import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
    width: "100%",
    height: 70,
    borderWidth: 1,
    borderColor: '#EBE9E9',
    justifyContent: "center"
  },
  cardTitle: {
  },
  cardTitleText: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardContent: {
    paddingLeft: 10
  },
  cardContentText: {
    fontSize: 14,
    textAlign: 'center'
  },
  cardAction: {
    width: 90,
    paddingTop: 35,
    alignSelf: 'center'
  }
});

export default styles;