import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  iconContainer: {
    width: 28,
    height: 28,
    margin: 5,
    alignItems: 'center',
    justifyContent: 'center',
    // shadowColor: "#C8E5BD",
    // shadowOpacity: 1,
    // shadowRadius: 2,
    // shadowOffset: {
    //   width: 0,
    //   height: 0,
    // },
    // elevation: 5, //android shadow but cannot use
    // textShadowOffset: { width: 5, height: 2 }, // andriod shadow but cannot use
  }
});

export default styles;