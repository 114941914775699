/***************************************************/
// Navigation & Linking Page for EP staff
/***************************************************/

import React from 'react';
import { createAppContainer } from 'react-navigation';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';
import Icon from '../components/ionicon';
import SimpleIcon from "react-native-vector-icons/SimpleLineIcons";
import OrderListing from './orderListing';
import MessageScreen from './messageScreen';
import QuotationScreen from './quotationScreen';
import MessageTargetListing from './messageTargetListing';
import SalesScreen from './salesScreen';
import CustomerOrderListing from './customerOrderListing';
import NotificationScreen from './notificationScreen';
import ProjectDevelopmentScreen from './projectDevelopmentScreen';
import ProjectDevelopmentListing from './projectDevelopmentListing';
import ProjectDevelopmentListingByCustomer from './projectDevelopmentListingByCustomer';
import MessageListing from './messageListing';
import SalesAboutMeScreen from './salesAboutMeScreen';
import StockCheckScreen from './stockCheckScreen';
import SettingsScreen from './settings';
import ShipmentScreen from './uploadShipmentScreen';
import UploadShipmentScreen from './uploadShipmentScreen'

const HomeStack = createStackNavigator({
  NotificationScreen: { screen: NotificationScreen },
  SalesScreen: { screen: SalesScreen },
  ProjectDevelopmentListing: { screen: ProjectDevelopmentListing },
  ProjectDevelopmentListingByCustomer: { screen: ProjectDevelopmentListingByCustomer },
  ProjectDevelopmentScreen: { screen: ProjectDevelopmentScreen },
  AboutMeScreen: { screen: SalesAboutMeScreen },
  StockCheckScreen: { screen: StockCheckScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'SalesScreen'
});

const OrderStack = createStackNavigator({
  NotificationScreen: { screen: NotificationScreen },
  OrderListing: { screen: OrderListing },
  QuotationScreen: { screen: QuotationScreen },
  CustomerOrderListing: { screen: CustomerOrderListing },
  UploadShipmentScreen: {screen: UploadShipmentScreen}
}, {
  headerMode: 'none',
  initialRouteName: 'CustomerOrderListing'
});

const MessageStack = createStackNavigator({
  NotificationScreen: { screen: NotificationScreen },
  MessageListing: { screen: MessageListing },
  MessageTargetListing: { screen: MessageTargetListing },
  MessageScreen: { screen: MessageScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'MessageListing'
});

const SettingsStack = createStackNavigator({
  NotificationScreen: { screen: NotificationScreen },
  SettingsScreen: { screen: SettingsScreen },
  ProjectDevelopmentListing: { screen: ProjectDevelopmentListing },
  AboutMeScreen: { screen: SalesAboutMeScreen },
  StockCheckScreen: { screen: StockCheckScreen },
  ShipmentScreen: { screen: ShipmentScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'SettingsScreen'
});

const TabNavigator = createBottomTabNavigator({
  Home: HomeStack,
  Enquiry: OrderStack,
  Messages: MessageStack,
  Menu: SettingsStack,
}, {
  defaultNavigationOptions: ({ screenProps, navigation }) => ({
    tabBarIcon: ({ focused, horizontal, tintColor }) => {
      const routeName: string = navigation.state.routeName;

      let iconName: string = '';
      let badgeCount: number = 0;
      let color: string = tintColor || 'gray';
      let iconSize: number = 0;

      if (routeName === 'Home') {
        iconName = focused ? "home" : "home-outline";
        iconSize = 26;
      } else if (routeName === 'Enquiry') {
        iconName = focused ? "information" : "information-outline";
        iconSize = 26;
      } else if (routeName === 'Messages') {
        iconName = focused
          ? "md-chatbox-ellipses"
          : "md-chatbox-ellipses-outline";
          iconSize = 26;
        badgeCount = screenProps.unreadCount;
      } else if (routeName === 'Menu') {
        iconName = "menu";
        iconSize = 24;
      }

      return (routeName === 'Menu' ? <SimpleIcon
      name={iconName}
      size={iconSize}
      color={color}
      style={{
        shadowColor: "#C8E5BD",
        shadowOpacity: 1,
        shadowRadius: 1,
        shadowOffset: {
          width: 2,
          height: 1.5,
        },
        elevation: 10, //android shadow but cannot use
        textShadowOffset: { width: 5, height: 2 }, // andriod shadow but cannot use
      }}
    /> : <Icon name={iconName} color={color} badgeCount={badgeCount} size={iconSize}/>);
    }
  }),

  tabBarOptions: {
    activeTintColor: "#0E6B35",
    inactiveTintColor: "#8fad86",
    keyboardHidesTabBar: false
  }
});

export default createAppContainer(TabNavigator);
