import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { LogBox } from 'react-native';
import styles from './app.styles';
import LoginScreen from './views/loginScreen';
import ResetPasswordScreen from './views/resetPasswordScreen';
import CustomerHome from './views/customerHome';
import CustomerManagementHome from './views/customerManagementHome';
import SalesHome from './views/salesHome';
import ManagementHome from './views/managementHome';
import SupplierHome from './views/supplierHome';
import * as userService from './services/userService';
import * as appConfig from './configs/devApis';

//LogBox.ignoreLogs(['Invalid prop textStyle of type array supplied to Cell']);
LogBox.ignoreLogs([
  "It appears that you are using old version of react-navigation library",
]);

const App = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [token, setToken] = useState('');
  const [role, setRole] = useState('');
  const [userId, setUserId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [tier, setTier] = useState('');
  const [department, setDepartment] = useState('');
  const [requireUpdate, setRequireUpdate] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [unreadNoti, setUnreadNoti] = useState(0);


  useEffect(() => {
    async () => {
      const version = await userService.getVersion();
      if (version) {
        if (version.lastSupportedVersion > appConfig.CURRENT_VERSION) {
          setRequireUpdate(true);
        } else if (version.latest > appConfig.CURRENT_VERSION) {
          alert('New update is available. Please update it from Play Store/App Store.');
        }
      }
    }
  })

  function onLogin(token: string, role: string, userId: string, companyId: string, tier: string, unreadCount: number, department: string, unreadNoti: number) {
    setIsLogin(true);
    setToken(token);
    setRole(role);
    setUserId(userId);
    setCompanyId(companyId);
    setTier(tier);
    setUnreadCount(unreadCount);
    setDepartment(department);
    setUnreadNoti(unreadNoti);
  }

  function onLogout() {
    setIsLogin(false);
  }

  function backToLogin() {
    setResetPasswordMode(false);
  }

  function switchResetPassword() {
    setResetPasswordMode(true);
  }

  return (
    <View style={styles.container}>
      {requireUpdate && alert('Your APP is outdated. Please update it from Play Store/App Store to continue using this app.')}
      {!requireUpdate && !isLogin && !resetPasswordMode && <LoginScreen onLogin={onLogin} switchResetPassword={switchResetPassword} />}
      {resetPasswordMode && <ResetPasswordScreen backToLogin={backToLogin} />}
      {isLogin && role === 'customer' && tier === 'staff' && <CustomerHome screenProps={{ token: token, role: role, userId: userId, onLogout: onLogout, companyId: companyId, tier: tier, unreadCount: unreadCount, unreadNoti: unreadNoti }} />}
      {isLogin && role === 'customer' && tier !== 'staff' && <CustomerManagementHome screenProps={{ token: token, role: role, userId: userId, onLogout: onLogout, companyId: companyId, tier: tier, unreadCount: unreadCount, unreadNoti: unreadNoti }} />}
      {isLogin && role === 'sales' && <SalesHome screenProps={{ token: token, role: role, userId: userId, onLogout: onLogout, unreadCount: unreadCount, unreadNoti: unreadNoti }} />}
      {isLogin && role === 'management' && <ManagementHome screenProps={{ token: token, role: role, userId: userId, onLogout: onLogout, unreadCount: unreadCount, department: department, unreadNoti: unreadNoti }} />}
      {isLogin && role === 'supplier' && <SupplierHome screenProps={{ token: token, role: role, userId: userId, onLogout: onLogout, companyId: companyId, tier: tier, unreadCount: unreadCount, unreadNoti: unreadNoti }} />}
    </View>
  );

}

export default App;