import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/managementAdminScreen.styles';
import Header from '../components/header';
import Icon from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
interface CustomInputProps {
    navigation: NavigationStackProp;
    screenProps: NavigationStackScreenProps;
}

class SettingsScreen extends Component<CustomInputProps> {
    constructor(props: CustomInputProps) {
        super(props);
        this.onNavigate = this.onNavigate.bind(this);
    }

    onNavigate(destination: string) {
        this.props.navigation.navigate(destination);
    }

    render() {
        return (
            <View style={styles.homeContainer}>
                <Header headerText='Menus' onBack={() => this.props.navigation.goBack()} back={false} notification={false} onPress={() => this.props.navigation.navigate('NotificationScreen')}/>
                <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('AboutMeScreen')}>
                    <Text style={styles.itemText}><Icon name={'person-outline'} color={'gray'} size={25} />  About Me</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('ProjectDevelopmentListing')}>
                    <Text style={styles.itemText}><Icon name={'folder-outline'} color={'gray'} size={25} />  Project Development</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('StockCheckScreen')}>
                    <Text style={styles.itemText}><Icon name={'cube-outline'} color={'gray'} size={25} />  Stock</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('ShipmentScreen')}>
                    <Text style={styles.itemText}><MaterialCommunityIcons name={'truck-fast-outline'} color={'gray'} size={25} />  Upload Shipment</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.item} onPress={this.props.screenProps.onLogout}>
                    <Text style={styles.itemText}><Icon name={'exit-outline'} color={'gray'} size={25}/>  Logout</Text>
                </TouchableOpacity>
            </View>
        );
    }
}

export default SettingsScreen;