import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import moment from 'moment';
import styles from '../styles/orderListing.styles';
import Header from '../components/header';
import RowList from '../components/rowList';
import Loader from '../components/loader';
import * as orderService from '../services/orderService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  orderList: Array<any>;
  isLoading: boolean;
  selectedCustomer: string;
  refreshing: boolean;
}

class OrderListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      orderList: [],
      isLoading: true,
      selectedCustomer: '',
      refreshing: false
    }

    this.onQuotation = this.onQuotation.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  async componentDidMount() {  //Get all order and filter
    let orderListingResponse: any = {};
  
    this.setState({isLoading:true})
    if (this.props.screenProps.companyId === '') {
      orderListingResponse = await orderService.getOrder(this.props.screenProps.token);
    } else {
      orderListingResponse = await orderService.getOrderByCompany(this.props.screenProps.token, this.props.screenProps.companyId);
    }
    
    if (orderListingResponse) {
      let selectedCustomer: string = this.props.navigation.getParam('customerEmail', '');
      this.setState({ selectedCustomer: selectedCustomer });

      if (selectedCustomer) {
        orderListingResponse = orderListingResponse.filter(function (item: any) {
          return item.orderBy === selectedCustomer;
        });
      }

      if (this.props.navigation.getParam('showCompletedOnly', false)) {
        orderListingResponse = orderListingResponse.filter(function (item: any) {
          return item.status === 'order completed';
        });
      }
    
      if (this.props.navigation.getParam('showIncompleteOnly', false)) {
        orderListingResponse = orderListingResponse.filter(function (item: any) {
           return item.status !== 'order completed';
            // return item.status === 'quotation accepted';
        });
      }
      
      if (this.props.navigation.getParam('showIncompleteOnly', '') === '' && this.props.navigation.getParam('showCompletedOnly', '') === '' && this.props.screenProps.tier === 'management') {
        orderListingResponse = orderListingResponse.filter(function (item: any) {
          return item.status === 'pending approval tier management';
        });
      }
      
      if (this.props.navigation.getParam('showIncompleteOnly', '') === '' && this.props.navigation.getParam('showCompletedOnly', '') === '' && this.props.screenProps.tier === 'manager') {
        orderListingResponse = orderListingResponse.filter(function (item: any) {
          return item.status === 'pending approval tier manager';
        });
      }

      orderListingResponse.sort(function (a: any, b: any) {
        return new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime();
      });

      let orderList: Array<any> = [];
      orderListingResponse.map((item: any, key: any) => (
        orderList.push({
          "title": item.brand + " " + item.material + "\r\n" + item.materialType + " " + item.colour,
          "content": "Status: " + item.status + "\nOrder By: " + item.email,
          "date": moment(new Date(item.orderDate)).format('MMMM Do YYYY, h:mm:ss a'),
          "unread": this.actionNeeded(item.pendingOn),
          "id": item._id,
          "status": item.status,
          "onPress": () => this.onQuotation(item)
        })
      ));

      this.setState({ orderList: orderList, isLoading: false });
    }
    else{
      setTimeout(() => {
        this.props.navigation.navigate('HomeScreen');
        this.setState({isLoading:false});
      }, 3000);

    }
  }

  actionNeeded(pendingOn: string) {
    if (this.props.screenProps.role === 'sales' || this.props.screenProps.role === 'management') {
      return this.props.screenProps.userId === pendingOn;
    } else {
      return this.props.screenProps.companyId === pendingOn;
    }
  }

  onQuotation(order: any) {
    if (order.quotation) {
      let quotation: any = order.quotation;
      quotation.orderId = order._id;
      quotation.status = order.status;
      quotation.email = order.email;
      quotation.orderBy = order.orderBy;
      quotation.actualDeliveryDate = moment(new Date(order.actualDeliveryDate)).format('MMMM Do YYYY, h:mm:ss a');
      this.props.navigation.navigate('QuotationScreen', quotation);
      console.log('owo?');
    } else {
      let orderDetails: any = order;
      orderDetails.orderId = order._id;
      orderDetails.status = order.status;
      orderDetails.email = order.email;
      this.props.navigation.navigate('QuotationScreen', orderDetails);
      console.log('qwq');
    }
   
  }

  onRefresh() {
    this.setState({ refreshing: true });
    this.props.navigation.setParams({ 'customerEmail': this.state.selectedCustomer });
    this.componentDidMount();
    this.setState({ refreshing: false });
  }

  render() {
    return (
      <View style={styles.orderContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Order' onBack={() => this.props.navigation.goBack()} back={true} notification={false} onPress={() => this.props.navigation.navigate('NotificationScreen')} />
        {this.state.orderList.length === 0 && <Text style={styles.emptyMessage}>You have no order!</Text>}
        {this.state.orderList.length > 0 && <RowList dataList={this.state.orderList} refreshing={this.state.refreshing} onRefresh={this.onRefresh} />}
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default OrderListing;
