import React, { Component } from 'react';
import {
  View, Text, ScrollView, StyleSheet,
  Image, TouchableOpacity
} from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import * as Notifications from 'expo-notifications';
// import styles from '../styles/homeScreen.styles';
import Header from '../components/header';
import Icon from "react-native-vector-icons/Ionicons";
import Category from "../components/Category.js";

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class SalesScreen extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
    this.handleNotificationResponse = this.handleNotificationResponse.bind(this);
  }

  componentDidMount() {
    Notifications.addNotificationResponseReceivedListener(this.handleNotificationResponse);
  }

  handleNotificationResponse(notification: any) {
    if (notification.origin === 'selected') {
      this.props.navigation.navigate('Notification');
    }
  }

  onNavigate(destination: string) {
    this.props.navigation.navigate(destination);
  }

//   render() {
//     return (
//       <View style={styles.homeContainer}>
//         <Header headerText='Home' onPress={() => this.props.navigation.navigate('NotificationScreen')} />
//         <View style={styles.brandContainer}><Text style={styles.brandText}>Euro Potential Sdn Bhd</Text></View>
//         {/* TODO: Temporary use vector icon. To use back custom icon component in future */}
//         <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('AboutMeScreen')}>
//           <Text style={styles.itemText}><Icons name={'user'} color={'gray'} size={25} />     About Me</Text>
//         </TouchableOpacity>
//         <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('CustomerOrderListing')}>
//           <Text style={styles.itemText}><Icons name={'copy1'} color={'gray'} size={25} />     Customer Purchase History</Text>
//         </TouchableOpacity>
//         <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('StockCheckScreen')}>
//           <Text style={styles.itemText}><Icons name={'carryout'} color={'gray'} size={25} />     Stock</Text>
//         </TouchableOpacity>
//         <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('Enquiry')}>
//           <Text style={styles.itemText}><Icons name={'questioncircleo'} color={'gray'} size={25} />     Enquiry</Text>
//         </TouchableOpacity>
//         <TouchableOpacity style={styles.item} onPress={() => alert('Coming Soon!')}>
//           <Text style={styles.itemText}><Icons name={'book'} color={'gray'} size={25} />     Moulding Guide</Text>
//         </TouchableOpacity>
//         <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('ProjectDevelopmentListing')}>
//           <Text style={styles.itemText}><Icons name={'team'} color={'gray'} size={25} />     Project Development</Text>
//         </TouchableOpacity>
//         <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('Message')}>
//           <Text style={styles.itemText}><Icons name={'message1'} color={'gray'} size={25} />     Customer Support</Text>
//         </TouchableOpacity>
//         <TouchableOpacity style={styles.item} onPress={this.props.screenProps.onLogout}>
//           <Text style={styles.itemText}>Logout</Text>
//         </TouchableOpacity>
//       </View>
//     );
//   }
// }

render() {
  return (
    <>
      <Header headerText='Home' onBack={() => this.props.navigation.goBack()} back={false} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti}/>
      <ScrollView scrollEventThrottle={16} style={{ backgroundColor: "white" }}>
        <View style={styles.homeContainer}>
          <View
            style={{
              flex: 1,
              backgroundColor: "white",
              paddingTop: 20,
            }}
          >
            <View style={{ paddingLeft: 20 }}>
              <Text style={{ fontSize: 24,color: "#16773C",
                fontWeight: "bold", paddingBottom: 20 }}>
                What We Do
              </Text>
            </View>
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              style={{ height: 240 }}
            >
              <Category
                imageUri={require("../assets/images/welding.jpg")}
                name="Welding"
                
              />
              <Category
                imageUri={require("../assets/images/chemical.jpg")}
                name="Chemical"
              />
              <Category
                imageUri={require("../assets/images/lighting.jpg")}
                name="Lighting"
              />
            </ScrollView>
          </View>


          <View
            style={{
              alignContent: "space-between",
              justifyContent: "space-evenly",
              flexDirection: "row",
              height:130
            }}
          >
            <TouchableOpacity onPress={() => this.onNavigate('AboutMeScreen')}>
              <View style={[styles.homeButton, styles.shadowButton]}>
                <Icon name="person-outline" color="black" size={40} />
              </View>
              <Text style={[styles.textButton]}>About Me</Text>
            </TouchableOpacity>
            
            <TouchableOpacity onPress={() => this.onNavigate('StockCheckScreen')}>
              <View style={[styles.homeButton, styles.shadowButton]}>
                <Icon name="cube-outline" style={{paddingLeft:"5%"}} color="black" size={43} />
              </View>
              <Text style={[styles.textButton]}>Stock</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.onNavigate('Enquiry')}>
              <View style={[styles.homeButton, styles.shadowButton]}>
                <Icon name="construct-outline" color="black" size={40} />
              </View>
              <Text style={[styles.textButton]}>Enquiry</Text>
            </TouchableOpacity>
          </View>

          <View
            style={{
              alignContent: "space-between",
              justifyContent: "space-evenly",
              flexDirection: "row",
              height: 100
            }}
          >
            <TouchableOpacity onPress={() => this.onNavigate('ProjectDevelopmentListing')}>
              <View style={[styles.homeButton, styles.shadowButton]}>
                <Icon name="folder-outline" style={{ paddingLeft: "5%" }} color="black" size={40} />
              </View>
              <Text style={[styles.textButton]}>Project{"\n"}Development</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => this.onNavigate('MessageListing')}>
              <View style={[styles.homeButton, styles.shadowButton]}>
                <Icon name="call-outline" color="black" size={40} />
              </View>
              <Text style={[styles.textButton]}>Customer{"\n"}Support</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.onNavigate('CustomerOrderListing')}>
              <View style={[styles.homeButton, styles.shadowButton, { paddingLeft: 2 },]}>
                <Icon name="cash-outline" color="black" size={40} />
              </View>
              <Text style={[styles.textButton]}>Purchase{"\n"}History</Text>
            </TouchableOpacity>
          </View>
          {/* <View
            style={{
              alignContent: "center",
              justifyContent: "center",
              flexDirection: "row"
            }}
          >
            <TouchableOpacity onPress={() => this.onNavigate('Message')}>
              <View style={[styles.homeButton, styles.shadowButton]}>
                <Icon name="call-outline" color="black" size={40} />
              </View>
              <Text style={[styles.textButton]}>Customer{"\n"}Support</Text>
            </TouchableOpacity>
          </View> */}
        </View>
        <View style={styles.logoContainer}>
          <Text>Euro Potential Sdn. Bhd.</Text>
          <Image
            style={styles.logo}
            source={require("../assets/images/logo_ep_ios.png")}
          />
        </View>
      </ScrollView>
    </>
  );
}
}

const styles = StyleSheet.create({
  homeButton: {
    height: 80,
    width: 80,
    // backgroundColor: "#E2F0D9",
    backgroundColor: "#E9E9E9", //grey color
    //backgroundColor: "#F7FDF5", // light green color
    borderRadius: 60,
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    opacity: 0.4,
    paddingTop: 50,
    alignItems: "center"
  },
  logo: {
    width: 100,
    height: 100,
  },
  textButton: {
    color: "#374957",
    paddingTop: 7,
    fontSize: 14,
    textAlign: "center",
  },
  shadowButton: {
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
  },
});

export default SalesScreen;
