/***************************************************/
//Will be recode in new version
/***************************************************/

import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import moment from 'moment';
import styles from '../styles/messageListing.styles';
import Header from '../components/header';
import RowListMessage from '../components/rowListMessage';
import * as userService from '../services/userService';
import * as messageService from '../services/messageService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  targetListing: Array<any>;
  refreshing: boolean;
}

class MessageListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      targetListing: [],
      refreshing: false
    }

    this.onSelect = this.onSelect.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  async componentDidMount() { //Get all existing message of user from database
    this.setState({ refreshing: true });
    let messageListingResponse: any = await messageService.getMessageByUser(this.props.screenProps.token, this.props.screenProps.userId);
    let targetListing: Array<any> = [];
    
    // targetListing.push({
    //   'title': "Add New Chat",
    //   'content': '',
    //   'date': '',
    //   'id': '9999999999999999',
    //   'unread': false,
    //   'onPress': () => this.onSelect('', '')
    // });
    
    if (messageListingResponse) {
      for (const item of messageListingResponse) {
        let userToGet: string = item.users[0];
        
        if (userToGet === this.props.screenProps.userId) {
          userToGet = item.users[1];
        }
      
        let userResponse: any = await userService.getUserById(this.props.screenProps.token, userToGet);
        
        let company: string = '';
        
        if (userResponse.companyId) {
          company = 'Company: ' + userResponse.companyId;
        } 
        
        let unread: boolean = false;
        
        if (item.unread === this.props.screenProps.userId) {
          unread = true;
        }
        
        let lastMessage: any = item.messageList.messages[item.messageList.messages.length - 1];
        let lastMessageText: string = lastMessage.text;
        let lastMessageDate: string = moment(new Date(lastMessage.createdAt)).format('MMMM Do YYYY, h:mm:ss a');;
        let lastMessageId: string = lastMessage._id;
                
        targetListing.push({
          'title': userResponse.email + "\r\n" + company,
          'content': lastMessageText,
          'date': lastMessageDate,
          'id': lastMessageId,
          'unread': unread,
          'onPress': () => this.onSelect(this.props.screenProps.userId, userResponse._id, unread)
        });
        
        targetListing.sort(function (a, b) {
          return b.id - a.id;
        });
      }
    }
    
    this.setState({ targetListing: targetListing });
    this.setState({ refreshing: false });
  }

  onSelect(user1: string, user2: string, unread: boolean) { //navigation function
    if (user1 === '' && user2 === '') {
      this.props.navigation.navigate('MessageTargetListing');
    } else {
      if (unread) {
        this.props.screenProps.unreadCount = this.props.screenProps.unreadCount - 1;
      }
      
      this.props.navigation.navigate('MessageScreen', {user1: user1, user2: user2});
    }
  }
  
  onRefresh() {  //refresh function
    this.componentDidMount();
  }

  wait(ms: number){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }

  render() {
    return (
      <View style={styles.targetContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Chats' onPress={() => this.props.navigation.navigate('NotificationScreen')} onBack={() => this.props.navigation.goBack()} back={false} notification={true} badgeCount={this.props.screenProps.unreadNoti} />
        {/* {this.state.targetListing.length === 0 && <Text style={styles.emptyMessage}>Loading...</Text>} */}
        <RowListMessage dataList={this.state.targetListing} refreshing={this.state.refreshing} onRefresh={this.onRefresh} />
        <TouchableOpacity style={styles.chatButton} onPress={() => this.onSelect('', '')}>

        <Text style={styles.chatMessage} >
          + New Chat
        </Text>

      </TouchableOpacity>
      
      </View>
    );
  }
}

export default MessageListing;