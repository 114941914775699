import React, { useEffect, useState } from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from "react-native";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Background from "../components/Background";
import styles1 from "../styles/login.styles";
import styles from '../styles/loginScreen.styles';
import Form from '../components/loginForm';
import Loader from '../components/loader';
import * as authService from '../services/authService';
import * as notificationService from '../services/notificationService';
import * as messageService from '../services/messageService';
import { Dimensions } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';

const screenWidth = Dimensions.get('screen').width;
const screenHeight = Dimensions.get('screen').height;

const loginScreen = ({ onLogin, switchResetPassword }) => {
  const [formInput, setFormInput] = useState([] as any);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, hasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, isLoading] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);

  useEffect(() => {
    generateForm();
  }, [email, password, hidePassword])

  function generateForm() { //Declare input
    setFormInput([
      { placeholder: 'Username', onChangeText: setEmail, value: email },
      { placeholder: 'Password', onChangeText: setPassword, secureTextEntry: hidePassword, value: password }
    ])
    setErrorMessage('');
  }

  const login = async () => {  //login function
    isLoading(true);
    if ((email && password) !== '') {
      const authServiceResponse: any = await authService.login(email, password);

      if (authServiceResponse) {
        if (Platform.OS != "web") {
          await notificationService.registerForPushNotificationsAsyn(authServiceResponse.token);
        }

        let unreadCount: any = await messageService.getUnreadCount(authServiceResponse.token);
        
        let notificationResponse: any = await notificationService.getNotification(authServiceResponse.token);
        let unreadNoti: any = notificationResponse[0].unreadNoti;
        isLoading(false);
        onLogin(authServiceResponse.token, authServiceResponse.role, authServiceResponse.id, authServiceResponse.companyId || '', authServiceResponse.tier || '', unreadCount.unreadCount, authServiceResponse.department || '', unreadNoti);


      } else {
        hasError(true);
        isLoading(false);
        setErrorMessage('Invalid email/password');
      }
    } else {
      hasError(true);
      isLoading(false);
      setErrorMessage('Invalid email/password');
    }
    setTimeout(() => {
      isLoading(false);
    }, 2000);


  }

  return (
    <KeyboardAwareScrollView
      style={{ height: screenHeight, width: screenWidth, flex: 1 }}
      resetScrollToCoords={{ x: 0, y: 0 }}
      contentContainerStyle={{ height: screenHeight, width: screenWidth }}
      scrollEnabled={true}
    >
      <Background>
        <View style={{ marginVertical: Platform.OS === 'ios' ? "20%" : "16%", zIndex: 2 }}>
          <View style={styles1.logoContainer}>
            <Image style={styles.logo} source={require("../../assets/logo_ep.png")} />
            <Text style={styles1.companyName}>
              Euro Potential
            </Text>
          </View>
          <View style={[styles1.whiteContainer, styles1.shadowProp]}>
            <View style={{ alignItems: "center" }}>
              <TouchableOpacity style={{position:'absolute', alignSelf: 'flex-end', marginTop:"54.5%", marginRight: "23%", zIndex:1}} onPress={() => setHidePassword(!hidePassword)}>
                <Icon
                  name={hidePassword ? 'eye-slash' : 'eye'}
                  size={15}
                />
              </TouchableOpacity>
              <Text style={styles1.loginText}>
                Login
              </Text>
              <Form formInput={formInput} haveError={error} errorMessage={errorMessage} buttonText={'Login'} buttonOnPress={login} />
              <View style={styles.resetContainer}>
                <TouchableOpacity onPress={switchResetPassword}>
                  <Text style={[styles1.forgotWord, { textDecorationLine: "underline" }]}>
                    Forgot Password
                  </Text>
                </TouchableOpacity>
              </View>

            </View>
          </View>
        </View>
        <Loader isLoading={loading} />
      </Background>
    </KeyboardAwareScrollView>
  )
}

export default loginScreen;