import axios from 'axios';
import * as apis from '../configs/devApis';

export const getQuotingById = async (token: string, quotingId: string) => {
  try {
    const getQuotingResponse: any = await axios.get(apis.SUPPLIER_API + '/quoting/id/' + quotingId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getQuotingResponse.data.data;
  } catch (err) {
    console.log(`Error returned from GET quoting API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getQuotingBySupplier = async (token: string, companyId: string) => {
  try {
    const getQuotingResponse: any = await axios.get(apis.SUPPLIER_API + '/quoting/supplier/' + companyId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getQuotingResponse.data.data;
  } catch (err) {
    console.log(`Error returned from GET quoting API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getQuotingByStatus = async (token: string, status: string) => {
  try {
    const getQuotingResponse: any = await axios.get(apis.SUPPLIER_API + '/quoting/status/' + status, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getQuotingResponse.data.data;
  } catch (err) {
    console.log(`Error returned from GET quoting API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postQuoting = async (token: string, quotingDetails: any) => {
  try {
    const postQuotingResponse: any = await axios.post(apis.SUPPLIER_API + '/quoting', quotingDetails, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return postQuotingResponse.data;
  } catch (err) {
    console.log(`Error returned from POST quoting API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postQuotingById = async (token: string, quotingId: string, quotingDetails: any) => {
  try {
    await axios.post(apis.SUPPLIER_API + '/quoting/id/' + quotingId, quotingDetails, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from POST quoting API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getSupplier = async (token: string) => {
  try {
    await axios.get(apis.SUPPLIER_API + '/quoting/supplier', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    alert(token+"  "+JSON.stringify(err));
    console.log(`Error returned from GET quoting API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}
