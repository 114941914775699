import { Platform, StyleSheet } from "react-native"; 
import { RFPercentage } from "react-native-responsive-fontsize";    
import { Dimensions } from 'react-native';         

// const screenWidth = Dimensions.get('screen').width;
// const screenHeight = Dimensions.get('screen').height;

const styles = StyleSheet.create({
    formContainer: {
        flex: 1,
        alignItems: "center",
        paddingBottom: 10,
    },
    bgContainer: {
        overflow: "hidden",
        borderBottomLeftRadius: 100,
        borderBottomRightRadius: 100,
    },
    stretch:{
        height: 100,
        width:20,
        resizeMode: "contain"
    },
    logo: {
        width: Platform.OS === 'ios' ? "20%" : "10%",
        height: Platform.OS === 'ios' ? "20%" : "10%",
        marginBottom: 35,
    },
    logoContainer: {
        paddingTop: "10%",
        alignItems: "center",
        paddingBottom: "20%",
        flex: 2
    },
    shadowProp: {
        shadowColor: "#171717",
        shadowOpacity: 0.5,
        shadowRadius: 8,
        elevation: 2,
    },
    whiteContainer: {
        zIndex: 3,
        paddingHorizontal: Platform.OS === 'ios' ? "6%" : "4%",
        paddingVertical: Platform.OS === 'ios' ? "8%" : "4%",
        backgroundColor: "white",
        borderRadius: 20,
    },
    companyName: {
        color: "white",
        fontSize: Platform.OS === 'ios' ? 45 : 28,
        fontWeight: "bold",
    },
    loginText: {
        color: "#0E6B35",
        fontSize: Platform.OS === 'ios' ? 35 : 25,
        fontWeight: "bold",
        marginTop: "5%",
    },
    resetText: {
        color: "#0E6B35",
        fontSize: Platform.OS === 'ios' ? 28:  20,
        fontWeight: "bold",
        marginTop: "6%",
        marginLeft: Platform.OS === 'ios' ? "2%" : "4%",
        marginBottom: "8%"
    },
    forgotWord: {
        color: "#0E6B35",
        fontWeight: "bold",
        fontSize: RFPercentage(2),
    },
    forgotButton:{
        alignItems: "flex-end",
        width: "78%",
        marginBottom: "40%",
    },
    signUpWord: { 
        fontSize: 16, 
        fontWeight: "bold", 
        color: "#0E6B35" 
    },
    loginContainer: {
        flex: 1,
        color:"green"
      },
      resetContainer: {
        alignItems: 'center',
        paddingBottom: 20
      },
    //   logo: {
    //     alignItems: 'center',
    //     justifyContent: 'flex-end',
    //     width: 150,
    //     height: 150
    //   }
});

export default styles;