import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  // feedContainer: {
  //   flex: 1
  // },
  // uploadForm: {
  //   width: 350,
  //   justifyContent: 'center',
  //   alignSelf: 'center'
  // },
  // button: {
  //   width: 200,
  //   paddingTop: 10,
  //   alignSelf: 'center'
  // },
  // uploadButtonContainer: {
  //   width: 300,
  //   paddingTop: 30,
  //   alignSelf: 'center'
  // },
  // photoButtonContainer: {
  //   width: 280,
  //   paddingTop: 10,
  //   alignSelf: 'center'
  // },
  
  feedContainer: {
    flex: 1
  },
  button: {
    width:"80%",
    backgroundColor: "#F7FDF5",
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
    padding:12,
    
  },
  ctn: {
    
    height:"70%", alignItems: "center",justifyContent:"center",margin:20,backgroundColor:"white",borderRadius:20
  },
  documentText1: {
    fontSize: 18,
    alignSelf: 'center',
    color: "#374957"
  },
  ctnShadow: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 3,
    elevation: 5,
  },
  shadow: {
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
  },
});

export default styles;
