import React, { Component } from 'react';
import { View } from 'react-native';
// import Icons from "react-native-vector-icons/Ionicons";
import Icons from "react-native-vector-icons/AntDesign";
import styles from './icon.styles';
import IconBadge from './iconBadge';
//import { Icon } from 'react-native-gradient-icon';

//navigation icon

interface CustomInputProps {
  name: string;
  size: number;
  color: string;
  badgeCount: number;
}

class Icon extends Component<CustomInputProps> {
  static defaultProps = {
    size: 25
  }
  
  render() {
    return (
      <View style={styles.iconContainer}>
        <Icons name={this.props.name} size={this.props.size} color={this.props.color} />
        {/* <Icon 
          name={this.props.name} 
          size={this.props.size} 
          color={this.props.color} 
          style={{
            shadowColor: "#C8E5BD",
            shadowOpacity: 1,
            shadowRadius: 1,
            shadowOffset: {
              width: 2,
              height: 1.5,
          },
            elevation: 5, //android shadow but cannot use
            textShadowOffset: { width: 5, height: 2 }, // andriod shadow but cannot use
          }}/> */}
        {this.props.badgeCount > 0 && <IconBadge badgeCount={this.props.badgeCount} />}
      </View>
    );
  }
}

export default Icon;