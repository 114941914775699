import { Platform, StyleSheet } from 'react-native';
import { darkGreen } from "./Constants";

const styles = StyleSheet.create({
  form: {
    width: "70%",
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  scrollViewContainer: { 
    flexShrink: 1, 
    justifyContent: "center",
    paddingVertical: 15, 
  },
  formContainer: {
    flex: 2
  },
  button: {
    width: 250,
    paddingTop: 10,
    alignSelf: 'center'
  },
  errorMessage: {
    color: 'red',
    alignSelf: 'center'
  },
  inputContainer: {
    flex:1,
    borderRadius: 10,
    padding: Platform.OS === 'ios' ? "5%" : "4%",
    width: Platform.OS === 'ios' ? "90%" : "100%",
    backgroundColor: "#F7FDF5",
    marginVertical: Platform.OS === 'ios' ? "4%" : "2%",
    shadowColor: "#171717",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    alignSelf: 'center',
    elevation: 2
  },
  pickerContainer: {
    flex: 1,
    borderRadius: 10,
    width: "100%",
    backgroundColor: "#F7FDF5",
    marginVertical: Platform.OS === 'ios' ? "4%" : "2%",
    shadowColor: "#171717",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    alignSelf: 'center',
    elevation: 2
  },
  picker: {
    width: "100%"
  },
  labelText: {
    fontSize: 16,
    color: '#999999',
    fontWeight: 'bold'
  },
  title: {
    color: "#056B30",
    fontSize: 20,
    fontWeight: "600",
  },
  gradient: {
    borderRadius: 10,
    alignItems: "center",
    width: "100%",
    paddingVertical: "4%",
    marginTop: "2%",
    marginBottom: "4%",
    borderColor: "white",
  },
  shadow: {
    shadowOffset: {
      height: 1,
      width: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 4,
  },
});

export default styles;