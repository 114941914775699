import React, { Component } from "react";
import { View, ScrollView, RefreshControl } from "react-native";
import styles from "./rowList.styles";
import Row from "./row";

interface CustomInputProps {
  dataList: Array<any>;
  refreshing: boolean;
  onRefresh: Function;
  loadMore: Function;
}

class RowList extends Component<CustomInputProps> {
  onRefresh() {
    if (typeof(this.props.onRefresh) !== "undefined") {
      this.props.onRefresh();
    }
  }

  loadMore() {
    if (typeof(this.props.loadMore) !== "undefined") {
      this.props.loadMore();
    }
  }

  render() {
    return (
      <ScrollView
        onMomentumScrollEnd={this.loadMore.bind(this)}
        refreshControl={
          <RefreshControl
            refreshing={this.props.refreshing}
            onRefresh={this.onRefresh.bind(this)}
          />
        }
      >
        <View style={styles.rowList}>
          {this.props.dataList.map((item, key) => (
            <Row
              key={key}
              title={item.title}
              content={item.content}
              date={item.date}
              unread={item.unread}
              id={item.id}
              status={item.status}
              type={item.type}
              onPress={item.onPress}
            />
          ))}
         
        </View>
      </ScrollView>
    );
  }
}

export default RowList;
