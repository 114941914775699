import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  resetContainer: {
    flex: 1,
  },
  logo: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 150,
    height: 150
  }
});

export default styles;
