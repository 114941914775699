import React, { Component } from 'react';
import { View, ScrollView } from 'react-native';
import styles from './cardList.styles';
import Card from './card';

//Content card list

interface CustomInputProps {
  dataList: Array<any>;
}

class CardList extends Component<CustomInputProps> {
  render() {
    return (
      <ScrollView>
        <View style={styles.cardList}>
          {this.props.dataList.map((item, key) => (
            <Card key={key} title={item.title} content={item.content} action={item.buttonText} unread={item.unread || false} onPress={item.buttonOnPress} />
          ))}
        </View>
      </ScrollView>
    );
  }
}

export default CardList;