import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  feedContainer: {
    flex: 1
  },
  uploadForm: {
    width: 350,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  button: {
    width: 200,
    paddingTop: 10,
    alignSelf: 'center'
  },
  uploadButtonContainer: {
    width: 300,
    paddingTop: 30,
    alignSelf: 'center'
  },
  photoButtonContainer: {
    width: 280,
    paddingTop: 10,
    alignSelf: 'center'
  },

  photoIcon: {
    backgroundColor: "white",
    marginTop:"20%",
    borderRadius: 20,
    height: "25%",
    width: "45%",
    alignItems: "center",
    justifyContent: "center",
  },
  ctn: {
    alignItems: "center",
    backgroundColor: "white",
    margin:30,
    borderRadius: 10,
    minHeight:"80%",
    // maxHeight: "90%",
    marginTop:"15%"
    
  },
  shadow: {
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 3,
    elevation: 5,
  },
  textinput: {
    borderRadius: 10,
    marginTop: "20%",
    padding: "4%",
    paddingTop: "4%",
    width: "85%",
    backgroundColor: "#F7FDF5",
    
  },
  btn: {
    marginTop: "5%",
    width: 200,
    borderRadius: 10,
    paddingHorizontal: "1%",
    marginVertical: 20,
    paddingVertical: 12,
    borderCOlor: "white",
  },

  text:{
    color: "white", 
    fontSize: 20, 
    fontWeight: "600", 
    textAlign: "center" 

  }
});

export default styles;