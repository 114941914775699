import axios from 'axios';
import * as apis from '../configs/devApis';

export const getProductByStatus = async (token: string, status: string) => {
  try {
    const getProductResponse: any = await axios.get(apis.PRODUCT_API + '/product/status/' + status, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getProductResponse.data.data;
  } catch (err) {
    console.log(`Error returned from GET product API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}