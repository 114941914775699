/***************************************************/
//Will be recode in new version
/***************************************************/

import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/messageTargetListing.styles';
import Header from '../components/header';
import RowList from '../components/rowListMessage';
import * as userService from '../services/userService';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  targetListing: Array<any>;
}

class MessageTargetListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      targetListing: []
    }

    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() { //Get all available user
    let targetListingResponse: any = await userService.getMessageTargetList(this.props.screenProps.token);
    let targetListing: Array<any> = [];
    
    if (targetListingResponse) {
      for (const item of targetListingResponse) {
        let company: string = '';
        
        if (item.companyId) {
          company = 'Company: ' + item.companyId;
          
          if (this.props.screenProps.role === 'management' && this.props.screenProps.department !== 'all' && this.props.screenProps.department !== 'super') {
            let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, item.companyId);
    
            if (getCompanyResponse[0]) {
		            if (getCompanyResponse[0].department !== 'all' && getCompanyResponse[0].department !== this.props.screenProps.department) {
		              continue;
		            }
		          }
		        }
        } 
                
        targetListing.push({
          'title': item.email + "\r\n" + company,
          'content': '',
          'date': '',
          'id': company,
          'unread': false,
          'onPress': () => this.onSelect(this.props.screenProps.userId, item._id)
        })
        
        targetListing.sort(function (a, b) {
          return a.id.localeCompare(b.id);
        });
      }
    }
    
    this.setState({ targetListing: targetListing });
  }

  onSelect(user1: string, user2: string) {  //navigation function
    this.props.navigation.navigate('MessageScreen', {user1: user1, user2: user2});
  }

  render() {
    return (
      <View style={styles.targetContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='New Chat' onPress={() => this.props.navigation.navigate('NotificationScreen')} onBack={() => this.props.navigation.goBack()} back={true} notification={true} badgeCount={this.props.screenProps.unreadNoti} />
        {this.state.targetListing.length === 0 && <Text style={styles.emptyMessage}>Loading...</Text>}
        {this.state.targetListing.length > 0 && <RowList dataList={this.state.targetListing} />}
      </View>
    );
  }
}

export default MessageTargetListing;