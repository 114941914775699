import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Platform } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import * as Notifications from 'expo-notifications';
import * as userService from '../services/userService';
import styles from '../styles/managementUserScreen.styles';
import Header from '../components/header';
import { Table, Row } from 'react-native-table-component';
import Icon from 'react-native-vector-icons/Ionicons';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  customerListing: Array<any>;
}

class ManagementUser extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);
    this.state = {
      customerListing: []
    }
    this.onNavigate = this.onNavigate.bind(this);
    this.handleNotificationResponse = this.handleNotificationResponse.bind(this);
  }

  async componentDidMount() { //retrieve all user from database
    Notifications.addNotificationResponseReceivedListener(this.handleNotificationResponse);
    let customerListingResponse: any = await userService.getCustomer(this.props.screenProps.token);
    let salesListingResponse: any = await userService.getSales(this.props.screenProps.token);

    if (customerListingResponse) {
      let customerListing: Array<any> = [];
      let tempArray: Array<string>;
      let customerSales: any;

      customerListingResponse.map(function (data) {
        customerSales = salesListingResponse.filter(function (salesData) {
          return (salesData._id === data.salesId) ? true : false;
        });

      
        tempArray = [];
        tempArray.push(data.email);

        if (customerSales[0]) {
          tempArray.push(customerSales[0].email);
        } else {
          tempArray.push('N/A');
        }

        customerListing.push(tempArray);
      });

      this.setState({ customerListing: customerListing });
    }
  }

  handleNotificationResponse(notification: any) {
    if (notification.origin === 'selected') {
      this.props.navigation.navigate('Notification');
    }
  }

  checkIndexIsEven(index) { //Used to seperate odd and even row
    return (index % 2 == 0) ? true : false;
  }

  onNavigate(destination: string) { //navigation function
    this.props.navigation.navigate(destination);
  }

  render() {
    return (  
      <View style={styles.managementUserContainer}>
        <Header headerText='User' onPress={() => this.onNavigate('NotificationScreen')} onBack={() => this.props.navigation.goBack()} back={true} badgeCount={this.props.screenProps.unreadNoti}/>
        
        {this.state.customerListing.length === 0 && <Text style={styles.emptyMessage}>No Customer!</Text>}
        {this.state.customerListing.length > 0 &&
          
            
            <ScrollView stickyHeaderIndices={[0]} stickyHeaderHiddenOnScroll={true} style={[styles.dataWrapper,styles.shadow]}>
            {/* <View style={[styles.tableHeader]}> */}
            <>
              <View style={[styles.tableHeader]}>
                <View style={[styles.headerContainer,{borderRightWidth:0.5,borderColor:"white"}]}>
              <Text style={[styles.headerText]}>Customer</Text>
              </View>
             <View style={[styles.headerContainer,{borderLeftWidth:0.5,borderColor:"white"}]}>
              <Text style={[styles.headerText]}>Sales</Text>
              </View>
             </View>
             </>
             {/* <View style={[{ borderLeftWidth: 0.5,borderColor:"white" }, styles.headerContainer]}>
             
          </View> */}
        {/* </View> */}
              <Table borderStyle={{ borderWidth: 2,borderColor:"white",borderRadius:10}}>
                {
                  this.state.customerListing.map((rowData, index) => (
                    <Row
                      key={index}
                      data={rowData}
                      flexArr={[1, 1]}
                      style={{ ...styles.tableRow, ...{ backgroundColor: this.checkIndexIsEven(index) ? '#F2F2F2' : '#E1E3E3' } }}
                      textStyle={styles.tableText}
                    />
                  ))
                }
              </Table>
              <View style={{marginVertical:50}}></View>
            </ScrollView>
          }
        <TouchableOpacity
          style={{ position: "absolute", marginTop: Platform.OS === 'ios' ? "178%" : "169%", marginLeft: "55%" }}
          onPress={() => this.onNavigate("CreateUserScreen")}
        >
          <View style={[styles.btn, styles.shadow]}>
            <Text style={{ color: "white", fontSize: Platform.OS === 'ios' ? 24 : 20, fontWeight: "500" }}>
              <Icon name="create-outline" size={25} /> Create User
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}

export default ManagementUser;
