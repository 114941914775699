import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  aboutMeContainer: {
    backgroundColor: '#F2F1F1',
    flex: 1,
    
  },
  
  // item: {
  //   maxHeight: 100,
  //   flexGrow: 1,
  //   borderWidth: 0.5,
  //   borderColor: 'gray',
  //   alignSelf: 'stretch',
  //   justifyContent: 'center',
  //   paddingLeft: 10
  // },
  // itemText: { 
  //   fontSize: 18,
  //   fontWeight: 'bold',
  //   paddingLeft: 20
  // }，
  box:{
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginTop:"20%",
    
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    
    width: 150,
    height: 150,
    backgroundColor: "#F7FDF5",
    borderRadius: 30,
  },
  buttonShadow: {
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
  },
  text: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: "600",
    color: "#374957",
  },

});

export default styles;