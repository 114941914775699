import React, { Component } from 'react';
import { View, Text } from 'react-native';
import styles from './iconBadge.styles';

interface CustomInputProps {
  badgeCount: number;
}

class IconBadge extends Component<CustomInputProps> {
  render() {
    return (
      <View style={styles.badge}>
        <Text style={styles.badgeText}>{this.props.badgeCount}</Text>
      </View>
    );
  }
}

export default IconBadge;