/***************************************************/
//Will be recode in new version
/***************************************************/

import React, { Component } from 'react';
import { View, KeyboardAvoidingView } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { GiftedChat } from 'react-native-gifted-chat';
import styles from '../styles/messageScreen.styles';
import Header from '../components/header';
import Loader from '../components/loader';
import * as messageService from '../services/messageService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  messages: Array<any>;
  isLoading: boolean;
}

class MessageScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      messages: [],
      isLoading: true
    }

    this.onSend = this.onSend.bind(this);
  }

  async componentDidMount() { //Get message history
    this.setState({ isLoading: true });
    let messageResponse: any = await messageService.getMessage(this.props.screenProps.token, this.props.navigation.getParam('user1', ''), this.props.navigation.getParam('user2', ''));
    
    if (messageResponse[0]) {
      await messageService.resetUnread(this.props.screenProps.token, messageResponse[0]._id);
      
      let messageList: Array<any> = messageResponse[0].messageList.messages;

      messageList.sort(function (a, b) {
        return b._id - a._id;
      });
      
      this.setState({
        messages: messageList
      });
    }
    
    this.setState({ isLoading: false });
  }

  async onSend(messages: Array<any> = []) { //upload message to database
    this.setState({ isLoading: true });
    
    this.setState((previousState: any) => ({
      messages: GiftedChat.append(previousState.messages, messages)
    }));
    
    await messageService.postMessage(this.props.screenProps.token, { users: [this.props.navigation.getParam('user1', ''), this.props.navigation.getParam('user2', '')], text: messages[0].text });
    
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <View style={styles.messageContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Message' onPress={() => this.props.navigation.navigate('NotificationScreen')} onBack={() => this.props.navigation.goBack()} back={true} notification={true} badgeCount={this.props.screenProps.unreadNoti}/>
        <KeyboardAvoidingView style={styles.chatContainer} enabled>
          <GiftedChat messages={this.state.messages} onSend={this.onSend} user={{ _id: this.props.screenProps.userId }} />  
        </KeyboardAvoidingView>
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default MessageScreen;
