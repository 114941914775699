import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/managementAdminScreen.styles';
import Header from '../components/header';
import Icon from "react-native-vector-icons/Ionicons";
import Icons from 'react-native-vector-icons/AntDesign';

interface CustomInputProps {
    navigation: NavigationStackProp;
    screenProps: NavigationStackScreenProps;
}

class supplierSettingsScreen extends Component<CustomInputProps> {
    constructor(props: CustomInputProps) {
        super(props);
        this.onNavigate = this.onNavigate.bind(this);
    }

    onNavigate(destination: string) {
        this.props.navigation.navigate(destination);
    }

    render() {
        return (
            <View style={styles.homeContainer}>
                <Header headerText='Settings' onBack={() => this.props.navigation.goBack()} back={false} notification={false} onPress={() => this.props.navigation.navigate('NotificationScreen')}/>
                <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('MeScreen')}>
                    <Text style={styles.itemText}><Icon name={'person-outline'} color={'gray'} size={25} />  About Me</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('ProjectDevelopmentListing')}>
                    <Text style={styles.itemText}><Icon name={'folder-outline'} color={'gray'} size={25} />  Project Development</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('ConsumptionListing')}>
                    <Text style={styles.itemText}><Icons name={'solution1'} color={'gray'} size={25} />  Consumtion Listing</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.item} onPress={this.props.screenProps.onLogout}>
                    <Text style={styles.itemText}><Icon name={'exit-outline'} color={'gray'} size={25}/>  Logout</Text>
                </TouchableOpacity>
            </View>
        );
    }
}

export default supplierSettingsScreen;