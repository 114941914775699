import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/managementAdminScreen.styles';
import Header from '../components/header';
import Icons from 'react-native-vector-icons/AntDesign';
import Icon from "react-native-vector-icons/Ionicons";

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class ManagementAdminScreen extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
  }
  
  onNavigate(destination: string) { //navigation function
    this.props.navigation.navigate(destination);
  }

  render() {
    return (
      <View style={styles.homeContainer}>
        <Header headerText='Admin' onPress={() => this.onNavigate('NotificationScreen')} onBack={() => this.props.navigation.goBack()} back={false} notification={false} />
        {this.props.screenProps.department === 'super' && <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('ManagementUserScreen')}>
          <Icon name={'person-outline'} color="#374957" size={30} />
          <Text style={styles.itemText}>User</Text>
        </TouchableOpacity>}
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('FeedScreen')}>
          <Icon name={'create-outline'} color="#374957" size={30} />
          <Text style={styles.itemText}>News Feed</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('ConsumptionListing')}>
          <Icons name={'solution1'} color="#374957" size={30} />
          <Text style={styles.itemText}>Consumption List</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={this.props.screenProps.onLogout}>
          <Icon name="log-out-outline" color="#374957" size={30} />
          <Text style={styles.itemText}>Logout</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

export default ManagementAdminScreen;