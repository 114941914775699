import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  targetContainer: {
    flex: 1,
    backgroundColor: '#ECF5ED',
    height: "100%",
  },
  emptyMessage: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  chatButton: {
    position: "absolute",
    marginTop: "175%",
    marginLeft: "65%",
    width: 110,
    height: 0,
    backgroundColor: "green",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowColor: "#000",
    shadowOpacity: 0.7,
    shadowRadius: 2,
    elevation: 5,
  },
  chatMessage: {
    color: "white",
    fontSize: 17,
    fontWeight: "700",
  },
  searchbar: { 
    flex: 6, 
    width: "90%", 
    alignSelf: 'center',
    position: "absolute",
    marginTop: "25%",
    elevation: 5,
    zIndex: 1
  }
});

export default styles;