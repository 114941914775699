import React from "react";
import { View, ImageBackground } from "react-native";
import { Dimensions } from 'react-native';
import LazyLoad from 'react-lazyload';

const Background = ({ children }) => {
  const screenWidth = Dimensions.get('screen').width;
  const screenHeight = Dimensions.get('screen').height;

  return (
    <>
    <View style={{
        overflow: "hidden",
        borderBottomLeftRadius: 100,
        borderBottomRightRadius: 100,
      }}>

      <ImageBackground
          source={require("../../assets/photo-background.jpg")}
          style={{
            height: screenHeight/2,
          }}
      />
      
    </View>
    <View style={{ position: "absolute", alignSelf:"center"}}>{children}</View>
    </>
  );
};

export default Background;
