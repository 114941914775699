import React, { Component } from 'react';
import { View, Alert, Platform } from 'react-native';
import { NavigationActions, NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import moment from 'moment';
import styles from '../styles/quotationScreen.styles';
import Header from '../components/header';
import Form from '../components/form';
import Loader from '../components/loader';
import * as OrderService from '../services/orderService';
import * as CompanyService from '../services/companyService';
import * as UserService from '../services/userService';
import * as NotificationService from '../services/notificationService';
import { ScrollView } from 'react-native-gesture-handler';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  formInput: Array<any>;
  customer: string;
  brand: string;
  material: string;
  materialType: string;
  colour: string;
  quantity: number;
  deliveryDate: string;
  haveError: boolean;
  errorMessage: string;
  isLoading: boolean;
  price: string;
  validity: string;
  requotationMode: boolean;
  requotationRemarks: string;
  creditTerm: string;
  remarks: string;
  actualDeliveryDate: string;
  status: string;
}

class PurchaseScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      formInput: [],
      customer: '',
      brand: '',
      material: '',
      materialType: '',
      colour: '',
      quantity: 0,
      deliveryDate: '',
      haveError: false,
      errorMessage: '',
      isLoading: false,
      price: '',
      validity: '',
      requotationMode: false,
      requotationRemarks: '',
      creditTerm: '',
      remarks: '',
      actualDeliveryDate: '',
      status: ''
    }

    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.onChangeMaterial = this.onChangeMaterial.bind(this);
    this.generateForm = this.generateForm.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeColour = this.onChangeColour.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onChangeDeliveryDate = this.onChangeDeliveryDate.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
    this.onChangeValidity = this.onChangeValidity.bind(this);
    this.onPurchase = this.onPurchase.bind(this);
    this.onQuote = this.onQuote.bind(this);
    this.onUpdateStatus = this.onUpdateStatus.bind(this);
    this.onAcceptQuotation = this.onAcceptQuotation.bind(this);
    this.onChangerequotationRemarks = this.onChangerequotationRemarks.bind(this);
    this.onChangeCreditTerm = this.onChangeCreditTerm.bind(this);
    this.onChangeRemarks = this.onChangeRemarks.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const orderResponse: any = await OrderService.getOrderById(this.props.screenProps.token, this.props.navigation.getParam('orderId', ''));
    console.log('Order id:',this.props.navigation.getParam('orderId', ''))
    if (orderResponse) {
      let brand: string, material: string, materialType: string, colour: string, quantity: string, deliveryDate: string, price: string, validity: string, creditTerm: string, remarks: string, requotationRemarks: string, actualDeliveryDate: string;
      
      if (orderResponse.quotation) {
        brand = orderResponse.quotation.brand;
        material = orderResponse.quotation.material;
        materialType = orderResponse.quotation.materialType;
        colour = orderResponse.quotation.colour;
        quantity = orderResponse.quotation.quantity;
        deliveryDate = orderResponse.quotation.deliveryDate;
        price = orderResponse.quotation.price.toString();
        validity = orderResponse.quotation.validity;
        creditTerm = orderResponse.quotation.creditTerm;
        remarks = orderResponse.quotation.remarks;
        requotationRemarks = orderResponse.quotation.requotationRemarks;   
        actualDeliveryDate = moment(new Date(orderResponse.actualDeliveryDate)).format('MMMM Do YYYY, h:mm:ss a');
      } else {
        brand = orderResponse.brand;
        material = orderResponse.material;
        materialType = orderResponse.materialType;
        colour = orderResponse.colour;
        quantity = orderResponse.quantity;
        deliveryDate = orderResponse.deliveryDate;
        price = '';
        validity = '';
        creditTerm = '';
        remarks = '';
        requotationRemarks = '';   
        actualDeliveryDate = '';
      }
      this.setState({
        customer: orderResponse.orderBy,
        brand: brand,
        material: material,
        materialType: materialType,
        colour: colour,
        quantity: parseInt(quantity),
        deliveryDate: deliveryDate,
        price: price,
        validity: validity,
        creditTerm: creditTerm,
        remarks: remarks,
        requotationRemarks: requotationRemarks,
        actualDeliveryDate: actualDeliveryDate,
        status: orderResponse.status
      }, () => { this.generateForm(); });
    }
    this.setState({ isLoading: false });
  }

  generateForm() {
    // TODO: this method will caused re-render everytime user key in a character. In future will need change if performance is affected. Temporary do not need to change as of now
    let formInput: Array<any> = [];

    if (this.state.requotationMode) {
      formInput.push({ placeholder: 'Requotation Remarks', onChangeText: this.onChangerequotationRemarks, value: this.state.requotationRemarks});
    } else {
      if (this.props.screenProps.role === 'customer') {
        formInput = [
          { placeholder: 'Customer', onChangeText: () => {}, value: this.state.customer, disabled: true },
          { placeholder: 'Brand', onChangeText: this.onChangeBrand, value: this.state.brand, disabled: true },
          { placeholder: 'Material', onChangeText: this.onChangeMaterial, value: this.state.material, disabled: true },
          { placeholder: 'Type', onChangeText: this.onChangeType, value: this.state.materialType, disabled: true },
          { placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour, disabled: true },
          { placeholder: 'Quantity', onChangeText: this.onChangeQuantity, keyboardType: 'numeric', value: this.state.quantity.toString(), disabled: true },
          { placeholder: 'Delivery Date(DD/MM/YYYY)', onChangeText: this.onChangeDeliveryDate, value: this.state.deliveryDate, disabled: true }
        ];
    
        if (!(this.props.screenProps.role === 'customer' && (this.state.status === 'pending quotation' || this.state.status === 'pending approval'))) {
          formInput.push({ placeholder: 'Price (RM)', onChangeText: this.onChangePrice, keyboardType: 'numeric', value: this.state.price, disabled: true });
          formInput.push({ placeholder: 'Validity', onChangeText: this.onChangeValidity, value: this.state.validity, disabled: true });
          formInput.push({ placeholder: 'Credit Term', onChangeText: this.onChangeCreditTerm, value: this.state.creditTerm, disabled: true });
          formInput.push({ placeholder: 'Remarks', onChangeText: this.onChangeRemarks, value: this.state.remarks, disabled: true });
        }
      } else {
        formInput = [
          { placeholder: 'Customer', onChangeText: () => { }, value: this.state.customer, disabled: true },
          { placeholder: 'Brand', onChangeText: this.onChangeBrand, value: this.state.brand, disabled: true },
          { placeholder: 'Material', onChangeText: this.onChangeMaterial, value: this.state.material, disabled: true },
          { placeholder: 'Type', onChangeText: this.onChangeType, value: this.state.materialType, disabled: true },
          { placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour, disabled: true },
          { placeholder: 'Quantity', onChangeText: this.onChangeQuantity, keyboardType: 'numeric', value: this.state.quantity.toString(), disabled: true },
          { placeholder: 'Delivery Date(DD/MM/YYYY)', onChangeText: this.onChangeDeliveryDate, value: this.state.deliveryDate, disabled: true }
        ];
    
        if (!(this.props.screenProps.role === 'customer' && (this.state.status === 'pending quotation' || this.state.status === 'pending approval'))) {
          formInput.push({ placeholder: 'Price (RM)', onChangeText: this.onChangePrice, keyboardType: 'numeric', value: this.state.price });
          formInput.push({ placeholder: 'Validity', onChangeText: this.onChangeValidity, value: this.state.validity });
          formInput.push({ placeholder: 'Credit Term', onChangeText: this.onChangeCreditTerm, value: this.state.creditTerm });
          formInput.push({ placeholder: 'Remarks', onChangeText: this.onChangeRemarks, value: this.state.remarks });
        }
      }
      
      if (this.state.requotationRemarks !== '' && this.state.requotationRemarks !== undefined) {
		      formInput.push({ placeholder: 'Requotation Remarks', onChangeText: () => {}, value: this.state.requotationRemarks, disabled: true });
		    }
		    
      if (this.state.actualDeliveryDate !== '') {
        formInput.push({ placeholder: 'Actual Delivery Date', onChangeText: () => {}, value: this.state.actualDeliveryDate, disabled: true });
      }
    }
    
    this.setState({ formInput: formInput });
  }

  onChangeBrand(selected: string) {
    this.setState({ brand: selected }, () => this.generateForm());
  }

  onChangeMaterial(selected: string) {
    this.setState({ material: selected }, () => this.generateForm());
  }

  onChangeType(selected: string, index: number) {
    this.setState({ materialType: selected }, () => this.generateForm());
  }

  onChangeColour(selected: string, index: number) {
    this.setState({ colour: selected }, () => this.generateForm());
  }

  onChangeQuantity(input: string) {
    if (input) {
      this.setState({ quantity: parseInt(input.replace(/[^0-9]/g, '')) }, () => this.generateForm());
    } else {
      this.setState({ quantity: 0 }, () => this.generateForm());
    }
  }

  onChangeDeliveryDate(input: string) {
    let newInput: string = input.replace(/[^0-9]/g, '');
    
    if (newInput.length > 4) {
      newInput = newInput.substring(0,2) + '/' + newInput.substring(2,4) + '/' + newInput.substring(4,newInput.length);
    } else if (newInput.length > 3) {
      newInput = newInput.substring(0,2) + '/' + newInput.substring(2,4) + '/';
    } else if (newInput.length > 1) {
      newInput = newInput.substring(0,2) + '/' + newInput.substring(2,newInput.length);
    } 
  
    this.setState({ deliveryDate: newInput }, () => this.generateForm());
  }

  onChangePrice(input: string) {
    this.setState({ price: input }, () => this.generateForm());
  }

  onChangeValidity(input: string) {
    this.setState({ validity: input }, () => this.generateForm());
  }

  onChangerequotationRemarks(input: string) {
    if (input.length < 30) {
      this.setState({ requotationRemarks: input }, () => this.generateForm());
    }
  }
  
  onChangeCreditTerm(input: string) {
    this.setState({ creditTerm: input }, () => this.generateForm());
  }
  
  onChangeRemarks(input: string) {
    this.setState({ remarks: input }, () => this.generateForm());
  }

  async onPurchase() {
    let proceed: string = await this.onConfirm();
    
    if (proceed !== 'proceed') {
      return;
    }
  
    if (this.state.quantity !== 0 && this.state.brand && this.state.material && this.state.materialType && this.state.colour && this.state.deliveryDate) {
      this.setState({ isLoading: true });
      let success: boolean = await OrderService.postOrder(this.props.screenProps.token, { 'brand': this.state.brand, 'material': this.state.material, 'materialType': this.state.materialType, 'colour': this.state.colour, 'quantity': this.state.quantity, 'deliveryDate': this.state.deliveryDate });
      this.setState({ isLoading: false });

      if (success) {
        this.props.navigation.navigate(NavigationActions.navigate({
          routeName: 'Me',
          action: NavigationActions.navigate({
            routeName: 'OrderListing'
          })
        }));
      } else {
        this.setState({
          haveError: true,
          errorMessage: 'An error has occured. Please retry.'
        });
      }
    } else {
      this.setState({
        haveError: true,
        errorMessage: 'Field can not be empty!'
      });
    }
  }

  async onQuote() {
    let proceed: string = await this.onConfirm();
    
    if (proceed !== 'proceed') {
      return;
    }
  
    if (this.state.quantity !== 0 && this.state.brand && this.state.material && this.state.materialType && this.state.colour && this.state.deliveryDate && this.state.price && this.state.validity) {
      let decimalFormat: RegExp = /^[0-9.]*$/g;

      if (decimalFormat.test(this.state.price)) {
        if(moment(this.state.deliveryDate, 'DD/MM/YYYY', true).isValid()) {
          this.setState({ isLoading: true });
          let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.state.customer);
      
          if (getCompanyResponse[0]) {
              let department: any = getCompanyResponse[0].department;
              let notifyTarget: any = await UserService.getManagementByDepartment(this.props.screenProps.token, department);  
            let success: boolean = await OrderService.postQuotation(this.props.screenProps.token, { 'orderId': this.props.navigation.getParam('orderId', ''), 'notifyTarget': notifyTarget, 'quotation': { 'brand': this.state.brand, 'material': this.state.material, 'materialType': this.state.materialType, 'colour': this.state.colour, 'quantity': this.state.quantity, 'deliveryDate': this.state.deliveryDate, 'price': parseFloat(this.state.price), 'validity': this.state.validity, 'creditTerm': this.state.creditTerm, 'remarks': this.state.remarks, 'requotationRemarks': this.state.requotationRemarks} });
            this.setState({ isLoading: false });
  
            if (success) {
              this.props.navigation.navigate('OrderListing', { showIncompleteOnly: true });
            } else {
              this.setState({
                haveError: true,
                errorMessage: 'Invalid Field Format!'
              });
            }
          }
        } else {
            this.setState({
              haveError: true,
              errorMessage: 'Invalid Date! Please input in format DD/MM/YYYY.'
            });
        }
      } else {
        this.setState({
          haveError: true,
          errorMessage: 'Invalid Price Format! Please enter only number/decimal.'
        });
      }
    } else {
      this.setState({
        haveError: true,
        errorMessage: 'Field can not be empty!'
      });
    }
  }

  async onUpdateStatus(status: string) {
    let proceed: string = await this.onConfirm();
    
    if (proceed !== 'proceed') {
      return;
    }
  
    this.setState({ isLoading: true });
    await OrderService.putStatus(this.props.screenProps.token, this.props.navigation.getParam('orderId', ''), status);
    
    if (status === 'quotation accepted') {
      let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.state.customer);
      let department: any = getCompanyResponse[0].department;
				   let notifyTarget: any = await UserService.getManagementByDepartment(this.props.screenProps.token, department);  
		    let notification: any = { orderId: this.props.navigation.getParam('orderId', '') };            
      await NotificationService.postNotification(this.props.screenProps.token, notifyTarget, 'Quotation Accepted', 'Status Update: Quotation is accepted for order ' + this.state.brand + ' ' + this.state.material + ' by ' + this.state.customer, 'QuotationScreen', notification);
    }
      
    this.setState({ isLoading: false });

    this.props.navigation.navigate('OrderListing');
  }
  
  async onAcceptQuotation() {
    let proceed: string = await this.onConfirm();
    
    if (proceed !== 'proceed') {
      return;
    }
  
    if (this.props.screenProps.tier === 'management') {
      this.onUpdateStatus('quotation accepted');
    } else {
		    let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
		    
		    if (getCompanyResponse[0]) {
				    let userTier: any = getCompanyResponse[0].userTier;
				    
				    if (userTier === 1 || (userTier === 2 && this.props.screenProps.tier === 'manager')) {
				      this.onUpdateStatus('quotation accepted');
				    } else if (this.props.screenProps.tier === 'staff') {
				      this.onUpdateStatus('pending approval tier manager');
				    } else if (this.props.screenProps.tier === 'manager') {
				      this.onUpdateStatus('pending approval tier management');
				    }
		    }
    }
  }
  
  onConfirm() {  
    return new Promise((resolve, reject) => {
      if (Platform.OS === 'web') {
        let res: any = window.confirm(`Confirm To Proceed?`);
      
        if (res) {
          resolve('proceed');
        } else {
          resolve('cancel');
        }
      } else {
        Alert.alert('Confirm To Proceed?', '', [ 
          { text: 'Cancel', onPress: () => resolve('cancel'), style: 'cancel' }, 
          { text: 'OK', onPress: () => resolve('proceed') } 
        ], { cancelable: false } );
      }
    });
  }

  render() {
    return (
      <View style={styles.purchaseContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Quotation' onBack={() => this.props.navigation.goBack()} back={true} notification={false} onPress={() => this.props.navigation.navigate('NotificationScreen')} />
        <ScrollView>
          {/* upload shipment not sure need to include or not  from supplier or from sales */}
          {this.props.screenProps.role === 'sales' && this.state.status === 'order confirmed'  && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Upload Shipment'} buttonOnPress={() => {this.props.navigation.navigate('UploadShipmentScreen'), {orderId: this.props.navigation.getParam('orderId', '')}}} />}
          {this.props.screenProps.role === 'customer' && (this.state.status === 'pending quotation' || this.state.status === 'pending approval' || this.state.status.includes('requotation requested')) && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Pending Quotation'} buttonOnPress={()=>{}} />}
          {this.props.screenProps.role === 'sales' && (this.state.status === 'pending quotation' || this.state.status === 'pending approval' || this.state.status.includes('requotation requested')) && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Quote'} buttonOnPress={this.onQuote} />}
          {this.props.screenProps.role === 'sales' && (this.state.status === 'quotation approved' || this.state.status === 'pending approval tier manager' || this.state.status === 'pending approval tier management') && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Quotation Approved'} buttonOnPress={() => {}} />}
          {this.props.screenProps.role === 'sales' && this.state.status === 'quotation accepted' && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Mark As Complete'} buttonOnPress={() => this.onUpdateStatus('order completed')} />}
          {this.props.screenProps.role === 'management' && (this.state.status === 'pending quotation' || this.state.status === 'pending approval' || this.state.status.includes('requotation requested')) && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Approve Quotation'} buttonOnPress={this.onQuote} />}
          {this.props.screenProps.role === 'management' && (this.state.status === 'quotation approved' || this.state.status === 'pending approval tier manager' || this.state.status === 'pending approval tier management') && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Quotation Approved'} buttonOnPress={() => {}} />}
          {this.props.screenProps.role === 'management' && this.state.status === 'quotation accepted' && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Quotation Accepted'} buttonOnPress={() => {}} />}
          {this.props.screenProps.role === 'customer' && this.state.status === 'quotation approved' && !this.state.requotationMode && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Accept'} buttonOnPress={this.onAcceptQuotation} buttonText2={'Requotation'} buttonOnPress2={() => this.setState({requotationMode: true}, () => { this.generateForm(); })} />}
          {this.props.screenProps.role === 'customer' && this.state.requotationMode && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Request Requotation'} buttonOnPress={this.onQuote} />}
          {this.props.screenProps.role === 'customer' && this.props.screenProps.tier === 'staff' && (this.state.status === 'pending approval tier manager' || this.state.status === 'pending approval tier management') && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Pending Approval'} buttonOnPress={() => {}} />}
          {this.props.screenProps.role === 'customer' && this.props.screenProps.tier === 'manager' && !this.state.requotationMode && this.state.status === 'pending approval tier manager' && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Approve'} buttonOnPress={this.onAcceptQuotation} buttonText2={'Requotation'} buttonOnPress2={() => this.setState({requotationMode: true}, () => { this.generateForm(); })} />}
          {this.props.screenProps.role === 'customer' && this.props.screenProps.tier === 'manager' && this.state.status === 'pending approval tier management' && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Pending Approval'} buttonOnPress={() => {}} />}
          {this.props.screenProps.role === 'customer' && this.props.screenProps.tier === 'management' && !this.state.requotationMode && (this.state.status === 'pending approval tier manager' || this.state.status === 'pending approval tier management') && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Approve'} buttonOnPress={this.onAcceptQuotation} buttonText2={'Requotation'} buttonOnPress2={() => this.setState({requotationMode: true}, () => { this.generateForm(); })} />}
          {this.props.screenProps.role === 'customer' && this.state.status === 'quotation accepted' && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Quotation Accepted'} buttonOnPress={() => {this.props.navigation.navigate('UploadDocumentScreen', {orderId: this.props.navigation.getParam('orderId', '')})}} />}
          {/* added this code to show the detail of quotation when the status is order confirmed */}
          {this.props.screenProps.role === 'customer' && this.state.status === 'order confirmed' && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Order Confirmed'} buttonOnPress={() => {}} /> } 
          {/* {this.props.screenProps.role === 'sales' && this.state.status === 'order confirmed' && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Order Confirmed'} buttonOnPress={() => {}} /> }  */}
          {this.state.status === 'order completed' && <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Order Completed'} buttonOnPress={() => {}} />}
        </ScrollView>
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default PurchaseScreen;

// export default function (props) {
//   const navigation = useNavigation();

//   return <PurchaseScreen {...props} navigation={navigation} />;
// }