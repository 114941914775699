import React, { Component } from "react";
import {
  View,
  Text,
  KeyboardAvoidingView,
  Alert,
  Platform,
  TouchableOpacity,
} from "react-native";
import { Input, Button } from "react-native-elements";
import { NavigationEvents } from "react-navigation";
import {
  NavigationStackProp,
  NavigationStackScreenProps,
} from "react-navigation-stack";
import { ScrollView, TextInput } from "react-native-gesture-handler";
import moment from "moment";
import styles from "../styles/supplierQuotingScreen.styles";
import Header from "../components/header";
import Loader from "../components/loader";
import BackBar from "../components/backBar";
import * as SupplierService from "../services/supplierService";
import * as CompanyService from "../services/companyService";
import * as UserService from "../services/userService";
import * as NotificationService from "../services/notificationService";
import Field from "../components/Field";
import { LinearGradient } from "expo-linear-gradient";

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  supplier: string;
  brand: string;
  product: string;
  minimumOrderQuantity: string;
  validity: string;
  haveError: boolean;
  errorMessage: string;
  isLoading: boolean;
  price: string;
  status: string;
  date: string;
  companyTier: number;
  quotingId: string;
  allowedAction: boolean;
  buttonText: string;
}

class SupplierQuotingScreen extends Component<
  CustomInputProps,
  CustomInputStates
> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      supplier: "",
      brand: "",
      product: "",
      minimumOrderQuantity: "",
      validity: "",
      haveError: false,
      errorMessage: "",
      isLoading: false,
      price: "",
      status: "",
      date: "",
      companyTier: 0,
      quotingId: "",
      allowedAction: false,
      buttonText: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onQuote = this.onQuote.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    if (this.props.navigation.getParam("quotingId", "") !== "") {
      let getQuotingResponse: any = await SupplierService.getQuotingById(
        this.props.screenProps.token,
        this.props.navigation.getParam("quotingId", "")
      );

      if (getQuotingResponse) {
        let getCompanyResponse: any = await CompanyService.getCompany(
          this.props.screenProps.token,
          getQuotingResponse.supplier
        );

        let buttonText: string;
        let allowedAction: boolean;
        if (getQuotingResponse.status === "approved") {
          buttonText = "Approved";
          allowedAction = false;
        } else if (this.props.screenProps.tier === "management") {
          buttonText = "Approve";
          allowedAction = true;
        } else if (this.props.screenProps.tier === "staff") {
          buttonText = "Pending Approval";
          allowedAction = false;
        } else if (this.props.screenProps.tier === "manager") {
          if (getQuotingResponse.status === "pending manager approval") {
            buttonText = "Verify";
            allowedAction = true;
          } else {
            buttonText = getQuotingResponse.status;
            allowedAction = false;
          }
        }

        this.setState({
          supplier: getCompanyResponse[0].name,
          brand: getQuotingResponse.brand,
          product: getQuotingResponse.product,
          price: getQuotingResponse.price.toFixed(2),
          status: getQuotingResponse.status,
          date: moment(new Date(getQuotingResponse.date)).format(
            "MMMM Do YYYY"
          ),
          companyTier: getCompanyResponse[0].userTier,
          quotingId: getQuotingResponse._id,
          buttonText: buttonText,
          allowedAction: allowedAction,
          isLoading: false,
          minimumOrderQuantity: getQuotingResponse.minimumOrderQuantity.toString()
        });
      }
    } else {
      let getCompanyResponse: any = await CompanyService.getCompany(
        this.props.screenProps.token,
        this.props.screenProps.companyId
      );

      this.setState({
        supplier: getCompanyResponse[0].name,
        companyTier: getCompanyResponse[0].userTier,
        allowedAction: true,
        buttonText: "Quote",
        brand: "",
        product: "",
        haveError: false,
        errorMessage: "",
        isLoading: false,
        price: "",
        minimumOrderQuantity: "",
      });

    }
  }

  onChange(name: string, input: string) {
    this.setState({ [name]: input });
  }

  async onQuote() {
    let proceed: string = await this.onConfirm();

    if (proceed !== "proceed") {
      return;
    }

    if (
      this.state.brand &&
      this.state.product &&
      this.state.price &&
      this.state.minimumOrderQuantity
    ) {
      let decimalFormat: RegExp = /^[0-9.]*$/g;

      if (
        decimalFormat.test(this.state.price) ||
        decimalFormat.test(this.state.minimumOrderQuantity)
      ) {
        let status: string,
          apiResponse: any,
          notifyTarget: any,
          notifyTitle: string,
          notifyText: string,
          destination: string,
          destinationId: any;

        if (
          this.state.companyTier === 1 ||
          (this.state.companyTier === 2 &&
            this.props.screenProps.tier === "manager") ||
          this.props.screenProps.tier === "management"
        ) {
          status = "approved";
          notifyTarget = await UserService.getManagementId(
            this.props.screenProps.token
          );
          notifyTitle = "New Quoting from Supplier";
          notifyText =
            "New quoting available from supplier " +
            this.state.supplier +
            " for product " +
            this.state.product;
          destination = "QuotingScreen";
        } 
        else if (this.props.screenProps.tier === "staff") {
          status = "pending manager approval";
          apiResponse = await UserService.getUserByCompanyTier(
            this.props.screenProps.token,
            this.props.screenProps.companyId,
            "manager"
          );
          notifyTarget = apiResponse[0]._id;
          notifyTitle = "Verification Needed for New Quoting";
          notifyText =
            "Verification is needed for new quoting. Brand: " +
            this.state.brand +
            ", Product: " +
            this.state.product;
          destination = "QuotingScreen";
        } else if (this.props.screenProps.tier === "manager") {
          status = "pending management approval";
          apiResponse = await UserService.getUserByCompanyTier(
            this.props.screenProps.token,
            this.props.screenProps.companyId,
            "management"
          );
          notifyTarget = apiResponse[0]._id;
          notifyTitle = "Approval Needed for New Quoting";
          notifyText =
            "Approval is needed for new quoting. Brand: " +
            this.state.brand +
            ", Product: " +
            this.state.product;
          destination = "QuotingScreen";
        }

        let quotingPayload: any = {
          supplier: this.props.screenProps.companyId,
          brand: this.state.brand,
          product: this.state.product,
          minimumOrderQuantity: parseInt(this.state.minimumOrderQuantity),
          validity: this.state.validity,
          price: parseFloat(this.state.price),
          status: status,
          date: moment().format(),
        };
        console.log("will it print? \n", quotingPayload);

        this.setState({ isLoading: true });
        let success: boolean;
        if (this.props.navigation.getParam("quotingId", "") === "") {
          let result: any = await SupplierService.postQuoting(
            this.props.screenProps.token,
            quotingPayload
          );
          destinationId = { quotingId: result.data._id };
          success = result.success;
          console.log("Result: ", result);
        } else {
          success = await SupplierService.postQuotingById(
            this.props.screenProps.token,
            this.state.quotingId,
            quotingPayload
          );
          destinationId = { quotingId: this.state.quotingId };
        }

        if (success) {
          await NotificationService.postNotification(
            this.props.screenProps.token,
            notifyTarget,
            notifyTitle,
            notifyText,
            destination,
            destinationId
          );
          this.setState({ isLoading: false });
          this.props.navigation.navigate("QuotingListing");
        } else {
          this.setState({
            isLoading: false,
            haveError: true,
            errorMessage: "An error has occured, please try again!",
          });
        }
      } else {
        this.setState({
          haveError: true,
          errorMessage:
            "Invalid Price Format! Please enter only number/decimal.",
        });
      }
    } else {
      this.setState({
        haveError: true,
        errorMessage: "Field can not be empty!",
      });
    }
  }

  onConfirm() {
    return new Promise((resolve, reject) => {
      if (Platform.OS === "web") {
        let res: any = window.confirm(`Confirm To Proceed?`);

        if (res) {
          resolve("proceed");
        } else {
          resolve("cancel");
        }
      } else {
        Alert.alert(
          "Confirm To Proceed?",
          "",
          [
            {
              text: "Cancel",
              onPress: () => resolve("cancel"),
              style: "cancel",
            },
            { text: "OK", onPress: () => resolve("proceed") },
          ],
          { cancelable: false }
        );
      }
    });
  }

  render() {
    return (
      <View style={styles.quotingContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header
          headerText="Quoting"
          onPress={() => this.props.navigation.navigate("NotificationScreen")}
        />
        {this.state.quotingId !== "" && (
          <BackBar
            onPress={() => this.props.navigation.navigate("QuotingListing")}
          />
        )}
        <KeyboardAvoidingView style={[styles.ctn, styles.shadow]} enabled>
          <ScrollView contentContainerStyle={styles.scrollViewContainer}>
            {/*Changed the UI as well as bug fixed for the UI */}

            <Text style={[styles.title]}>Supplier</Text>
            <TextInput
              placeholder="Supplier"
              style={styles.field}
              editable={false}
              value={this.state.supplier}
            />

            <Text style={[styles.title]}>Brand</Text>
            <TextInput
              placeholder="Brand"
              style={styles.field}
              onChangeText={(value) => this.onChange("brand", value)}
              value={this.state.brand}
            />

            <Text style={[styles.title]}>Product</Text>
            <TextInput
              placeholder="Product"
              style={styles.field}
              onChangeText={(value) => this.onChange("product", value)}
              value={this.state.product}
            />

            <Text style={[styles.title]}>Minimum Order Quantity</Text>
            <TextInput
              placeholder="Minimum Order Quantity"
              style={styles.field}
              onChangeText={(value) =>
                this.onChange("minimumOrderQuantity", value)
              }
              value={this.state.minimumOrderQuantity}
            />

            <Text style={[styles.title]}>Price</Text>
            <TextInput
              placeholder="Price"
              style={styles.field}
              onChangeText={(value) => this.onChange("price", value)}
              value={this.state.price}
            />

            {this.state.quotingId !== "" && (
              <Text style={[styles.title]}>Quoting Date</Text>
            )}
            {this.state.quotingId !== "" && (
              <TextInput
                placeholder="Quoting Date"
                style={styles.field}
                editable={true}
                value={this.state.date}
              />
            )}

            {this.props.screenProps.role === "supplier" && (
              <TouchableOpacity disabled={!this.state.allowedAction} onPress={this.onQuote} style={[styles.button]}>
                <LinearGradient
                  start={{ x: 1, y: 0 }}
                  end={{ x: -1, y: 0 }}
                  colors={["#6DD34D", "#2f883C", "#0E6B35"]}
                  style={[styles.gradient]}
                >
                  <Text style={{ color: "white", fontSize: 20, fontWeight: "600" }}>
                    {this.state.buttonText}
                  </Text>
                </LinearGradient>
              </TouchableOpacity>
            )}
          </ScrollView>
        </KeyboardAvoidingView>
        {this.state.haveError && (
          <Text style={styles.errorMessage}>{this.state.errorMessage}</Text>
        )}
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default SupplierQuotingScreen;

{/* <TouchableOpacity
                style={styles.button}
                disabled={!this.state.allowedAction}
                onPress={this.onQuote}
              >
                <Text>{this.state.buttonText}</Text>
              </TouchableOpacity> */}