import React, { Component } from 'react';
import { View } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import moment from 'moment';
import Form from '../components/form';
import styles from '../styles/uploadDocumentScreen.styles';
import Header from '../components/header';
import Loader from '../components/loader';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
    navigation: NavigationStackProp;
    screenProps: NavigationStackScreenProps;
  }
  
  interface CustomInputStates {
    formInput: Array<any>;
    supplier: string;
    shipmentId: string;
    etd: string;
    fullContainerLoad: string;
    availableLoadBalance: string;
    minimumLoad: string;
    isLoading: boolean;
    haveError: boolean;
    errorMessage: string;
    freeTextInput: boolean;
    shipmentList: Array<any>;
  }
  
  class UploadShipmentScreen extends Component<CustomInputProps, CustomInputStates> {
    constructor(props: CustomInputProps) {
      super(props);
  
      this.state = {
        formInput: [],
        supplier: '',
        shipmentId: '',
        etd: '',
        fullContainerLoad: '',
        availableLoadBalance: '',
        minimumLoad: '',
        isLoading: false,
        haveError: false,
        errorMessage: '',
        freeTextInput: false,
        shipmentList: []
      }
      this.generateForm = this.generateForm.bind(this);
      this.onChangeShipmentId = this.onChangeShipmentId.bind(this);
      this.onChangeSupplier = this.onChangeSupplier.bind(this);
      this.onChangeDeliveryDate = this.onChangeDeliveryDate.bind(this);
      this.onChangeFullContainerLoad = this.onChangeFullContainerLoad.bind(this);
      // this.onChangeAvailableLoad = this.onChangeAvailableLoad.bind(this);
      this.onChangeMinimumLoad = this.onChangeMinimumLoad.bind(this);
      this.onPostShipment = this.onPostShipment.bind(this);
      
      }

    async componentDidMount() { 
      let shipmentListingResponse: any = [];
      let shipmentListing: Array<any> = [];
      let new_shipmentId: string = '';
      this.setState({ isLoading: true });
      //Service getShipmentAll haven't been implemented
      shipmentListingResponse = await CompanyService.getShipmentAll(this.props.screenProps.token);
      this.setState({ isLoading: false });
    
      this.setState({
        shipmentId: new_shipmentId,
        supplier: this.props.navigation.getParam('supplier', ''),
        etd: this.props.navigation.getParam('estimatedDeliveryDate', ''),
        fullContainerLoad: this.props.navigation.getParam('fullContainerLoad', ''),
        availableLoadBalance: this.props.navigation.getParam('availableLoadBalance', ''),
        minimumLoad: this.props.navigation.getParam('minimumLoad', ''),
        shipmentList: shipmentListing,
      }, () => { this.generateForm() });

      if (shipmentListingResponse) {
        shipmentListing = shipmentListingResponse.map(a => Object.assign({}, a));
        let shipmentAll: Array<string> = shipmentListing.map(item => item._id).filter((value, index, self) => self.indexOf(value) === index);
          let new_shipmentId_num: number = parseInt(shipmentAll[shipmentAll.length-1].slice(9,12))+1;
          if (new_shipmentId_num < 10){
            new_shipmentId = "Shipment 00"+ new_shipmentId_num;
          } else if (new_shipmentId_num < 100) {
            new_shipmentId = "Shipment 0"+new_shipmentId_num;
          } else if (new_shipmentId_num < 1000) {
            new_shipmentId = "Shipment "+new_shipmentId_num;
          }
      } 
      
    }

    generateForm() {
        let formInput: Array<any> = [];
        if (this.state.freeTextInput) {
            formInput.push(
                { placeholder: 'Supplier', onChangeText: this.onChangeSupplier, value: this.state.supplier },
                { placeholder: 'ShipmentId', onChangeText: this.onChangeShipmentId, value: this.state.shipmentId },
                { placeholder: 'Estimated Delivery Date(DD/MM/YYYY)', onChangeText: this.onChangeDeliveryDate, value: this.state.etd },
                { placeholder: 'Full Container Load', onChangeText: this.onChangeFullContainerLoad, value: this.state.fullContainerLoad },
                { placeholder: 'Minimum Load', onChangeText: this.onChangeMinimumLoad, keyboardType: 'numeric', value: this.state.minimumLoad.toString() },
              );
        } else {
          let shipmentListing: Array<any> = this.state.shipmentList.map(a => Object.assign({}, a));
          let supplierOption: Array<string> = shipmentListing.map(item => item.supplier).filter((value, index, self) => self.indexOf(value) === index);
          
          
          supplierOption.push('Others');
          supplierOption.sort();

            if (this.state.supplier) {
              let supplierSelected: string = this.state.supplier;
              shipmentListing = shipmentListing.filter(function(shipment: any) {
                return shipment['supplier'] === supplierSelected;
              });
            }

          formInput.push(
            { placeholder: 'Supplier', onChangeText: this.onChangeSupplier, value: this.state.supplier, option: supplierOption, inputType: 'picker' },
            { placeholder: 'Shipment ID', onChangeText: this.onChangeShipmentId, value: this.state.shipmentId, inputType: 'text' },
            { placeholder: 'Estimated Delivery Date(DD/MM/YYYY)', onChangeText: this.onChangeDeliveryDate, value: this.state.etd, inputType: 'text' },
            { placeholder: 'Full Container Load', onChangeText: this.onChangeFullContainerLoad, value: this.state.fullContainerLoad, inputType: 'numeric' },
            { placeholder: 'Minimum Load', onChangeText: this.onChangeMinimumLoad, value: this.state.minimumLoad, inputType: 'numeric' }
          );  
        }
        this.setState({formInput: formInput});

    }

    onChangeSupplier(selected: string) {
        if (selected === 'Others') {
          this.setState({ freeTextInput: true }, () => this.generateForm());
        } else {
          this.setState({supplier: selected}, () => this.generateForm())
        }
    }

    onChangeShipmentId(input: string) {
      if (input) {
        this.setState({ shipmentId: input }, () => this.generateForm());
      } else {
        this.setState({ shipmentId: input }, () => this.generateForm());
      }
    }

    onChangeFullContainerLoad(input: string) {
      let full = "16";
      if (input) {
        this.setState({ fullContainerLoad: input }, () => this.generateForm());
      } else {
        this.setState({ fullContainerLoad: full}, () => this.generateForm());
      }
    }

    onChangeMinimumLoad(input: string) {
      let min = "16";
      if (input) {
        this.setState({ minimumLoad: input }, () => this.generateForm());
      } else {
        this.setState({ minimumLoad: min}, () => this.generateForm());
      }
    }
    
    onChangeDeliveryDate(input: string) {
        let newInput: string = input.replace(/[^0-9]/g, '');
        
        if (newInput.length > 4) {
          newInput = newInput.substring(0,2) + '/' + newInput.substring(2,4) + '/' + newInput.substring(4,newInput.length);
        } else if (newInput.length > 3) {
          newInput = newInput.substring(0,2) + '/' + newInput.substring(2,4) + '/';
        } else if (newInput.length > 1) {
          newInput = newInput.substring(0,2) + '/' + newInput.substring(2,newInput.length);
        } 
        
        this.setState({ etd: newInput }, () => this.generateForm());
      }
  
      async onPostShipment() {

        let shipmentPayLoad = {
          supplier: this.state.supplier,
          _id: this.state.shipmentId,
          estimatedDeliveryDate: moment(this.state.etd, 'DD/MM/YYYY'),
          fullContainerLoad: parseInt(this.state.fullContainerLoad),
          minimumLoad: parseInt(this.state.minimumLoad),
          availableLoadBalance: parseInt(this.state.fullContainerLoad)
        }
      
        this.setState({ isLoading: true });
        await CompanyService.postShipment(this.props.screenProps.token, shipmentPayLoad);
        
        this.setState({ isLoading: false });
    
        this.props.navigation.navigate('SupplierListing');
      }

    render() {
      let header;
      if (this.props.screenProps.role === 'sales'){
        header = <Header headerText='Shipments' onBack={() => this.props.navigation.goBack()} back={true} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti}/>
      }else{
        header = <Header headerText='Shipments' onBack={() => this.props.navigation.goBack()} back={false} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti}/>
      }
      return (
        <View style={styles.feedContainer}>
          {header}
          <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Upload Shipment'} buttonOnPress={() => this.onPostShipment()} />
          <Loader isLoading={this.state.isLoading} />
        </View>
        );
      }
  }
  export default UploadShipmentScreen;
