import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/supplierAboutMeScreen.styles';
import Header from '../components/header';
import Icon from "react-native-vector-icons/Ionicons";

import FontAwesome5 from "react-native-vector-icons/FontAwesome5";


interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class SupplierAboutMe extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
  }

  onNavigate(destination: string) {
    this.props.navigation.navigate(destination);
  }
  
  render() {
    return (
      <View style={styles.aboutMeContainer}>
        <Header headerText='About Me' onBack={() => this.props.navigation.goBack()} back={true} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti}/>
        {/* <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('CompanyDetailScreen')}>
          <Text style={styles.itemText}>{'\u2022'}     Company Detail</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('QuotingListing')}>
          <Text style={styles.itemText}>{'\u2022'}     Quoting History</Text>
        </TouchableOpacity> */}
        
        <View style={styles.box}>
        <TouchableOpacity
          onPress={() => this.onNavigate("CompanyDetailScreen")}
          style={{ alignItems: "center" }}
        >
          <View style={[styles.button, styles.buttonShadow]}>
            <Icon name="md-business-outline" size={60} />
          </View>
          <Text style={[styles.text]}>Company Detail</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.onNavigate("QuotingListing")}
          style={{ alignItems: "center" }}
        >
          <View style={[styles.button, styles.buttonShadow]}>
            <FontAwesome5 name="history" size={60} />
          </View>
          <Text style={[styles.text]}>Quoting History</Text>
        </TouchableOpacity>
        </View>
        
      </View>
    )
  }
}

export default SupplierAboutMe;