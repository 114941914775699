import React, { Component } from 'react';
import { View, Image, Text, Platform, Alert, RefreshControl, ScrollView, TouchableOpacity, Modal, Pressable, Dimensions, ActivityIndicator } from 'react-native';import { Button } from 'react-native-elements';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import moment from 'moment';
import styles from '../styles/feedScreen.styles';
import Header from '../components/header';
import Loader from '../components/loader';
import * as feedService from '../services/feedService';
import * as apis from '../configs/devApis';
//'../configs/apis' change need when move to production server
import * as CompanyService from '../services/companyService';
import Icon from "react-native-vector-icons/Ionicons";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  feedList: Array<any>;
  department: string;
  status: string;
  isLoading: boolean;
  isRefreshing: boolean;
  isModalVisible: boolean;
  imagesKey: any;
  tempItem: any;
  blur: boolean;
  feedLength: number;
  reachMaxFeedLength: boolean;
}

class FeedScreen extends Component<CustomInputProps, CustomInputStates> {
  mounted: boolean = false;
  constructor(props: CustomInputProps) {
    super(props);
    this.state = {
      feedList: [],
      department: '',
      status:'',
      isLoading: false,
      isRefreshing: false,
      isModalVisible: false,
      imagesKey: null,
      tempItem: {},
      blur: false,
      feedLength: 0,
      reachMaxFeedLength: false,
    }

    this.loadMore = this.loadMore.bind(this)
  }
  
  async getAllData(){   //Get all feed data from database
    this.setState({isLoading : true, feedLength: this.state.feedLength + 5});
    let tempList: Array<any> = this.state.feedList.slice();
    let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
    
    if (getCompanyResponse[0]) {
      this.setState({
        department: getCompanyResponse[0].department,
        status: getCompanyResponse[0].status
      });
    }else{
      this.setState({
        department: 'All',
        status: 'approved'
      });
    }

    let feedResponse = await feedService.getAllFeed(this.props.screenProps.token, this.state.feedLength);
    

    if (feedResponse) {
      // feedResponse.sort(function (a: any, b: any) {
      //   return new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime();
      // });
      
      if (this.props.screenProps.role === 'customer') {  //Need to review, not separate by department anymore

        if(this.state.department==='chemicals')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Chemicals' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }else if(this.state.department==='welding')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Welding' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }else if(this.state.department==='lighting')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Lighting' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }

        let feedList: Array<any> = [];
        feedResponse.map((item: any, key: any) => (
          feedList.push({
            'title': item.caption,
            'content': 'uploadDate:'+item.uploadDate
          })
        ));
        this.setState({ feedList: feedResponse });        
      }
      else if (this.props.screenProps.role === 'management'){ //Need to review, not separate by department anymore

        if(this.props.screenProps.department==='chemicals')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Chemicals' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }else if(this.props.screenProps.department==='welding')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Welding' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }else if(this.props.screenProps.department==='lighting')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Lighting' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }

        let feedList: Array<any> = [];
        feedResponse.map((item: any, key: any) => (
          feedList.push({
            'title': item.caption,
            'content': 'uploadDate:'+item.uploadDate
          })
        ));
        this.setState({ feedList: feedResponse });	 
        console.log('times' + this.state.reachMaxFeedLength)    
      }
    }
    if(JSON.stringify(tempList) === JSON.stringify(this.state.feedList)) {
      this.setState({reachMaxFeedLength: true});
    } else {
      this.setState({reachMaxFeedLength: false});
    }
    this.setState({ isLoading: false, isRefreshing: false});
  }
  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      this.getAllData();
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  async deleteFeed(feedId: any) {  //Delete feed function only available for EP management
    let proceed: unknown = await this.onConfirm();
    if (proceed as string !== 'proceed') {
      return;
    }
    this.setState({ isLoading: true });
    await feedService.deleteFeed(this.props.screenProps.token, feedId.value);
    this.setState({ isLoading: false });
    this.componentDidMount();
  }
  
  onRefreshing = () => {   //Refresh function
    this.setState({isRefreshing: true});
    this.componentDidMount();
    
  }

  onConfirm() {   //Confirm function
    return new Promise((resolve, reject) => {
      if (Platform.OS === 'web') {
        let res: any = window.confirm(`Confirm To Proceed?`);
      
        if (res) {
          resolve('proceed');
        } else {
          resolve('cancel');
        }
      } else {
        Alert.alert('Confirm To Proceed?', '', [ 
          { text: 'Cancel', onPress: () => resolve('cancel'), style: 'cancel' }, 
          { text: 'OK', onPress: () => resolve('proceed') } 
        ], { cancelable: false } );
      }
    });
  }

  loadMore(){
    if(!this.state.reachMaxFeedLength){
      this.componentDidMount();
    }
  }

  render() {
    let header;
    if (this.props.screenProps.role === 'management') {
      header = <Header headerText='News Feed' onPress={() => this.props.navigation.navigate('NotificationScreen')} onBack={() => this.props.navigation.goBack()} back={true} notification={true} badgeCount={this.props.screenProps.unreadNoti} />
    } else {
      header = <Header headerText='News Feed' onPress={() => this.props.navigation.navigate('NotificationScreen')} onBack={() => this.props.navigation.goBack()} back={false} notification={true} badgeCount={this.props.screenProps.unreadNoti}/>
    }
    return (
      <>
        {header}
        {/* added a scroll to refresh */}
        {/* added focus view */}
        <ScrollView onMomentumScrollEnd={this.loadMore} refreshControl={<RefreshControl refreshing={this.state.isRefreshing} onRefresh={() =>  this.onRefreshing()}/>}>
          <View style={styles.feedContainer}>
          {this.state.feedList.length > 0 && this.state.feedList.map((item, key) => {
              return (
                <TouchableOpacity
                  key={key}
                  onPress={() => this.setState({ isModalVisible: !this.state.isModalVisible, tempItem: item, imagesKey: { uri: apis.STATIC_PATH + item.photoURI.substring(item.photoURI.lastIndexOf('/')) } })}
                  style={styles.cardContainter}
                >
                  <View style={{ flex: 2 }}>
                    <Image
                      source={{ uri: apis.STATIC_PATH + item.photoURI.substring(item.photoURI.lastIndexOf('/')) }}
                      style={{
                        width: "100%",
                        height: 250,
                        borderRadius: 15,
                      }}
                    />
                  </View>
                  <Modal
                    animationType={Platform.OS === 'ios' ? "slide" : "none"}
                    transparent={true}
                    visible={this.state.isModalVisible}
                    onRequestClose={() => {
                      Alert.alert("Modal has been closed.");
                      this.setState({ isModalVisible: !this.state.isModalVisible })
                    }}
                  >
                    <View style={styles.centeredView}>
                      <View style={styles.modalView}>
                        <Pressable
                          style={[styles.closeButton]}
                          onPress={() => this.setState({ isModalVisible: !this.state.isModalVisible })}
                        >
                          <Icon name='close' size={24}></Icon>
                        </Pressable>
                        <Pressable style={{ width: "100%", height: "85%" }} onPress={() => this.setState({ blur: !this.state.blur })}>
                          <Image
                            source={this.state.imagesKey}
                            style={{ width: "100%", height: "100%" }}
                            resizeMode="contain"
                          />
                          <View style={styles.modalCardContainer}>
                          <View style={[styles.modalFeedCaption, { opacity: this.state.blur ? 0 : 0.9, marginTop:"114%" }]}>
                            <View style={{ justifyContent: "flex-start"}}>
                              <Text style={styles.feedText}>{this.state.tempItem.caption}</Text>
                            </View>
                            <View style={{ justifyContent: "flex-end"}}>
                              <Text>Posted by
                                <Text style={styles.feedBold}> {this.state.tempItem.createdBy}</Text>{"\n"}on
                                <Text> {moment(new Date(this.state.tempItem.uploadDate)).format('Do MMMM YYYY')}</Text>
                              </Text>
                            </View>
                          </View>
                          </View>
                        </Pressable>
                        { this.props.screenProps.role === 'management' && <View style={styles.deleteView}>
                          <Pressable style={[styles.deleteButton]}
                            onPress={() => { this.deleteFeed({ value: this.state.tempItem._id }), this.setState({ isModalVisible: !this.state.isModalVisible }) }}>
                            <Text style={styles.textStyle}>Delete</Text>
                          </Pressable>
                        </View>}
                      </View>
                    </View>
                  </Modal>
                  
                  <View style={styles.feedCaption}>
                    <View style={{ justifyContent: "flex-start"}}>
                      <Text numberOfLines={2} ellipsizeMode='tail' style={styles.feedText}>{item.caption}</Text>
                    </View>
                    <View style={{ justifyContent: "flex-end"}}>
                      <Text>Posted by
                        <Text style={styles.feedBold}> {item.createdBy}</Text>{"\n"}on
                        <Text> {moment(new Date(item.uploadDate)).format('Do MMMM YYYY')}</Text>
                      </Text>
                    </View>
                    
                  </View>
                </TouchableOpacity>
              );
            }
          )}
          </View>
          {!this.state.reachMaxFeedLength && <View style={{marginBottom:10, padding:10, paddingHorizontal:25, alignItems:'flex-start' }}>
            {this.state.isLoading && <ActivityIndicator/>}
            {!this.state.isLoading && <Text style={{fontWeight: '400'}}>Scroll up to load more</Text>}
          </View>}
          {this.state.reachMaxFeedLength && <View style={{marginBottom:10, padding:10, paddingHorizontal:25 }}>
            <Text style={{fontWeight: '400'}}>End of Feed</Text>
          </View>}
        </ScrollView>
        {this.props.screenProps.role === 'management' && 
        <TouchableOpacity style={[styles.btn, styles.shadow]} onPress={() => this.props.navigation.navigate('UploadFeedScreen')}>
            <MaterialIcons name="add" size={35} color="white" />
        </TouchableOpacity>}
        {/* <Loader isLoading={this.state.isLoading}/> */}
      </>
    );
  }
}
export default FeedScreen;
