import React, { Component } from "react";
import { View, Text } from "react-native";
import {
  NavigationStackProp,
  NavigationStackScreenProps,
} from "react-navigation-stack";
import moment from "moment";
import styles from "../styles/notificationScreen.styles";
import Header from "../components/header";
import RowList from "../components/rowList";
import * as notificationService from "../services/notificationService";
import { NavigationEventSubscription } from "react-navigation";

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  notifications: Array<any>;
  refreshing: boolean;
  notificationLength: number;
  reachMaxNotificationLength: boolean;
}

class NotificationScreen extends Component<
  CustomInputProps,
  CustomInputStates
> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      notifications: [],
      refreshing: false,
      notificationLength: 0,
      reachMaxNotificationLength: false,
    }
    
    this.onWillFocus = this.onWillFocus.bind(this);
    this.getData = this.getData.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  focusSubscription: NavigationEventSubscription = null;

  componentDidMount() {
    this.focusSubscription = this.props.navigation.addListener(
      "willFocus",
      this.onWillFocus
    );
  }

  componentWillUnmount() {
    this.focusSubscription && this.focusSubscription.remove();
    this.focusSubscription = null;
  }

  async onWillFocus(payload) {
    await this.getData();
  }

  async getData() { //get all notification from database
    let tempList: Array<any> = this.state.notifications.slice();
    console.log('temp: ' + tempList.length + ', Length: ' + this.state.notificationLength);
    let notificationResponse: any = await notificationService.getLimitNotification(this.props.screenProps.token, this.state.notificationLength+12);
    
    if (notificationResponse[0]) {
      // notificationResponse[0].notifications.sort(function (a: any, b: any) {
      //   return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      // });

      let notifications: Array<any> = [];
      notificationResponse[0].notifications.map((item: any, key: any) =>
        notifications.push({
          title: item.title,
          content: item.message,
          date: moment(new Date(item.createdAt)).format(
            "MMMM Do YYYY, h:mm:ss a"
          ),
          unread: item.unread,
          id: item._id,
          onPress: () => this.onRead(item),
        })
      );

      this.setState({ notifications: notifications });
    }
    console.log('Noti: ' + this.state.notifications.length + ', Length: ' + this.state.notificationLength);
    if(JSON.stringify(tempList) === JSON.stringify(this.state.notifications)) {
      this.setState({reachMaxNotificationLength: true});
    } else {
      this.setState({notificationLength: this.state.notificationLength + 12});
      this.setState({reachMaxNotificationLength: false});
    }
    this.setState({refreshing: false})
  }

  onRead(notification: any) {
    //set notification as read
    notificationService.readNotification(
      this.props.screenProps.token,
      notification._id
    );

    let notifications: Array<any> = this.state.notifications;
    notifications.map((item: any, key: any) => {
      if (item.id === notification._id) {
        item.unread = false;
      }
    });

    this.setState({ notifications: notifications });
    this.props.navigation.navigate(
      notification.destination,
      notification.destinationId
    );
  }

  onRefresh(){//refresh function
    this.setState({ refreshing: true });
    this.getData();
  }

  loadMore(){
    if(!this.state.reachMaxNotificationLength){
      this.getData();
    }
  }


  render() {
    return (
      <View style={styles.notificationContainer}>
        <Header headerText={'Notification'} onPress={() => this.props.navigation.navigate('NotificationScreen')} onBack={() => this.props.navigation.goBack()} back={true} notification={false}/>
        {this.state.notifications.length === 0 && <Text style={styles.emptyMessage}>You have no notification!</Text>}
        {this.state.notifications.length > 0 && <RowList dataList={this.state.notifications} refreshing={this.state.refreshing} onRefresh={this.onRefresh} loadMore={this.loadMore}/>}
      </View>
    );
  }
}

export default NotificationScreen;
