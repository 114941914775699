import React, { Component } from "react";
import { View, ScrollView, RefreshControl } from "react-native";
import styles from "./rowList.styles";
import ConsumptionRow from "./consumptionRow";

interface CustomInputProps {
  dataList: Array<any>;
  refreshing: boolean;
  onRefresh: Function;
}

class ConsumptionRowList extends Component<CustomInputProps> {
  onRefresh() {
    if (typeof this.props.onRefresh !== "undefined") {
      this.props.onRefresh();
    }
  }

  render() {
    return (
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={this.props.refreshing}
            onRefresh={this.onRefresh.bind(this)}
          />
        }
      >
        <View style={styles.rowList}>
          {this.props.dataList.map((item, key) => (
            <ConsumptionRow
              key={key}
              title={item.title}
              content={item.content}
              date={item.date}
              unread={item.unread}
              id={item.id}
              onPress={item.onPress}
            />     
          ))}
         
        </View>
      </ScrollView>
    );
  }
}

export default ConsumptionRowList;
