/***************************************************/
// Navigation & Linking Page for Supplier
/***************************************************/

import React from 'react';
import { createAppContainer } from 'react-navigation';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';
import Icon from '../components/ionicon';
import SupplierHomeScreen from './supplierHomeScreen';
import MessageTargetListing from './messageTargetListing';
import MessageScreen from './messageScreen';
import SupplierMeScreen from './supplierAboutMeScreen';
import NotificationScreen from './notificationScreen';
import CompanyDetailScreen from './companyDetailScreenNonEditable';
import QuotingScreen from './supplierQuotingScreen';
import QuoteListing from './quoteListing';
import ProjectDevelopmentScreen from './projectDevelopmentScreen';
import ProjectDevelopmentListing from './projectDevelopmentListing';
import ProjectDevelopmentListingByCustomer from './projectDevelopmentListingByCustomer';
import ConsumptionListing from './consumptionListing';
import MessageListing from './messageListing';
import supplierSettingScreen from './supplierSettingScreen';

const MeStack = createStackNavigator({
  NotificationScreen: { screen: NotificationScreen },
  HomeScreen: { screen: SupplierHomeScreen },
  MeScreen: { screen: SupplierMeScreen },
  CompanyDetailScreen: { screen: CompanyDetailScreen },
  QuotingListing: { screen: QuoteListing },
  ConsumptionListing: { screen: ConsumptionListing }
}, {
  headerMode: 'none',
  initialRouteName: 'HomeScreen'
});

const MessageStack = createStackNavigator({
  NotificationScreen: { screen: NotificationScreen },
  MessageListing: { screen: MessageListing },
  MessageTargetListing: { screen: MessageTargetListing },
  MessageScreen: { screen: MessageScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'MessageListing'
});

const ProjectDevelopmentStack = createStackNavigator({
  NotificationScreen: { screen: NotificationScreen },
  ProjectDevelopmentListing: { screen: ProjectDevelopmentListing },
  ProjectDevelopmentListingByCustomer: { screen: ProjectDevelopmentListingByCustomer },
  ProjectDevelopmentScreen: { screen: ProjectDevelopmentScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'ProjectDevelopmentListing'
});

const MenuStack = createStackNavigator({
  supplierSettingScreen: {screen:supplierSettingScreen},
  MeScreen:{screen: SupplierMeScreen},
  CompanyDetailScreen: { screen: CompanyDetailScreen },
  QuotingListing: { screen: QuoteListing },
  ConsumptionListing: { screen: ConsumptionListing },
  ProjectDevelopmentListing: { screen: ProjectDevelopmentListing },
  ProjectDevelopmentListingByCustomer: { screen: ProjectDevelopmentListingByCustomer },
  ProjectDevelopmentScreen: { screen: ProjectDevelopmentScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'supplierSettingScreen'
});

const QuotingStack = createStackNavigator({
  QuotingScreen: { screen: QuotingScreen },
}, {
  headerMode: 'none',
});


const TabNavigator = createBottomTabNavigator({
  Home: MeStack,
  Quoting: QuotingStack,
  Messages: MessageStack,
  'Project Development': ProjectDevelopmentStack,
  // Notification: NotificationScreen,
  Menu: MenuStack,
}, {
  defaultNavigationOptions: ({ screenProps, navigation }) => ({
    tabBarIcon: ({ focused, horizontal, tintColor }) => {
      const routeName: string = navigation.state.routeName;

      let iconName: string = '';
      let badgeCount: number = 0;
      let color: string = tintColor || 'gray';
      let iconSize: number = 0;

      if (routeName === 'Home') {
        iconName = focused ? "home" : "home-outline";
        iconSize = 26;
      } else if (routeName === 'Quoting') {
        iconName = focused ? "list-sharp" : "list-outline";
        iconSize = 26;
      } else if (routeName === 'Messages') {
        iconName = focused
                ? "md-chatbox-ellipses"
                : "md-chatbox-ellipses-outline";
                iconSize = 26;
        badgeCount = screenProps.unreadCount;
      } else if (routeName === 'Project Development') {
        iconName = focused
                ? "ios-reader-sharp"
                : "ios-reader-outline";
                iconSize = 26;
      } else if (routeName === 'Notification') {
        iconName = focused ? "notifications" : "notifications-outline";
        iconSize = 26;
      }else if (routeName === 'Menu') {
        iconName = focused ? "menu" : "menu-outline";  
        iconSize = 26;   
      }

      return <Icon name={iconName} color={color} badgeCount={badgeCount} />;
    }
  }),
  tabBarOptions: {
    activeTintColor: '#0E6B35',
    inactiveTintColor: '#8fad86',
    keyboardHidesTabBar: false
  }
});

export default createAppContainer(TabNavigator);