import axios from 'axios';
import * as apis from '../configs/devApis';

export const getConsumptionAll = async (token: string) => {
  try {
    const getConsumptionResponse: any = await axios.get(apis.CONSUMPTION_API + '/consumption/all', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getConsumptionResponse.data.data;
  } catch (err) {
    console.log(`Error returned from GET consumption by id API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}