import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import styles from './row.styles';
// import Icon from './icon';
import Ionicons from "react-native-vector-icons/Ionicons";
import Fontisto from "react-native-vector-icons/Fontisto";


interface CustomInputProps {
  title: string;
  content: string;
  date: string;
  unread: number;
  id: string;
  onPress: Function;
}

class ConsumptionRow extends Component<CustomInputProps> {

 
  onPress() {
    this.props.onPress();
  }

  render() {
    return (
      <TouchableOpacity style={styles.row} onPress={this.onPress.bind(this)}>
        <View style={styles.container}>       
         <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#374957" /></View> 
          <View style={styles.rowTextContainer}>
            <Text style={styles.rowTitleText}>{this.props.title}</Text>
            <Text numberOfLines={5} ellipsizeMode='tail' style={styles.rowContentText}>{this.props.content}</Text>
            {this.props.date !== '' && <View style={styles.rowDate}>
              <View style={{paddingRight: 5, paddingTop: 3}}>
                <Fontisto name="clock" size={15} color="#374957" />
              </View>
              <Text style={styles.rowDateText}>{this.props.date}</Text>
            </View>}
          </View>
          <View style={styles.rowTitleIcon}>
            {this.props.unread > 0 && 
            <View style={styles.badge}>
              <Text style={styles.badgeText}>{this.props.unread}</Text>
            </View>}
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}


export default ConsumptionRow;