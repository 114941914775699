import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  aboutMeContainer: {
    backgroundColor: "#F2F1F1",
    flex: 1,
  },
  // item: {
  //   maxHeight: 100,
  //   flexGrow: 1,
  //   borderWidth: 0.5,
  //   borderColor: 'gray',
  //   alignSelf: 'stretch',
  //   justifyContent: 'center',
  //   paddingLeft: 10
  // },
  // itemText: {
  //   fontSize: 18,
  //   fontWeight: 'bold',
  //   paddingLeft: 20
  // }
  MainCtn: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  ctn: {
    flexDirection: "row",
    marginBottom: 20,
    width: "100%",
    height: "20%",
    justifyContent: "space-evenly",
    marginVertical: "15%",
  },
  // ctn2:{
  //   width: "100%",
  //         height: "20%",
  //         margin: 20,
  //         justifyContent: "space-around",
  //         alignItems: "center",
  // },
  button: {
    alignItems: "center",
  },
  icon: {
    alignItems: "center",
    justifyContent: "center",

    width: 110,
    height: 110,
    backgroundColor: "#F7FDF5",
    borderRadius: 30,
  },
  iconShadow: {
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
  },
  text: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: "600",
    color: "#374957",
  },

  // text:{
  //   marginTop:45,
  //   marginLeft:20,
  //   fontSize: 20,
  //   fontWeight: "600",
  //   color: "#374957",
  // },
});

export default styles;
