import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/aboutMeScreen.styles';
import Header from '../components/header';
import Icon from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class AboutMe extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
  }

  onNavigate(destination: string) {
    this.props.navigation.navigate(destination);
  }
  
  render() {
    return (
      <View style={styles.aboutMeContainer}>
        <Header headerText='About Me' onPress={() => this.props.navigation.navigate('NotificationScreen')} onBack={() => this.props.navigation.goBack()} back={true} notification={false} />
        {/* <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('CompanyDetailScreen')}>
          <Text style={styles.itemText}>{'\u2022'}     Company Detail</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.props.navigation.navigate('OrderListing', { 'showCompletedOnly': true })}>
          <Text style={styles.itemText}>{'\u2022'}     Purchase History</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.props.navigation.navigate('OrderListing', { 'showIncompleteOnly': true })}>
          <Text style={styles.itemText}>{'\u2022'}     Purchase & Goods Delivery</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('CreditLimitScreen')}>
          <Text style={styles.itemText}>{'\u2022'}     Credit Limit/Debtor Ageing</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('UploadDocumentScreen')}>
          <Text style={styles.itemText}>{'\u2022'}     Documents</Text>
        </TouchableOpacity> */}

        <View style={styles.MainCtn}>
        
            <View style={styles.ctn}>
            <TouchableOpacity
              onPress={() => this.onNavigate("CompanyDetailScreen")}
              style={styles.button}
            >
              <View style={[styles.icon, styles.iconShadow]}>
                <Icon name="md-business-outline" size={60} />
              </View>
              <Text style={[styles.text]}>Company Detail</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => this.props.navigation.navigate('OrderListing', { 'showCompletedOnly': true })}
              style={styles.button}
            >
              <View style={[styles.icon, styles.iconShadow]}>
                <FontAwesome5 name="history" size={60} />
              </View>
              <Text style={[styles.text]}>Purchase History</Text>
            </TouchableOpacity>
          </View>
          {/* <View
            style={styles.ctn2}
          >
            <TouchableOpacity
              onPress={() => this.onNavigate('UploadDocumentScreen')}
              style={styles.button}
            >
              <View style={[styles.icon, styles.iconShadow]}>
                <Icon name="documents-outline" size={60} />
              </View>
              <Text style={[styles.text]}>Documents</Text>
            </TouchableOpacity>
          </View> */}
          <View style={styles.ctn}>
            <TouchableOpacity
              onPress={() => this.props.navigation.navigate('OrderListing', { 'showIncompleteOnly': true })}
              style={styles.button}
            >
              <View style={[styles.icon, styles.iconShadow]}>
                <MaterialCommunityIcons name="truck-delivery-outline" size={60} />
              </View>
              <Text style={[styles.text]}>Purchase &{"\n"}Goods Delivery</Text>
              
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => this.onNavigate('CreditLimitScreen')}
              style={styles.button}
            >
              <View style={[styles.icon, styles.iconShadow]}>
                <MaterialCommunityIcons
                  name="credit-card-clock-outline"
                  size={60}
                />
              </View>
              <Text style={[styles.text]}>Credit Limit/{"\n"}Debtor Ageing</Text>
            </TouchableOpacity>
          </View>
          {/* 
          Another UI
          <TouchableOpacity style={[ styles.button ]}>
            <View style={[styles.icon, styles.iconShadow]}>
              <Icon name="md-business-outline" size={60} />
            </View>
            <Text style={[styles.text]}>Company Detail</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[ styles.button ]}>
            <View style={[styles.icon, styles.iconShadow]}>
              <FontAwesome5 name="history" size={60} />
              
            </View>
            <Text style={[styles.text]}>Purchase History</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[ styles.button ]}>
            <View style={[styles.icon, styles.iconShadow]}>
              <Icon name="documents-outline" size={60} />
            </View>
            <Text style={[styles.text]}>Documents</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[ styles.button ]}>
            <View style={[styles.icon, styles.iconShadow]}>
              <MaterialCommunityIcons name="truck-delivery-outline" size={60} />
            </View>
            <Text style={[styles.text]}>Purchase & Goods Delivery</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[ styles.button ]}>
            <View style={[styles.icon, styles.iconShadow]}>
              <MaterialCommunityIcons name="credit-card-clock-outline" size={60} />
            </View>
            <Text style={[styles.text]}>Credit Limit/Debtor Ageing</Text>
          </TouchableOpacity> */}
        </View>
      </View>

   
    )
  }
}

export default AboutMe;