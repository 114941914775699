import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import moment from 'moment';
import styles from '../styles/quoteListing.styles';
import Header from '../components/header';
import RowList from '../components/rowList';
import Loader from '../components/loader';
import * as SupplierService from '../services/supplierService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  quoteList: Array<any>;
  isLoading: boolean;
  isRefreshing: boolean;
}

class QuoteListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      quoteList: [],
      isLoading: true,
      isRefreshing: false
    }
    
    this.onClickQuoting = this.onClickQuoting.bind(this);
  }

  async componentDidMount() {
    let quoteListingResponse: any = {};
  
    this.setState({isLoading:true})
    if (this.props.screenProps.role === 'management') {
      quoteListingResponse = await SupplierService.getQuotingByStatus(this.props.screenProps.token, 'approved');
    } else {
      quoteListingResponse = await SupplierService.getQuotingBySupplier(this.props.screenProps.token, this.props.screenProps.companyId);
    }

    this.setState({isLoading: false});
    
    if (quoteListingResponse) {
      quoteListingResponse.sort(function (a: any, b: any) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });

      let quoteList: Array<any> = [];
      // let getCompanyResponse: any;
      for (const item of quoteListingResponse) {
        // getCompanyResponse = await CompanyService.getCompany(this.props.screenProps.token, item.supplier);        
        // removed to improve performance
        
        quoteList.push({
          "title": item.brand + " " + item.product + "\r\nSupplier: " + item.supplier,
          "content": "Price: " + item.price + "\r\nStatus: " + item.status,
          "date": moment(new Date(item.date)).format('MMMM Do YYYY, h:mm:ss a'),
          "unread": false,
          "id": item._id,
          "status":item.status,
          "onPress": () => this.onClickQuoting(item._id)
          
        });
      }

      this.setState({ quoteList: quoteList});
    }
  }
  
  onClickQuoting(quotingId: string) {
    if (this.props.screenProps.role === 'management') {
      this.props.navigation.navigate('QuotingScreen', { quotingId: quotingId })
    } else {
      this.props.navigation.navigate('QuotingScreen', { quotingId: quotingId })
    }
  }

  onRefreshing =() => {
    this.setState({isRefreshing: true});
    this.componentDidMount();
    setTimeout(() =>{
      this.setState({isRefreshing: false});
    }, 2000)
  }

  render() {
    let header;
    if (this.props.screenProps.role === 'supplier') {
      header = <Header headerText='Quote List' onBack={() => this.props.navigation.goBack()} back={true} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti}/>
    } else {
      header = <Header headerText='Quote List' onBack={() => this.props.navigation.goBack()} back={false} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti}/>
    }
    return (
      <View style={styles.quoteContainer}>
        {/* <NavigationEvents onDidFocus={() => this.componentDidMount()} /> */}
        {header}
        {this.state.quoteList.length === 0 && <Text style={styles.emptyMessage}>You have no quoting!</Text>}
        {this.state.quoteList.length > 0 && <RowList dataList={this.state.quoteList} refreshing={this.state.isRefreshing} onRefresh={this.onRefreshing}/>}
        <Loader isLoading={this.state.isLoading} />
        
      </View>
    );
  }
}

export default QuoteListing;
