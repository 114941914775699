import React from 'react';
import App from './app/app';
import { LogBox } from "react-native";



const Main = () => {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);
  return (
    <App />
  );
}

export default Main;
