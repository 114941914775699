import { Dimensions, Platform, StyleSheet } from 'react-native';

const screenWidth = Dimensions.get('screen').width;
const screenHeight = Dimensions.get('screen').height;

const styles = StyleSheet.create({
  feedContainer: {
    flex: 1,
    alignItems: "center",
  },
  deleteButton: {
    position:"absolute",
    borderRadius: Platform.OS === 'ios' ? 20 : 17,
    paddingHorizontal: 15,
    paddingVertical: Platform.OS === 'ios' ? 15 : 10,
    elevation: 2,
    alignSelf: "center",
    backgroundColor: "#e30202",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },
  feedText:{
    fontSize: 22,
    fontWeight: "500",
    paddingBottom: "3%"
  },
  feedBold:{
    fontWeight:"600"
  },
  feedCaption:{
    flex: 1,
    padding: 10,
    width: "100%",
    opacity: 1,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    backgroundColor: "#F9F9F9",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "absolute",
  },
  modalFeedCaption: {
    padding: 10,
    width: "100%",
    opacity: 0.75,
    marginTop:"109%",
    backgroundColor: "#F9F9F9",
    justifyContent:"flex-end",
    position:"absolute"
  },
  cardContainter:{
    height: 250,
    width: "90%",
    margin: "3%",
    justifyContent: "flex-end",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    elevation: 5
  },
  modalCardContainer:{
    height: "94%",
    width: "100%",
    justifyContent: "flex-end",
    position:"absolute",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    width: screenWidth - 10,
    height: screenHeight * 8.5/10,
    margin: 20,
    padding:5,
    backgroundColor: "#F2F1F1",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    elevation: 3
  },
  closeButton: {
    borderRadius: 20,
    padding: 10,
    marginRight: 10,
    elevation: 0,
    alignSelf: "flex-end"
  },
  deleteView: {
    justifyContent: "flex-end",
    marginTop: "10%",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },
  btn: {
    position: 'absolute',
    justifyContent: "center",
    backgroundColor: "#0E6B35", 
    marginLeft: "77%",
    marginTop: Platform.OS === 'ios' ? "177%": "167%",
    maxHeight: 60,
    maxWidth: 60,
    borderRadius: 70,
    alignItems: "center",
    
  },
  shadow: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 5,
  },
});

export default styles;
