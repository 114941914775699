import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

const screenWidth = Dimensions.get('screen').width;
const screenHeight = Dimensions.get('screen').height;

const styles = StyleSheet.create({
  loader: {
    width: screenWidth/4,
    height: screenHeight/4,
  }
});

export default styles;