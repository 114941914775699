import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/managementOrderListing.styles';
import Header from '../components/header';
import NameList from '../components/nameList';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  customerListing: Array<any>;
}

class CustomerListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      customerListing: []
    }

    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() { //Retrieve all customer from database
    let companyListingResponse: any = await CompanyService.getCompanyAll(this.props.screenProps.token);

    if (companyListingResponse) {
      let header = '';
      let tempList: any = [];
      
      companyListingResponse = companyListingResponse.filter(function (item: any) {
        return item.type === 'customer';
      });
      
      if (this.props.screenProps.department !== 'all' && this.props.screenProps.department !== 'super') {
        let departmentToFilter: string = this.props.screenProps.department;
        
        companyListingResponse = companyListingResponse.filter(function (item: any) {
          return item.department === 'all' || item.department === departmentToFilter;
        });
      }
        
      let customerListing: Array<any> = [];
      companyListingResponse.sort((a, b) => {  //sort function 
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      companyListingResponse.map((item: any, key: any) => { //For Section Listing
        if (header === '') {
          header = item.name.substring(0, 1).toUpperCase()
        }
        if (header !== item.name.substring(0, 1).toUpperCase()) {
          customerListing.push({
            'title': header,
            'data': tempList
          });
          header = item.name.substring(0, 1).toUpperCase()
          tempList = []
        }
        tempList.push({
          'title': item.name,
          'content': '',
          'buttonText': 'SELECT',
          'buttonOnPress': () => this.onSelect(item.name)
        })
        if(key === companyListingResponse.length-1){
          customerListing.push({
            'title': header,
            'data': tempList
          });
        }
      });

      this.setState({ customerListing: customerListing });
    }
  }

  onSelect(orderBy: string) { //navigation function
    this.props.navigation.navigate('OrderListing', {customerEmail: orderBy});
  }

  render() {
    return (
      <View style={styles.customerContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Order Listing' onPress={() => this.props.navigation.navigate('NotificationScreen')} onBack={() => this.props.navigation.goBack()} back={false} notification={true} badgeCount={this.props.screenProps.unreadNoti} />
        {this.state.customerListing.length === 0 && <Text style={styles.emptyMessage}>Your customer have not order yet!</Text>}
        {this.state.customerListing.length > 0 && <NameList dataList={this.state.customerListing} />}
      </View>
    );
  }
}

export default CustomerListing;