import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  consumptionContainer: {
    flex: 1
  },
  emptyMessage: {
    alignSelf: 'center',
    justifyContent: 'center'
  }, 
  searchBar: {
    backgroundColor: 'white'
  },
  boxStyle: {
    flexDirection: "row",
    backgroundColor: 'white',
    width: "100%",
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
    alignSelf: 'center',
  },
  dropdownStyle: {
    backgroundColor: "#F7FDF5",
    marginLeft: 20,
    paddingHorizontal: 134,
    shadowColor: "#171717",
    shadowOpacity: 0,
    shadowRadius: 4,
    elevation: 2,
    borderColor: "#C3DEBB",
  },
});

export default styles;