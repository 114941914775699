import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import styles from "./product.styles";
import Icon from "./icon";

//Content card

interface CustomInputProps {
  title: string;
  content: string;
  action: string;
  unread: boolean;
  onPress: Function;
  active: number;
}

class Product extends Component<CustomInputProps> {
  onPress() {
    this.props.onPress();
    console.log("press", this.props.active);
  }
  render() {
    return (
      <TouchableOpacity
        style={[this.props.active === 1 ? styles.cardActive : styles.card]}
        onPress={this.onPress.bind(this)}
      >
        {this.props.unread && (
          <Icon name={"notification"} color={"black"} badgeCount={1} />
        )}
        <Text style={styles.cardTitleText}>{this.props.title}</Text>

        {this.props.content !== "" && (
          <View style={styles.cardContent}>
            <Text
              numberOfLines={2}
              ellipsizeMode="tail"
              style={styles.cardContentText}
            >
              {this.props.content}
            </Text>
          </View>
        )}
      </TouchableOpacity>
    );
  }
}

export default Product;
