/***************************************************/
//Currently Not In Used
/***************************************************/

import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/customerOrderListing.styles';
import Header from '../components/header';
import CardList from '../components/cardList';
import * as orderService from '../services/orderService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  customerListing: Array<any>;
}

class CustomerListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      customerListing: []
    }

    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() {
    const orderListingResponse: any = await orderService.getOrder(this.props.screenProps.token);

    if (orderListingResponse) {
      //orderListingResponse.map((item: any) => item.orderBy).filter((value: any, index: any, self: any) => self.indexOf(value) === index);
      let customerList: Array<any> = Array.from(new Set(orderListingResponse.map((item: any) => item.orderBy)));
  
      let customerListing: Array<any> = [];
      customerList.map((item: any, key: any) => (
        customerListing.push({
          'title': item,
          'content': '',
          'buttonText': 'SELECT',
          'buttonOnPress': () => this.onSelect(item)
        })
      ));

      this.setState({ customerListing: customerListing });
    }
  }

  onSelect(orderBy: string) {
    this.props.navigation.navigate('OrderListing', {customerEmail: orderBy});
  }

  render() {
    return (
      <View style={styles.customerContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='History' onBack={() => this.props.navigation.goBack()} back={false} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti} />
        {this.state.customerListing.length === 0 && <Text style={styles.emptyMessage}>Your customer have not order yet!</Text>}
        {this.state.customerListing.length > 0 && <CardList dataList={this.state.customerListing} />}
      </View>
    );
  }
}

export default CustomerListing;