import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
  homeContainer: {
    flex: 1
  },
  item: {
    maxHeight: 80,
    // backgroundColor: '#F2F1F1',
    flexGrow: 1,
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderColor: 'gray',
    borderBottomWidth: 1,
    paddingLeft: 20,
  },
  itemText: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingLeft: 15,
  }
});

export default styles;