import React, { Component } from 'react';
import { View, Text, KeyboardAvoidingView, Platform, TextInput } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { Button } from 'react-native-elements'
import styles from './loginForm.styles';
import { ScrollView } from 'react-native-gesture-handler';
import RNPickerSelect from 'react-native-picker-select';
import Btn from "./Btn";
import { darkGreen } from "./Constants";

interface CustomInputProps {
    formInput: Array<any>;
    haveError: boolean;
    errorMessage: string;
    buttonText: string;
    buttonOnPress: Function;
    buttonText2: string;
    buttonOnPress2: Function;
}

class Form extends Component<CustomInputProps> {
    static defaultProps = {
        buttonText2: '',
        buttonOnPress2: () => { }
    }

    constructor(props: CustomInputProps) {
        super(props);

        this.onPress = this.onPress.bind(this);
        this.onPress2 = this.onPress2.bind(this);
    }

    onPress() {
        this.props.buttonOnPress();
    }

    onPress2() {
        this.props.buttonOnPress2();
    }

    render() {
        return (
            <KeyboardAvoidingView style={styles.formContainer} enabled behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
                <ScrollView contentContainerStyle={styles.scrollViewContainer}>
                    <View style={styles.form}>
                        {this.props.formInput.map(function (item: any, key: number) {
                            if (item.inputType === 'picker') {
                                if (Platform.OS === 'ios' || Platform.OS === 'macos') {
                                    if (item.value === '') {
                                        return <View style={styles.pickerContainer} key={key}>
                                            <Text style={styles.labelText}>{item.placeholder}</Text>
                                            <RNPickerSelect
                                                onValueChange={item.onChangeText}
                                                value={item.value}
                                                items={item.option.map((item: any, key: number) => { return ({ label: item, value: item }) })}
                                            />
                                        </View>;
                                    } else {
                                        return <View style={styles.pickerContainer} key={key}>
                                            <Text style={styles.labelText}>{item.placeholder}</Text>
                                            <RNPickerSelect
                                                onValueChange={item.onChangeText}
                                                items={item.option.map((item: any, key: number) => { return ({ label: item, value: item }) })}
                                            />
                                        </View>;
                                    }
                                } else {
                                    return <View style={styles.pickerContainer} key={key}>
                                        <Text style={styles.labelText}>{item.placeholder}</Text>
                                        <Picker selectedValue={item.value} style={styles.picker} onValueChange={item.onChangeText}>
                                            <Picker.Item label='' value='' />
                                            {item.option.map((item: any, key: number) => <Picker.Item label={item} value={item} key={key} />)}
                                        </Picker>
                                    </View>;
                                }
                            } else {
                                return (
                                        <TextInput
                                        key={key}
                                        style={styles.inputContainer}
                                        placeholder={item.placeholder}
                                        placeholderTextColor={darkGreen}
                                        onChangeText={item.onChangeText || function () { }}
                                        secureTextEntry={item.secureTextEntry || false}
                                        keyboardType={item.keyboardType || 'default'}
                                        value={item.value} />
                                        );
                            }
                        })}
                        {this.props.haveError && <Text style={styles.errorMessage}>{this.props.errorMessage}</Text>}
                        <View style={{ alignItems: "center" }}><Btn
                            bgColor="#0E6B35"
                            textColor="white"
                            btnLabel={this.props.buttonText}
                            press={this.onPress} />
                        </View>
                        {this.props.buttonText2 != '' && this.props.buttonText2 !== undefined && <View style={styles.button}><Button title={this.props.buttonText2} onPress={this.onPress2} /></View>}
                    </View>
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}

export default Form;