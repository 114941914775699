import React, { Component } from 'react';
import { View, KeyboardAvoidingView, Text, Image, Platform } from "react-native";
import Background from "../components/Background";
import { ScrollView } from 'react-native-gesture-handler';
import styles1 from "../styles/login.styles";
import styles from '../styles/resetPasswordScreen.styles';
import Form from '../components/loginForm';
import Loader from '../components/loader';
import BackBar from '../components/backBar';
import * as userService from '../services/userService';

interface CustomInputProps {
  backToLogin: Function;
}

interface CustomInputStates {
  formInput: Array<any>;
  username: string;
  password: string;
  confirm: string;
  haveError: boolean;
  errorMessage: string;
  isLoading: boolean;
}

class ResetPasswordScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      formInput: [],
      username: '',
      password: '',
      confirm: '',
      haveError: false,
      errorMessage: '',
      isLoading: false
    }

    this.generateForm = this.generateForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
  }

  componentDidMount() {
    this.generateForm();
  }

  generateForm() {
    let formInput: Array<any> = [
      { placeholder: 'Username', onChangeText: (value) => this.onChange('username', value), value: this.state.username },
      { placeholder: 'New Password', onChangeText: (value) => this.onChange('password', value), secureTextEntry: true, value: this.state.password },
      { placeholder: 'Confirm Password', onChangeText: (value) => this.onChange('confirm', value), secureTextEntry: true, value: this.state.confirm }
    ];

    this.setState({ formInput: formInput });
  }

  onChange(name: string, input: string) {
    this.setState({ [name]: input }, () => this.generateForm());
  }
  
  backToLogin() {
    this.props.backToLogin();
  }

  async onResetPassword() {
    this.setState({ isLoading: true });
    if ((this.state.username && this.state.password && this.state.confirm) !== ''){
      if(this.state.password === this.state.confirm){
    const resetResponse: any = await userService.resetPassword(this.state.username, this.state.password);

    if (resetResponse.success) {
      this.setState({ haveError: true });
      this.setState({ errorMessage: 'You will received an email shortly to verify the reset password request.' });
    } else {
      this.setState({ haveError: true });
      this.setState({ errorMessage: 'Username does not exists.' });
    }
    }else{
      this.setState({ haveError: true });
      this.setState({ errorMessage: 'Password entered not same!' });
    }
    }else{
      this.setState({ haveError: true });
      this.setState({ errorMessage: 'Please do not leave blank!' });
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <KeyboardAvoidingView style={{ flex: 1 }} enabled behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
        <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: "flex-start" }}>
          <Background>
            <View style={{ marginVertical: Platform.OS === 'ios' ? "20%" : "16%", zIndex: 2 }}>
              <View style={styles1.logoContainer}>
                <Image style={styles.logo} source={require("../../assets/logo_ep.png")} />
                <Text style={styles1.companyName}>
                  Euro Potential
                </Text>
              </View>
              <View style={[styles1.whiteContainer, styles1.shadowProp]}>
              <View style={styles.resetContainer}>
                <View style={{flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                <BackBar onPress={this.backToLogin} />
                    <Text style={[styles1.resetText, {
                      alignSelf: 'center', 
                      flexGrow: 0,
                      flexShrink: 1,
                      flexBasis: 210,
                    }]}>
                    Reset Password
                  </Text>
                </View>
                <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Reset Password'} buttonOnPress={this.onResetPassword} />
              </View>
              </View>
            </View>
          </Background>
        </ScrollView>
        <Loader isLoading={this.state.isLoading} />
      </KeyboardAvoidingView>
    );
  }
}

export default ResetPasswordScreen;