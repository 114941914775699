import axios from 'axios';
import * as devApis from '../configs/devApis';

export const getAllFeed = async (token: string, limit: number) => {
  try {
    const getFeedResponse: any = await axios.get(devApis.FEED_API + '/feed/all/' + limit, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getFeedResponse.data.data;
  } catch (err) {
    console.log(`Error returned from GET feed/all API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postFeed = async (token: string, feedDetails: any) => {
  // try {
    // const postLog = async() => await axios.post(devApis.FEED_API + '/feed', feedDetails, { onUploadProgress: ({progress}) => {
    //   console.log((progress * 100).toFixed(2));
    // }, headers: { Accept: 'application/json', "Content-Type": 'multipart/form-data','authToken': token }, timeout: 60 * 4 * 1000, maxRedirects: 0});
    // modified the post Feed service
    new Promise(function (resolve, reject) {
    let request = new XMLHttpRequest();
    request.open('POST', devApis.FEED_API + '/feed', true);
    request.setRequestHeader('Content-Type',   'multipart/form-data' );
    request.setRequestHeader('Authorization', token);
    request.upload.onprogress = function(e) {
      if (e.lengthComputable) {
        var percentComplete = (e.loaded / e.total) * 100;
        console.log(percentComplete + '% uploaded');
      }
    };

    // set up call back from request
    request.onload = () =>{
      const response = JSON.stringify(request.response);
      console.log(response);
      alert('Upload Complete');
    };
    request.onerror = e => {
      console.log('upload failed', e);
      alert('Fail to upload Please try again');
    };
    
    request.send(feedDetails);
  });
    // return postLog().then(function(response) {
    //   console.log(response);
    // }).catch(error => console.log(error));
  //   new Promise(function (resolve, reject) {
  //   var xhr = new XMLHttpRequest();
  //   xhr.open('POST', devApis.FEED_API + '/feed');
  //   xhr.setRequestHeader('Content-Type',   'multipart/form-data' );
  //   xhr.setRequestHeader('Authorization', token);
  //   console.log('OPENED', xhr.status);
  //   xhr.onprogress = function () {
  //     console.log('LOADING', xhr.status);
  //   }; 
  //   xhr.upload.onprogress = function(e) {
  //     if (e.lengthComputable) {
  //       var percentComplete = (e.loaded / e.total) * 100;
  //       console.log(percentComplete + '% uploaded');
  //     }
  //   };
  //   xhr.onload = function () {
  //     console.log('DONE', xhr.status);
  //     var status = xhr.status;
  //       if (status == 200) {
  //           resolve(xhr.response);
  //       } else {
  //           reject(status);
  //       }
  //   };
  //   xhr.onerror = e => {
  //     console.log('upload failed', e);
  //     alert('Fail to upload Please try again');
  //   };
    

  //   xhr.send(feedDetails);
  // });
  // } catch (err) {
  //   console.log(`Error returned from POST feed API. Message: ${err.message}. Response: ${JSON.stringify(err)}`);
  //   return false;
  // }
}

export const deleteFeedAutoByDate = async (token: string) => {
  try {
    await axios.delete(devApis.FEED_API + '/feed', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000});
    return true;
  } catch (err) {
    console.log(`Error returned from DELETE date by feed API. Message: ${err.message}. Response: ${JSON.stringify(err.response.data)}`);
    return false;
  }
}

export const deleteFeed = async (token: string, feedId: string) => {
  try {
    await axios.delete(devApis.FEED_API + '/feed/feedId/' + feedId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from DELETE feed API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}
