import React, { Component } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity } from "react-native";

 const Category = (props) => {
    return (
      <TouchableOpacity
        style={{
          height: 200,
          width: 200,
          marginLeft: 20,
          marginRight: 20,
          justifyContent: "flex-end"
        }}
      >
        <View style={{ flex: 2}}>
          <Image
            source={props.imageUri}
            style={{
              width: 200,
              height: 200,
              borderRadius: 20,
            }}
          />
        </View>
        <View
          style={{
            flex: 1,
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            width: 200,
            opacity: 0.75,
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            backgroundColor: "#C4BDB5",
            alignItem: "center",
            position: "absolute",
          }}
        >
          {/* <Text style={{ fontSize: "20", fontWeight: "500" }}> */}
          <Text>
            {props.name}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }


export default Category;





