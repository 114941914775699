import { StyleSheet,Platform } from 'react-native';

const styles = StyleSheet.create({
  row: {
    backgroundColor: 'white',
    flexGrow: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#EBE9E9'
  },
  container: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 10,
    flex: 1,
    flexDirection: 'row'
  },
  rowIcon: {
    paddingLeft: 5,
    width: 130,
    flex: 1,
    flexDirection: 'row'
  },
  rowTextContainer: {
    flex: 5
  },
  rowTitleText: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingBottom: 5
  },
  rowTitleIcon: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20
  },
  rowContentText: {
    fontSize: 14
  },
  rowDate: {
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
  },
  rowDateText: {
    fontSize: 12,
    color: 'gray',
    paddingTop: Platform.OS === 'ios' ? 5 : 2
  },
  badge: {
    width: 12,
    height: 12,
    backgroundColor: 'red',
    borderRadius: 6,
    right: 15,
    top: 15,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    color: 'white',
    fontSize: 10,
    fontWeight: 'bold'
  }
});

export default styles;