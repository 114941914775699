import { Dimensions, StyleSheet } from 'react-native';

const screenWidth = Dimensions.get('screen').width;
const screenHeight = Dimensions.get('screen').height;

const styles = StyleSheet.create({
  messageContainer: {
    flex: 1
  },
  chatContainer: {
    flex: 1,
  },
  customInputContainer: {
    borderTopWidth: 1,
    borderTopColor: 'lightgray',
    flexDirection: 'row'
  },
  customInputText: {
    flex: 3
  },
  customInputTextContainer: {
    borderBottomWidth: 0
  },
  customInputSend: {
    flex: 1
  },
  customInputSendButton: {
    backgroundColor: 'transparent'
  },
  customInputSendButtonText: {
    color: '#ECF5ED',
  }
});

export default styles;