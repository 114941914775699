import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import styles from './row.styles';
// import Icon from './icon';
import Ionicons from "react-native-vector-icons/Ionicons";
import Fontisto from "react-native-vector-icons/Fontisto";
import IconBadge from './iconBadge';

interface CustomInputProps {
  title: string;
  content: string;
  date: string;
  unread: number;
  id: string;
  status:string;
  onPress: Function;
  type: string;
}

class Row extends Component<CustomInputProps> {

 
  onPress() {
    this.props.onPress();
  }

  render() {
    return (
      <TouchableOpacity style={styles.row} onPress={this.onPress.bind(this)}>
        <View style={styles.container}>
          {/*Customer*/}
          {/*Design 1*/}
          {/* {(this.props.status === "order confirmed" || this.props.title === "Order Confirmed") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#CCCC26" /></View>}
          {(this.props.status === "pending quotation" || this.props.title === "Pending Quotation") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#CCCC26" /></View>}
          {(this.props.status === "quotation approved" || this.props.title === "Quotation Approved" || this.props.title === "Approval Needed") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#CCCC26" /></View>}
          {(this.props.status === "quotation accepted" || this.props.title === "Quotation Accepted") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="green" /></View>}
          {(this.props.status === "pending approval" || this.props.title === "Pending Approval") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#CCCC26" /></View>}
          {(this.props.status === "requotation requested" || this.props.title === "Requotation Requested") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="red" /></View>}
          {(this.props.status === "order completed" || this.props.title === "Order Completed") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="green" /></View>} 
          {(this.props.status === "pending delivery" || this.props.title === "Pending Delivery") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#CCCC26" /></View>}  */}
          
          {/*Design 2, since I want to make it consistent with both web and mobile so I just follow the web choosing the color.*/}
          {(this.props.status === "order confirmed" || this.props.title === "Order Confirmed"  )&& <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="purple" /></View>}
          {(this.props.status === "pending quotation" || this.props.title === "Pending Quotation" )&& <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#CCCC26" /></View>}
          {(this.props.status === "quotation approved" || this.props.title === "Quotation Approved" || this.props.title === "Approval Needed" ) && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#CCCC26" /></View>}
          {(this.props.status === "quotation accepted" || this.props.title === "Quotation Accepted") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#00AFF0" /></View>}
          {(this.props.status === "pending approval" || this.props.title === "Pending Approval") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#00AFF0" /></View>}
          {(this.props.status === "requotation requested" || this.props.title === "Requotation Requested") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="red" /></View>}
          {(this.props.status === "order completed" || this.props.title === "Order Completed") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="green" /></View>} 
          {(this.props.status === "pending delivery" || this.props.title === "Pending Delivery") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="grey" /></View>} 
          {/* {console.log(this.props.status)} */}

          {/*notification*/}
          {this.props.title === "New Message" && <View style={styles.rowIcon}><Ionicons name="chatbox-ellipses-outline" size={30} color="#374957" /></View> }
          {(this.props.title === "Quotation Request" || this.props.title === "New Quoting from Supplier" || this.props.title === "Purchase Order Received By Sales" || this.props.title === "New Enquiry" || this.props.title === "New request from Euro Potential" || this.props.title === "Quotation Approval Needed" || this.props.title === "Verification Needed for New Quoting" || this.props.title === "Approval Needed for New Quoting" ) && <View style={styles.rowIcon}><Ionicons name="receipt-outline"  size={30} color="#CCCC26" /></View> }
         
        
          {/*Management and Supplier*/}
          {this.props.status === "approved"  && <View style={styles.rowIcon}><Ionicons name="md-pricetags-outline" size={30} color="green" /></View>}
          {(this.props.status === "pending manager approval" || this.props.status === "pending management approval" ) && <View style={styles.rowIcon}><Ionicons name="md-pricetags-outline" size={30} color="#CCCC26" /></View>}
          {(this.props.status === "pending approval tier manager" || this.props.status === "pending approval tier management") && <View style={styles.rowIcon}><Ionicons name="receipt-outline" size={30} color="#CCCC26" /></View>}
          

          <View style={styles.rowTextContainer}>
            <Text style={styles.rowTitleText}>{this.props.title}</Text>
            <Text numberOfLines={5} ellipsizeMode='tail' style={styles.rowContentText}>{this.props.content}</Text>
            {this.props.date !== '' && <View style={styles.rowDate}>
              <View style={{paddingRight: 5, paddingTop: 3}}>
                <Fontisto name="clock" size={15} color="#374957" />
              </View>
              <Text style={styles.rowDateText}>{this.props.date}</Text>
            </View>}
          </View>
          <View style={styles.rowTitleIcon}>
            {this.props.unread > 0 && 
            <View style={styles.badge}>
              <Text style={styles.badgeText}>{this.props.unread}</Text>
            </View>}
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}


export default Row;