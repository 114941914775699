import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  row:{
    flexGrow: 1,
    borderBottomWidth: 2,
    borderBottomColor: '#ffffff'
  },
  container1: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 10,
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#E2EFDD'
  },
  container2:{
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 10,
    flex: 1,
    flexDirection: 'row', 
    backgroundColor: '#ECF5ED'
  },
  rowIcon: {
    width: 130,
    flex: 1,
    flexDirection: 'row'
  },
  rowTextContainer: {
    flex: 5
  },
  rowTitleText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#16773C'
  },
  rowTitleIcon: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 8
  },
 
  rowContentText: {
    fontSize: 14,
    
  },
  rowDate: {
    paddingTop: 15,
    flexDirection: 'row'
  },
  rowDateText: {
    fontSize: 12,
    color: '#16773C',
    paddingTop: 5
  }
});

export default styles;