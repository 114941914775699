import React, { Component } from 'react';
import { RefreshControl, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/projectDevelopmentListingByCustomer.styles';
import Header from '../components/header';
import CardList from '../components/cardList';
import * as ProjectService from '../services/projectService';
import Icon from "react-native-vector-icons/Ionicons";

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  projectListing: Array<any>;
  isRefreshing: boolean;
}

class ProjectDevelopmentListingByCustomer extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);
    
    this.state = {
      projectListing: [],
      isRefreshing: false,
    }

    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() {
    let customerListingResponse: any = await ProjectService.getProjectDevelopmentByCustomer(this.props.screenProps.token, this.props.navigation.getParam('customerId', ''));
    
    let projectListing: Array<any> = [];
   
    if (customerListingResponse) {
      if (this.props.screenProps.role === 'supplier') {
        let companyId: string = this.props.screenProps.companyId;
        customerListingResponse = customerListingResponse.filter(function (item: any) {
          return item.supplierId === companyId;
        });
      }
      
      customerListingResponse.map((item: any, key: any) => (
        projectListing.push({
          'title': item.name,
          'content': '',
          'buttonText': 'SELECT',
          'buttonOnPress': () => this.onSelect(item._id)
        })
      ));
    }
    
    this.setState({ projectListing: projectListing });
  }

  onSelect(projectId: string) {
    this.props.navigation.navigate('ProjectDevelopmentScreen', { projectId: projectId, companyId: this.props.navigation.getParam('customerId', '') });
  }

  onRefreshing = () => {

    this.setState({ isRefreshing: true });
    this.componentDidMount();

    setTimeout(() => {
      this.setState({ isRefreshing: false });
    }, 4000)

  }

  render() {
    return (
      <View style={styles.listingContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Project' onBack={() => this.props.navigation.goBack()} back={true} notification={false} onPress={() => this.props.navigation.navigate('NotificationScreen')} />
        <ScrollView refreshControl={<RefreshControl refreshing={this.state.isRefreshing} onRefresh={() => this.onRefreshing()} />}>
          <CardList dataList={this.state.projectListing} />
          <View style={{paddingVertical: 50}}></View>
        </ScrollView>
        <View>
          {this.props.screenProps.role !== 'supplier' && <TouchableOpacity style={[styles.AddBtn, styles.shadow]} onPress={() => this.onSelect('')}>
            <Icon name="md-pencil-outline" size={20}/>
            <Text>Add New Project</Text>
          </TouchableOpacity>}
        </View>
      </View>
    );
  }
}

export default ProjectDevelopmentListingByCustomer;