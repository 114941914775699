import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  aboutMeContainer: {
    flex: 1,
    
  },
  box:{backgroundColor: "white", flex: 1, paddingLeft: "10%",margin:30,borderRadius:20,marginVertical:"10%"},
  title1: {
    color: "#16773C",
    fontSize: 25,
    fontWeight: "700",
    marginTop: "30%",
    
  },
  title2: {
    color: "#16773C",
    fontSize: 25,
    fontWeight: "700",
    marginTop: "20%",
    
  },
  ctn: {
    flexDirection: "row",
    marginTop: "0%",
    backgroundColor: "#ECfff1",
    height: "12%",
    width: "85%",
    borderRadius: 8,
  },
  text: {
    marginLeft: 20,
    margin: "3%",
    backgroundColor: "white",
    width: "60%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
  },
  shadow: {
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
  },
  textInside: {
    fontSize: 20,
    fontWeight: "400",
  },
  icon:{
    margin: 10, width: "20%", alignItems: "center",justifyContent:"center"
  },
});

export default styles;
