/***************************************************/
//Hardcode Version
/***************************************************/

import React, { Component } from "react";
import { View, TouchableOpacity, ScrollView } from "react-native";
import { Text } from "react-native-elements";
import {
  NavigationStackProp,
  NavigationStackScreenProps,
} from "react-navigation-stack";
import styles from "../styles/mouldingGuideScreen.styles";
import Header from "../components/header";
import Entypo from "react-native-vector-icons/Entypo";
import Ionicons from "react-native-vector-icons/Ionicons";
import Fontisto from "react-native-vector-icons/Fontisto";

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  mouldingList: Array<string>;
  selected: string;
}

class MouldingGuideScreen extends Component<
  CustomInputProps,
  CustomInputStates
> {
  constructor(props: CustomInputProps) {
    super(props);

    let mouldingList: Array<string> = [
      "PP - Polypropylene",
      "PPA - Polyphthalamide",
      "PPE - Polyphenylene Oxide / Polyphenylene Ether",
      "PPS - Polyphenylene Sulfide",
      "PPSU - Polyphenylsulfone",
      "PS - Polystyrene",
      "PTFE - Polytetrafluoroethylene",
      "PVC - Polyvinyl Chloride",
      "SAN - Styrene Acrylonitrile",
      "ASA - Acrylonitrile Styrene Acrylate",
      "TPE-A - Polyamide based Thermoplastic Elastomer",
      "TPE-E - Thermoplastic Copolyester Elastomer",
      "TPE-O - Olefin based Thermoplastic Elastomer",
      "TPE-S - Styrene based Thermoplastic Elastomer",
      "TPE-U - Thermoplastic Polyurethane",
      "TPE-V - Vulcanizated Thermoplastic Elastomer",
      "ABS - Acrylonitrile Butadiene Styrene",
      "LCP - Liquid Crystal Polymer",
      "Nylon 6 - Polyamide 6",
      "Nylon 6,6 - Polyamide 6,6",
      "PBT - Polybutylene Terephtalate",
      "PC - Polycarbonate",
      "UHMWPE - Ultra High Molecular Weight Polyethylene",
      "HDPE - High Density Polyethylene",
      "LLDPE - Linear Low Density Polyethylene",
      "LDPE - Low Density Polyethylene",
      "PEX - Cross-linked Polyethylene",
      "PEEK - Polyether Ether Ketone",
      "POK - Polyketone",
      "PET - Polyethylene Terephthalate",
      "PMMA - Polymethyl Methacrylate",
      "POM - Polyoxymethylene",
    ];

    mouldingList.sort();
    mouldingList.push("Citation");

    this.state = {
      mouldingList: mouldingList,
      selected: "",
    };
  }

  displayContent(input: string) {
    this.setState({ selected: input.value });
  }

  render() {
    return (
      <View style={styles.mouldingContainer}>
        <Header
          headerText="Moulding Guide"
          onPress={() => this.props.navigation.navigate("NotificationScreen")}
          onBack={() => this.props.navigation.goBack()}
          back={true}
          notification={true}
        />
        {this.state.selected !== "" && (
          <TouchableOpacity
            style={styles.backButton}
            onPress={() => this.displayContent({ value: "" })}
          >
            <Text style={styles.itemText}>{"\u25B8"} Back To Listing</Text>
          </TouchableOpacity>
        )}
        <ScrollView>
          {this.state.selected === "" &&
            this.state.mouldingList.map((value, key) => (
              <TouchableOpacity
                key={key}
                style={styles.item}
                onPress={() => this.displayContent({ value })}
              >
                <Text style={styles.itemText}><Entypo name="lab-flask" size={18}/></Text>
                <View style={styles.box}>
                  <Text style={styles.itemText}>{value}</Text>
                </View>
              </TouchableOpacity>
            ))}
          {this.state.selected === "PP - Polypropylene" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>

              <Text style={styles.contentText}><Text style={styles.bold}>PP</Text> - Polypropylene</Text>

              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PP</Text> is a highly versatile and rigid semi-crystalline
                thermoplastic. It can be polymerized into homo, block copo-, and
                random copo-polymers, depending on the position of the methyl
                groups. Homo-PP is considered as the most widely used
                general-purpose grade and composed only from propylene monomer
                in a semi-crystalline form. Copo-PP is copolymerized with ethene
                monomer, either arranged in a regular pattern (block, tougher
                and less brittle) or randomly fit in the chains (random, less
                melting point, more flexible, transparent).
              </Text>
              <View style={styles.contentHeader}>
              <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Good chemical resistance over wide range of bases
                and acids{"\n"}- Relatively cheap pricing{"\n"}- Good fatigue
                resistance{"\n"}- Good electrical insulator{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Highly flammable{"\n"}- High thermal
                expansion coefficient{"\n"}- Poor UV and scratch resistance
                {"\n"}- Poor paint adhesion
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Flexible and rigid packaging{"\n"}• Automotive industry : car
                batteries, bumpers, interior elements{"\n"}• Household items :
                containers, plates, trays{"\n"}• Furniture{"\n"}• Fibers and
                fabrics
              </Text>

              <View style={styles.contentHeader2}>
              <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 240 °C{"\n"}Melt temperature
                (range) : 220 - 280 °C{"\n"}Mold temprature : 20 - 60 °C{"\n"}
                Drying temperature : Usually need no drying{"\n"}Hold pressure :
                35 - 45 MPa{"\n"}Max peripheral screw speed : 1,3 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "PPA - Polyphthalamide" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}><Text style={styles.bold}>PPA</Text> - Polyphthalamide</Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PPA</Text> is a subgroup of thermoplastic synsthetic resin in the
                polyamide (nylon) family. It is a semi-crystalline material with
                partial-aromatic structure. As compared to PA6 or PA66, PPA is
                tougher, stiffer, and has higher thermal capabilities, it also
                tends to absorb less moisture and more dimensionally stable. All
                these superior properties are a result of substitution of
                aliphatic diacids by aromatic diacids in the polymer backbone.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Very high heat resistance, chemical resistance,
                abrasion resistance{"\n"}- Relatively low moisture absoption{" "}
                {"\n"}- Improved creep and fatigue resistance{"\n"}- Higher
                strength and stiffness even at elevated temperature{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- High processing temperature{"\n"}- Not
                inherently flame retardant{"\n"}- Require a good drying
                equipment
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Automotive : Clutch components, connecting piping for gases,
                coolants, fuels{"\n"}• Electrical : Coil formers, plugs, bulb
                and LED sockets, motor parts{"\n"}• Industrial : Heating
                systems, water meters, pump systems
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 325 °C{"\n"}Melt temperature
                (range) : 320 - 330 °C{"\n"}Mold temprature : 85 - 105 °C{"\n"}
                Drying temperature : 100 °C{"\n"}Drying time : 6 - 8 hours{"\n"}
                Drying max moisture : 0.1%{"\n"}Drying dew point : -18 °C{"\n"}
                Hold pressure : 35 - 140 MPa{"\n"}Max peripheral screw speed :
                0,2 m/s
              </Text>
            </View>
          )}
          {this.state.selected ===
            "PPE - Polyphenylene Oxide / Polyphenylene Ether" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PPE</Text> - Polyphenylene Oxide / Polyphenylene Ether
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PPE</Text> is a high-temperature, linear and non-crystalline
                thermoplastic. Due to its difficulty in processing, it is
                usually blended with polystyrene, high impact styrene-butadiene
                copolymer or polyamide, in order to achieve good transition
                temperature and enhance the toughness and processability.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Outstanding dielectric strength{"\n"}- High
                dimentional stability{"\n"}- Chemically resist to most salt
                solutions, acids and bases{"\n"}- Good impact strength{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Vulnerable to some hydrocarbons base
                chemicals{"\n"}- Color shift with UV exposure{"\n"}- Higher cost
                compared to this range of plastic resins
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Internal components for printers and copiers{"\n"}• Electrical
                : connectors, circuit breakers, vending machine{"\n"}• Air
                separation membranes for nitrogen generation{"\n"}• PPE blends
                with polyamide can be used to produce large car body parts
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 290 °C{"\n"}Melt temperature
                (range) : 280 - 310 °C{"\n"}Mold temprature : 80 - 120 °C{"\n"}
                Drying temperature : 110 °C{"\n"}Drying time : 3 - 4 hours{"\n"}
                Drying max moisture : 0.1%{"\n"}Drying dew point : -29 °C{"\n"}
                Hold pressure : 60 - 80 MPa{"\n"}Max peripheral screw speed :
                0,6 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "PPS - Polyphenylene Sulfide" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PPS</Text> - Polyphenylene Sulfide
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PPS</Text> is a semi-crystalline, high temperature and performance
                thermoplastic which consists of aromatic rings linked by
                sulfides. Due to its structure, PPS is extremely chemically
                stable and resistant to corrosion even at elevated temperature,
                so it is widely used in applications which involved in exposure
                of corrosive chemicals. However, PPS happens to be inherently
                brittleness, thus it is commonly mixed with fibers and fillers
                to further enhance its mechanical and thermal properties.
                Metallic sound can be heard when strucking PPS, makes it
                relatively easy to differentiate PPS from other type of
                plastics.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Excellent chemical resistance{"\n"}- Very high
                modulus when reinforced{"\n"}- Great thermal and dimensional
                stability{"\n"}- Good electrical insulation{"\n"}- Inherently
                flame retardant{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Subject to warpage and brittleness{"\n"}-
                Comparatively higher cost{"\n"}- Poor processability (high
                melting point)
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Most use in automotive industry : fuel injection systems,
                coolant systems, water pump impellers, thermostat holder,
                switches{"\n"}• Electrical/Electronic : bobbins, connectors,
                sockets, relays, air-cond internal parts{"\n"}• Protective
                coating for industrial level devices and equipments
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 320 °C{"\n"}Melt temperature
                (range) : 310 - 330 °C{"\n"}Mold temprature : 140 - 150 °C{"\n"}
                Drying temperature : 110 °C{"\n"}Drying time : 3 - 4 hours{"\n"}
                Drying max moisture : 0.04%{"\n"}Drying dew point : -29 °C{"\n"}
                Hold pressure : 30 - 70 MPa{"\n"}Max peripheral screw speed :
                0,2 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "PPSU - Polyphenylsulfone" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}><Text style={styles.bold}>PPSU</Text> - Polyphenylsulfone</Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PPSU</Text> is an amorphous, transparent and high-temperature
                engineering thermoplastic and categorised as polysulfone family
                with a phenyl rings linked by sulfone groups. It is the highest
                performing thermoplastic as compared to polysulfone (PSU) and
                polyethersulfone (PES) with better impact strength, heat
                tolerance, chemical resistance and excellent hydrolytic
                stability. Besides, PPSU has extreme capability to withstand
                continuous exposure to superheated steam.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Extremely high impact resistance{"\n"}- Resistance
                to hydrolysis and chemical{"\n"}- Can withstand repeated
                sterilization cycles in a steam autoclave{"\n"}- Good resistance
                against radiation{"\n"}- High rigidity over wide range of
                temperature / durable{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Stress cracking when contact with aromatic
                and oxygenated solvent, such as ketones and ethers{"\n"}- Poor
                weathering, ozone and UV resistance{"\n"}- Comparatively higher
                cost{"\n"}- High mold temperature
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Aircraft bezels and interior parts{"\n"}• Medical instruments
                that require repeat sterilization{"\n"}• Plumbing applications
                {"\n"}• Milk bottles
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 370 °C{"\n"}Melt temperature
                (range) : 350 - 390 °C{"\n"}Mold temprature : 140 - 180 °C{"\n"}
                Drying temperature : 150 °C{"\n"}Drying time : 4 hours{"\n"}
                Drying max moisture : 0.02%{"\n"}Drying dew point : -29 °C{"\n"}
                Hold pressure : 50 - 70 MPa{"\n"}Max peripheral screw speed :
                0,4 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "PS - Polystyrene" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}><Text style={styles.bold}>PS</Text> - Polystyrene</Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PS</Text> is an amorphous, transparent and non-polar commodity
                thermoplastics. Being as one of the mostly widely use plastics,
                it can be made either in rigid (General purpose polystyrene,
                GPPS & High impact polystyrene, HIPS) or foamed (expanded
                polystyrene, EPS & extruded polystyrene, XPS). GPPS is crystal
                clear in appearance but rather brittle, usually employed in
                disposable eating utensils. With the aid of Polybutadiene
                rubber, HIPS offers higher impact resistance. Foam polystyrene
                can consist of more than 95% air which makes it suitable for
                insulation and cushioning purpose. Apart from that, PS can be
                copolymerized with styrenic or other monomers (eg: ABS, ASA,
                SAN, SBS rubber) to create engineering thermoplastic as their
                properties can be tailored depending on the applications.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Relatively low material cost{"\n"}- Good
                electrical insulator{"\n"}- Excellent optical clarity{"\n"}-
                Easy to process (low melting point){"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Poor oxygen and UV resistance{"\n"}- Very
                brittle compared to other {"\n"}- Poor chemical resistance
                especially to organics{"\n"}- Flammable
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Automotive : knobs, instrument panels, trim, sound dampening
                foam.{"\n"}• Medical : tissue culture trays, test tube, petri
                dishes, test kits{"\n"}• Protective barrier for consumer
                products packaging{"\n"}• Thermal insulation layer to building
                walls and roofing, refrigerators, food packaging{"\n"}•
                Household appliances
              </Text>

              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Type : PS{"\n"}Melt temperature (nominal) : 230 °C{"\n"}Melt
                temperature (range) : 210 - 280 °C{"\n"}Mold temprature : 10 -
                70 °C{"\n"}Drying temperature : Usually need no drying{"\n"}Hold
                pressure : 45 - 50 MPa{"\n"}Max peripheral screw speed : 0,9 m/s
                {"\n"}
                {"\n"}Type : HIPS{"\n"}Melt temperature (nominal) : 230 °C{"\n"}
                Melt temperature (range) : 220 - 270 °C{"\n"}Mold temprature :
                30 - 70 °C{"\n"}Drying temperature : Usually need no drying
                {"\n"}Hold pressure : 45 - 50 MPa{"\n"}Max peripheral screw
                speed : 0,6 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "PTFE - Polytetrafluoroethylene" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PTFE</Text> - Polytetrafluoroethylene
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PTFE</Text> is a linear, semi-crystalline, high-performance
                thermoplastic that composed of strong bonding between fluorine
                and hydrocarbon. It is the highest-volume fluoropolymer (as
                compared to PCTF, PVDF, PVF, etc.) with unique combination of
                properties. PTFE is hydrophobic type, means it can't be wet by
                water or water-containing substances. It is best known as Teflon
                (trade mark name) as it offers the lowest coefficients of
                friction among all polymers. Due to its inertness, PTFE provides
                excellent resistance to most solvents and chemicals.{" "}
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Lowest coefficient of friction (self-lubricating
                effect){"\n"}- Excellent dielectric properties (high insulation
                properties), especially at high radio frequencies.{"\n"}-
                Outstanding chemical and heat resistance{"\n"}- Good weathering
                resistance{"\n"}- Noncombustible and high durability to broad
                range of temperature{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Cannot operate with conventional
                molten-state processing methods, extra costing{"\n"}- Poor creep
                and abrasion resistance{"\n"}- Low radiation resistance{"\n"}-
                Overheated PTFE will release mildly toxic fumes and cause dead
                to certain animals
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Insulation wiring in aerospace applications{"\n"}• Non-stick
                cookwares{"\n"}• Automotive : O-rings, gaskets, valve stem
                seals, shalf seals, power steering and transmissions{"\n"}•
                Chemical : coatings for heat exchangers, pumps, diaphragms,
                impellers, tanks{"\n"}• Industrial : gears and bearings, seats
                and plugs, slides plates, valve and pump parts
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 350 °C{"\n"}Melt temperature
                (range) : 300 - 380 °C{"\n"}Mold temprature : 150 °C{"\n"}Drying
                temperature : Usually need no drying{"\n"}Hold pressure : As low
                as possible{"\n"}Max peripheral screw speed : N/A, stainless
                special screw is needed
              </Text>
            </View>
          )}
          {this.state.selected === "PVC - Polyvinyl Chloride" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}><Text style={styles.bold}>PVC</Text> - Polyvinyl Chloride</Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PVC</Text> is an amorphous and versatile commodity thermoplastics which
                has been recognised as world's third largest thermoplastic by
                volume, right after PE and PP. It can be produced in two general
                forms : rigid PVC and flexible PVC. Rigid PVC is unmodified, it
                has lightweight and more stiffer and stronger, commonly used in
                construction. With the addition of plasticizers such as
                phthalates, adipates, trimellitate, it lowers the cyrstallinity
                and creates much clearer and flexible PVC.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Readily available and cost effective{"\n"}-
                Extremely good tensile strength{"\n"}- Non-toxic and
                intrinsically flame retardant{"\n"}- Good resistant to weather,
                shock and abrasion.{"\n"}- Good insulation material and chemical
                resistance{"\n"}- Durable{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Properties can change over time, due to
                plasticizer migration{"\n"}- Poor heat stability{"\n"}- Emit
                toxic fumes when melted
              </Text>

              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Buildings : window frames, floor and wall coverings, roofing
                wiring and cables{"\n"}• Packaging : bottles, transparent packs
                and punnets{"\n"}• Electrical : insulation pipes, jacketing,
                switches, plug housings, transparent distributor box housing
                {"\n"}• Medical : blood bags, transfusion tubes, surgical gloves
                {"\n"}• Garden hoses
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Type : Rigid PVC{"\n"}Melt temperature (nominal) : 190 °C{"\n"}
                Melt temperature (range) : 180 - 215 °C{"\n"}Mold temprature :
                30 - 60 °C{"\n"}Drying temperature : Usually need no drying
                {"\n"}Hold pressure : 50 - 55 MPa{"\n"}Max peripheral screw
                speed : 0,2 m/s{"\n"}
                {"\n"}Type : Flexible PVC{"\n"}Melt temperature (nominal) : 170
                °C{"\n"}Melt temperature (range) : 160 - 220 °C{"\n"}Mold
                temprature : 30 - 50 °C{"\n"}Drying temperature : Usually need
                no drying{"\n"}Hold pressure : 40 - 45 MPa{"\n"}Max peripheral
                screw speed : 0,5 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "SAN - Styrene Acrylonitrile" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>SAN</Text> - Styrene Acrylonitrile
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>SAN</Text> is an amorphous, transparent and rigid thermoplastic which
                copolymerized from styrene and acrylonitrile monomers. The
                percentage composition of styrene would be around 70% to 80%
                while acrylonitrile occupies the rest. With the combination of
                polyacrylonitrile, SAN has better hardness, strength,
                weatherability and solvent resistance as compared to polystyrene
                (PS). It has also improved chemical resistance, better heat
                stability and creep resistance. However, more acrylonitrile
                contents tend to add more yellowish tint to the plastic.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Higher glass transition temperature{"\n"}- Durable
                {"\n"}- High flexural strength{"\n"}- Improved UV resistance,
                thermal and chemical resistance compared to PS{"\n"}- Cost
                effective{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Yellowing with longer time{"\n"}- High water
                absorption{"\n"}- Flammable with high smoke generation
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Dishwashing-safe containers{"\n"}• Transparent kitchenwares
                and housewares{"\n"}• Disposable lighters{"\n"}• Toothbrush and
                cosmetic jars{"\n"}• Stationary : scales, calculators, writing
                and drawing pens{"\n"}• Electronic : TV, radio, air-cond,
                computers
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 240 °C{"\n"}Melt temperature
                (range) : 220 - 290 °C{"\n"}Mold temprature : 40 - 80 °C{"\n"}
                Drying temperature : Usually need no drying{"\n"}Hold pressure :
                45 - 50 MPa{"\n"}Max peripheral screw speed : 0,6 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "ASA - Acrylonitrile Styrene Acrylate" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>ASA</Text> - Acrylonitrile Styrene Acrylate
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>ASA</Text> is an amorphous, opaque, and tough thermoplastic. It is
                produced by introducing a grafted acrylic ester elastomer during
                copolymerization reaction between styrene and acrylonitrile
                (SAN). Chemical structure of ASA is very similar to
                Acrylonitrile Butadiene Styrene (ABS), crosslinkled acrylate
                rubber is added instead of butadiene rubber, which provides ASA
                higher long-term heat resistance, excellent weather resistance
                and better chemical resistance.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- High gloss surface{"\n"}- Extremely great weather
                resistance (UV stabilized){"\n"}- Good thermal stability{"\n"}-
                Good chemical resistance{"\n"}- High impact strength{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Hygroscopic, will absorb water from the air
                {"\n"}- Produce toxic fumes when burned{"\n"}- Vulnerable to
                some concentrated acids, esters, ethers and ketones
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Automotive : mirror housings, radiator grills{"\n"}•
                Lawn-mower components, garden hose reels, snowmobile housings,
                machinery covers, outdoor lighting covers{"\n"}• Outdoor
                furniture, windsurfer boards{"\n"}• Marine applications
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 210 °C{"\n"}Melt temperature
                (range) : 190 - 230 °C{"\n"}Mold temprature : 30 - 60 °C{"\n"}
                Drying temperature : 80 - 85 °C{"\n"}Drying time : 3 - 5 hours
                {"\n"}Drying max moisture : 0.038 to 0.1 %{"\n"}Hold pressure :
                70 - 140 MPa
              </Text>
            </View>
          )}
          {this.state.selected ===
            "TPE-A - Polyamide based Thermoplastic Elastomer" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>TPE-A</Text> - Polyamide based Thermoplastic Elastomer
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
                Commercial <Text style={styles.bold}>TPE-A</Text> is called as polyether-amide block copolymers
                (PEBA). PEBA is high-performance, light-weight thermoplastic
                elastomers based on nylon (hard segment) and polyether or
                polyester (soft-segment). These strong hydrogen-bonding amide
                domains provide relative wide range of service temperatures,
                especially at low temperature, good resistance to weathering and
                chemical. It is a plasticizer-free TPE and can be easily
                melt-blended with other polymers.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Great flexibility{"\n"}- Very low density{"\n"}-
                Outstanding impact strength at low temperature{"\n"}- Good heat
                and chemical resistance{"\n"}- Easy to process{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Expensive material
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Sport and leisure goods industry : outsoles, alpine ski boots,
                goggles, tennis rackets, bike handles{"\n"}• Handles for
                screwdrivers, kitchen tools and knifes{"\n"}• Wire and cable
                jacketing and tubings{"\n"}• Medical : catherters
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 240 °C{"\n"}Melt temperature
                (range) : 200 - 280 °C{"\n"}Mold temprature : 20 - 60 °C{"\n"}
                Drying temperature : 65 - 80 °C{"\n"}Drying time : 4 - 7 hours
                {"\n"}Drying max moisture : 0.2%{"\n"}Drying dew point : -18 °C
                {"\n"}Hold pressure : As low as possible{"\n"}Max peripheral
                screw speed : 0,6 m/s
              </Text>
            </View>
          )}
          {this.state.selected ===
            "TPE-E - Thermoplastic Copolyester Elastomer" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>TPE-E</Text> - Thermoplastic Copolyester Elastomer
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>TPE-E</Text>, sometimes refer as TPC, is a synthetic rubber consisting
                of hard polyester crystallites (normally PBT) dispersed in a
                soft, flexible matrix. The hard and soft regions are
                mechanically interlocked with strong intermolecular attractions,
                which allows TPC to be a good option when chemical and heat
                resistance are essential for certain applications. At low
                strain, it behaves like a perfect spring with ideal elasticity.{" "}
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Excellent abrasion and fatigue resistance{"\n"}-
                Excellent oil and solvent resistance{"\n"}- High tensile,
                compressive and tear strength{"\n"}- Selection of wide range of
                hardness{"\n"}- Good heat resistance{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}-{" "}
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Automotive : blow moulded boots and bellows, wire and cable,
                airbag covers{"\n"}• Industrial hose applications
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : Depends on hardness{"\n"}Melt
                temperature (range) : 150 - 210 °C{"\n"}Mold temprature : 30 -
                55 °C{"\n"}Drying temperature : 80 - 110 °C{"\n"}Drying time : 2
                - 3 hours{"\n"}Drying max moisture : 0.08%{"\n"}Drying dew point
                : -18 °C{"\n"}Hold pressure : As low as possible{"\n"}Max
                peripheral screw speed : 0,2 m/s
              </Text>
            </View>
          )}
          {this.state.selected ===
            "TPE-O - Olefin based Thermoplastic Elastomer" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>TPE-O</Text> - Olefin based Thermoplastic Elastomer
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>TPE-O</Text> is the compounds of various polyolefin thermoplastics and
                amorphous elastomers. The common example of it is polypropylene
                (PP) mixed with un-crosslinked ethylene propylene diene monomer
                rubber (EPDM rubber). They are used in applications which
                require increased toughness and durability over conventional PP
                copolymers. It can also be frequently seen in outdoor
                applications as it will not degrade under solar radiation.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Low cost{"\n"}- Good processability{"\n"}- Good UV
                resistance and heat aging properties{"\n"}- Good impact strength
                {"\n"}- Good dimensional stability{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Hardness is restricted to the high end{"\n"}
                - Poor elasticity{"\n"}- Poor high temperature resistance{"\n"}-
                Poor scratch resistance
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Automotive : bumpers, dashboards, spoilers, mudguards{"\n"}•
                Sport : Fins, masks, snorkels, body armour protection kits{"\n"}
                • Roofing
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 225 °C{"\n"}Melt temperature
                (range) : 190 - 260 °C{"\n"}Mold temprature : 30 - 60 °C{"\n"}
                Drying temperature : Usually need no drying{"\n"}Hold pressure :
                As low as possible{"\n"}Max peripheral screw speed : 0,4 m/s
              </Text>
            </View>
          )}
          {this.state.selected ===
            "TPE-S - Styrene based Thermoplastic Elastomer" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
                <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>TPE-S</Text> - Styrene based Thermoplastic Elastomer
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
                Styrenic block copolymer (SBC) <Text style={styles.bold}>TPE-S</Text> are multiphase compositions
                of hard polystyrene blocks and soft rubber material, with the
                chains connecting together in a three-dimensional way. Styrene
                end blocks act as physical cross-links to provide strength and
                hardness properties while rubber mid-block contributes the
                elasticity. A common example is poly(styrene-butadiene-styrene)
                (SBS), through selective hydrogenation, SBS became
                styrene-(ethylene-butylene)-styrene (SEBS) with enhanced
                mechanical, chemical and heat resistant properties. SBC is
                rarely used alone, it can be blended with a wide variety of
                materials (polymers, oils, fillers, additives) to achieve desire
                properties.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Compounding versatility (boundability to many
                substrates){"\n"}- Broad hardness range{"\n"}- Excellent
                colorability{"\n"}- Can be clear, translucent or opaque{"\n"}-
                Flexible at low temperature{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Poor high temperature performance{"\n"}-
                Poor oil resistance
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Grips and handles for toothbrushes, power tools{"\n"}• Medical
                tubing, films, bags{"\n"}• Footwear{"\n"}• Food contact and
                housewares, wine corks
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Type : SBS{"\n"}Melt temperature (nominal) : 190 °C{"\n"}Melt
                temperature (range) : 150 - 210 °C{"\n"}Mold temprature : 20 -
                40 °C{"\n"}Drying temperature : Usually need no drying{"\n"}Hold
                pressure : As low as possible{"\n"}Max peripheral screw speed :
                0,5 m/s{"\n"}
                {"\n"}Type : SEBS{"\n"}Melt temperature (nominal) : 225 °C{"\n"}
                Melt temperature (range) : 190 - 260 °C{"\n"}Mold temprature :
                30 - 60 °C{"\n"}Drying temperature : Usually need no drying
                {"\n"}Hold pressure : As low as possible{"\n"}Max peripheral
                screw speed : 0,5 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "TPE-U - Thermoplastic Polyurethane" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>TPE-U</Text> - Thermoplastic Polyurethane
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>TPE-U</Text> is highly versatile thermoplastic elastomer made from
                linear segmented block copolymer composed of hard (aromatic or
                aliphatic) and soft segments (polyester or polyether). Polyester
                TPU are recommended for parts that require good abrasion
                resistance, it provides great chemical and oil resistance as
                well. Meanwhile, polyether TPU is suitable for underwater
                application since it offers excellent hydrolysis resistance and
                can withstand microbes and tear stress, keeping flexibility in
                low temperature.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Excellent abrasion and scratch resistance{"\n"}-
                Good UV resistance{"\n"}- Resilence to oils, greases, and
                numerous solvents{"\n"}- High flexibility over a wide
                temperature range{"\n"}- High elasticity across the entire
                hardness range{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Some grades of TPU have relatively short
                shelf life{"\n"}- Material cost is not competitive compare to
                other alternatives{"\n"}- Narrow hardness range than other TPEs
                {"\n"}- Transparent product will turn yellowish over time{" "}
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Sport shoes soles{"\n"}• Timing belts, transmission belts,
                conveyor belt profiles{"\n"}• Automotive interior parts : gear
                knobs, instrument panels, console parts{"\n"}• Hydraulic hoses,
                fire hose liner, caster wheels{"\n"}• Wire and cable coatings
                {"\n"}
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 215 °C{"\n"}Melt temperature
                (range) : 190 - 245 °C{"\n"}Mold temprature : 20 - 50 °C{"\n"}
                Drying temperature : 110 °C{"\n"}Drying time : 1 - 2 hours{"\n"}
                Drying max moisture : 0.1%{"\n"}Drying dew point : -18 °C{"\n"}
                Hold pressure : As low as possible{"\n"}Max peripheral screw
                speed : 0,2 m/s
              </Text>
            </View>
          )}
          {this.state.selected ===
            "TPE-V - Vulcanizated Thermoplastic Elastomer" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>TPE-V</Text> - Vulcanizated Thermoplastic Elastomer
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
                Compounds of <Text style={styles.bold}>TPE-V</Text> are similar to TPE-O, both are made up of PP
                and EPDM rubber. However, they have been dynamically vulcanised
                during the compounding step. Vulcanisation refers to a chemical
                process in which the rubber is heated with sulphur, accelerator
                and activator at 140 - 160 °C. This will cause the formation of
                cross-linkings between long rubber molecules, making it improved
                elasticity, resilience, hardness and weather resistance. It is
                commonly used when elasticity and flex fatigue are required.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Good elastic properties and flex fatigue{"\n"}-
                Broad end use temperature and hardness ranges{"\n"}- Increased
                tensile strength and modulus{"\n"}- Good chemical and fluid
                resistance{"\n"}- Good UV resistance{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Poor tensile and tear strength at low
                hardness{"\n"}- Poor abrasion and scratch resistance
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Automotive : Sealing systems, door and window selas{"\n"}•
                Friction grips on iron and power tools{"\n"}• Weather stripping
                {"\n"}• Industrial seals and gaskets
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 210 °C{"\n"}Melt temperature
                (range) : 190 - 230 °C{"\n"}Mold temprature : 20 - 70 °C{"\n"}
                Drying temperature : 80 °C{"\n"}Drying time : 4 hours{"\n"}
                Drying max moisture : 0.1%{"\n"}Drying dew point : -18 °C{"\n"}
                Hold pressure : As low as possible{"\n"}Max peripheral screw
                speed : 0,6 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "ABS - Acrylonitrile Butadiene Styrene" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>ABS</Text> - Acrylonitrile Butadiene Styrene
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>ABS</Text> is an opaque thermoplastic and amorphous polymer. It is
                popular due to its low production cost and ease for machined
                with material. By polymerizing styrene and acrylonitrile in the
                presence of polybutadiene, chemical chains attract each other
                and bind together to make ABS stronger.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Good impact resistance, structural strength and
                stiffness{"\n"}- Good chemical resistance{"\n"}- Excellent high
                and loww temperature{"\n"}- Great electrical insulation
                properties{"\n"}- Easy to paint and glue{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Poor solvent and fatigue resistance{"\n"}-
                Poor UV resistance unless protected{"\n"}- Poor bearing
                properties{"\n"}- High smoke evolution
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Pipe Systems, golf club heads{"\n"}• Automotive trim
                components, bumper bars{"\n"}• Electrical and electronics
                assemblies
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 240 °C Melt Temperture (range) :
                220 - 280°C Mold temperature : 40 - 80°C Drying temperature :
                80°C Drying time : 3 hours Drying max moisture : 0.1% Drying dew
                point : -18°C Hold Pressure : 40 - 45 MPa Max peripheral screw
                speed : 0,5 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "LCP - Liquid Crystal Polymer" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>LCP</Text> - Liquid Crystal Polymer
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>LCP</Text> consists of highly crystalline molecular chain that made up
                a family of thermoplastics which have a unique set of
                properties. The most commonly used LDPs are PET copolyester,
                copolyamide and polyester-amide, however others are also
                possible. It exhibits highly ordered structure in both the melt
                and solid states. It can used to replace ceramics, metals,
                composites.{" "}
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Excellent moldability in thin section{"\n"}-
                Outstanding chemical resistance{"\n"}- Good heat resistance
                {"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- High costing{"\n"}- Knit line strength
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Halogen lamps{"\n"}• Pans{"\n"}• Electronic
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 355 °C Melt Temperture (range) :
                350 - 360°C Mold temperature : 60 - 120°C Drying temperature :
                150°C Drying time : 3 hours Drying max moisture : 0.01% Drying
                dew point : -29°C Hold Pressure : 20 - 60 MPa Max peripheral
                screw speed : Highest possible!
              </Text>
            </View>
          )}
          {this.state.selected === "Nylon 6 - Polyamide 6" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}><Text style={styles.bold}>Nylon 6</Text> - Polyamide 6</Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PA 6</Text>, also known as Polycaprolactam, can be produced in two
                general product types : regular type for textile uses (carpets,
                apparel, seat belts, etc.) and high-strength type for industrial
                uses (ring-opening polymerization of caprolactam: plastic
                resins). It is one of the most widely used polyamides because of
                its versatility. PA66 has improved surface finishing and
                processability compared to PA66.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Low mold shrinkage and melting point{"\n"}-
                Withstands high impact and stress{"\n"}- Better stands up to
                hydrocarbons, lustrous surface finish{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Low heat deflection temperature compared to
                PA66{"\n"}- High water absoprtion rate{"\n"}- Poor chemical
                resistance to strong acids and bases
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Machine building, transportation (rails, automotive, marine),
                appliances{"\n"}• Pharmaceutical, electrical and electronics
                {"\n"}• Cluster, bobbins
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 270 °C Melt Temperture (range) :
                260 - 280°C Mold temperature : 50 - 90°C Drying temperature :
                80°C Drying time : 2 - 4 hours Drying max moisture : 0.20%
                Drying dew point : -18°C Hold Pressure : 55 - 60 MPa Max
                peripheral screw speed : 0,8 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "Nylon 6,6 - Polyamide 6,6" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}><Text style={styles.bold}>Nylon 6,6</Text> - Polyamide 6,6</Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PA 66</Text> is majorly used as a replacement to metal in various
                applications because of its excellent balance strength,
                ductility and heat resistance. Nylon 66 is synthesized by
                polycondensation of hexamethylenediamine and adipic acid (two
                monomers each containing 6 carbon atoms). All nylons are
                normally reinforced with glass fibers, glass beads, and carbon
                fibers to improve their mechanical and thermal performance.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- High deflection temperature{"\n"}- Lower water
                absorption rate{"\n"}- Good chemical resitance, better
                stiffness, tensile and flexural modulus{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Greater mold shrinkage{"\n"}- Poor chemical
                resistance to stong acids and bases.{"\n"}- Molding process
                difficult to control
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Automotive applications{"\n"}• Electro-insulating elements
                {"\n"}• Pipes, cable ties
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 290 °C Melt Temperture (range) :
                280 - 300°C Mold temperature : 50 - 90°C Drying temperature :
                80°C Drying time : 2 - 4 hours Drying max moisture : 0.20%
                Drying dew point : -18°C Hold Pressure : 55 - 60 MPa Max
                peripheral screw speed : 0,8 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "PBT - Polybutylene Terephtalate" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PBT</Text> - Polybutylene Terephtalate
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PBT</Text> is a semi-crystalline, high molecular weight polymer that
                has excellent dielectric strength and electrical properties,
                majorly used as an insulator in the electrical and electronic
                induestries. PBT differs only slightly from PET in chemical
                composition but more substantially in its properties such as its
                higher crystallization rate and lower melting point. Compared to
                non-reinforced resin, glass-reinforced PBT exhibits a 1.75x
                increase in tensile, flexural and compressive strengths and
                modulus.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Low water absorption{"\n"}- Good moldability for
                high precision components{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Affected by boiling water{"\n"}- Lower
                strength and rigidity than PET{"\n"}- Lower glass transition
                temperature than PET
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Automotive : Windshield wiper covers, mirror housings{"\n"}
                •Cowl vents, handles and fans{"\n"}• Fuel system components
                {"\n"}• Connectors, sensor housing, fuse boxes
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 250 °C Melt Temperture (range) :
                240 - 260°C Mold temperature : 30 - 130°C Drying temperature :
                120°C Drying time : 2 - 4 hours Drying max moisture : 0.04%
                Drying dew point : -29°C Hold Pressure : 55 - 55 MPa Max
                peripheral screw speed : 0,4 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "PC - Polycarbonate" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}><Text style={styles.bold}>PC</Text> - Polycarbonate</Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PC</Text> is a high-performance, tough, amorphous and transparent
                thermoplastic polymer that offers unique combination of
                properties such as high impact strength, good heat resistance
                and most importantly, transparency. Due to its great
                compatibility with certain polymers, it is widely used in blends
                as PC/ABS, PC/PET, PC/PBT.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- High impact resistance, transmittance{"\n"}-
                Lightweight{"\n"}- Protection from UV radiation{"\n"}- High heat
                resistance{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Higher pricing than PMMA{"\n"}- Poor
                chemical resistance{"\n"}- Susceptible to scratching
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Automotive : DVDs, CDs, cell phone, laptop components{"\n"}•
                Roller shutters, safety goggles, ophthalmic lenses{"\n"}• Large
                volume water bottles{"\n"}• Switches, plug and sockets, helmets
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 290 °C Melt Temperture (range) :
                280 - 330°C Mold temperature : 80 - 120°C Drying temperature :
                120°C Drying time : 2 - 4 hours Drying max moisture : 0.02%
                Drying dew point : -29°C Hold Pressure : 60 - 80 MPa Max
                peripheral screw speed : 0,4 m/s
              </Text>
            </View>
          )}
          {this.state.selected ===
            "UHMWPE - Ultra High Molecular Weight Polyethylene" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>UHMWPE</Text> - Ultra High Molecular Weight Polyethylene
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>UHMWPE</Text>, also known as High-Modulus Polyethylene (HMPE), is a
                semi-crystalline, whitish and efficiently opaque engineering
                thermoplastic which, chemically, is an extremely high molecular
                weight (3-6 million) HDPE. As a result, it has a very high melt
                viscosity and can usually be processed only by powder sintering
                methods. It is best described as extremely tough material, used
                for wear and tear applications.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Extremely low moisture absorption{"\n"}-
                Chemically resistant (excluding oxidising acids){"\n"}- High
                thermal conductivity{"\n"}- Superior abrasion and impact
                resistance{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Not melt processable{"\n"}- Will deform
                continually as long as stress is present (creep)
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Cord and webbing, bow strings{"\n"}• Climbing equipment{"\n"}•
                Fishing line, spear line
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>N/A</Text>
            </View>
          )}
          {this.state.selected === "HDPE - High Density Polyethylene" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>HDPE</Text> - High Density Polyethylene
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>HDPE</Text> is a robust, moderately stiff plastic with a highly
                crystalline structure. It is one of the most versatile
                thermoplastics which is used in various type of industries. It
                is a cost-effective thermoplastic with linear structure and no
                or low degree of branching, famously known as light-weight yet
                super strong material.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- High strength to density ratio{"\n"}- Good
                chemical resistance (except hydrocarbons){"\n"}- Low cost with
                good processability{"\n"}- FDA compliant{"\n"}- High impact and
                tensile strength{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Susceptible to stress cracking{"\n"}- Lower
                stuffness than Polypropylene (PP){"\n"}- Poor UV and low heat
                resistance
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Crates, trays, bottles for milk and fruit juices{"\n"}• Caps
                for food packaging, jerry cans, drums{"\n"}• Industrial bulk
                containers, water pipes
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 200 °C Melt Temperture (range) :
                200 - 280°C Mold temperature : 25 - 60°C Drying temperature :
                Usually need no drying Hold Pressure : 25 - 35 MPa Max
                peripheral screw speed : 1,3 m/s
              </Text>
            </View>
          )}
          {this.state.selected ===
            "LLDPE - Linear Low Density Polyethylene" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>LLDPE</Text> - Linear Low Density Polyethylene
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
                The structure of <Text style={styles.bold}>LLDPE</Text> has a linear backbone with short, uniform
                branches (unlike longer branches of LDPE). These short branches
                are able slide against each other upon elongation without
                becoming entangled like LPDE. LLDPE offers higher tensile
                strength and higher impact and puncture resistance than does
                LDPE. It is very flexible and elongates under stress. In recent,
                LLDPE has been quite successful in replacing LDPE.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Better environmental stress cracking resistance
                {"\n"}- Higher flexibility and tensile strength than LDPE{"\n"}-
                Good chemical resistance and electrical properties{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Less glossy than LDPE{"\n"}- Narrower
                temperature range for heat sealing{"\n"}- Not as easy to process
                as LDPE
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Plastic bags and sheets, plastic wrap, strecth wrap{"\n"}•
                Pouches, toys{"\n"}• Covers, lids, pipes
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>N/A</Text>
            </View>
          )}
          {this.state.selected === "LDPE - Low Density Polyethylene" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>LDPE</Text> - Low Density Polyethylene
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>LDPE</Text> is a semi-rigid and translucent polymer. Compared to HDPE,
                it has a higher degree of short and long side-chain branching.
                It is a very flexible material with unique flow properties that
                makes it particularly suitable for shopping bags and other
                plastic film applications. The largest outlet for LDPE is the
                film market, accounting for around 55% of global consumption and
                used mainly in food and non-food packaging.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Low cost with good processability{"\n"}-
                Transparent in thin film form{"\n"}- Excellent electrical
                insulating temperature{"\n"}- Good chemical resistance{"\n"}-
                Good impact strength{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Very low stiffness{"\n"}- Poor heat
                resistance (high coefficient of thermal expansion)
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Trays, containers{"\n"}• Furniture{"\n"}• Plastic wraps{"\n"}•
                Playground slides
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 200 °C Melt Temperture (range) :
                180 - 240°C Mold temperature : 20 - 60°C Drying temperature :
                Usually need no drying Hold Pressure : 25 - 35 MPa Max
                peripheral screw speed : 0,9 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "PEX - Cross-linked Polyethylene" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PEX</Text> - Cross-linked Polyethylene
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PEX</Text> is a polyethylene material which has undergone a change in
                molecular structure using a chemical or a physical process
                whereby the polymer chains are chemically linked. It is
                specifically designed for critical applications like chemical
                storage pipework systems, hydronic radiant heating and cooling
                systems, and insulation for high voltage electrical cables.
                Probably the most common reason for using PEX is to replace
                traditional galvanized steel, copper and PVC piping due to
                rusting, cost and circulation.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Excellent low temperature impact strength{"\n"}-
                High abrasion resistance{"\n"}- High environmental stress
                cracking resistance{"\n"}- Hydrolysis resistance{"\n"}- Can
                withstand extreme pressure{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Perforation by insects{"\n"}- Degradation
                from sunlight is fairly rapid
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>• Piping and tubing</Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>N/A</Text>
            </View>
          )}
          {this.state.selected === "PEEK - Polyether Ether Ketone" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PEEK</Text> - Polyether Ether Ketone
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PEEK</Text> belongs to polyaryletherketone (PAEK) family of polymers
                (PEK, PEEK, PEEKK, PEKK, PEKEKK) and amongst them, PEEK is the
                most widely used and manufactured in large scale. It is a
                semi-crystalline, rigid, high-performance engineering
                thermoplastic. It is one of the few plastics compatible with
                ultra-high vacuum applications, which makes it suitable for
                aerospace, automotive, teletronic, and chemical industries.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Excellent long term chemical resistance{"\n"}- Low
                moisture uptake{"\n"}- Good fire performance{"\n"}- Excellent
                mechanical strength across a broad temprature range{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Poor solvent resistance{"\n"}- Expensive
                {"\n"}- Processing at high temperature
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Bearings, piston parts{"\n"}• Pumps, electrical cable
                insulation, valves
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 370 °C Melt Temperture (range) :
                360 - 430°C Mold temperature : 160 - 200°C Drying temperature :
                160°C Drying time : 2 - 3 hours Drying max moisture : 0.1%
                Drying dew point : -18°C Hold Pressure : 50 - 65 MPa Max
                peripheral screw speed : 0,2 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "POK - Polyketone" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}><Text style={styles.bold}>POK</Text> - Polyketone</Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>POK</Text> is a new green polymeric material composed of
                carbon-monoxide and olefins. It is a semi-crystalline
                engineering plastic and with the backbone of the structure
                consists only carbons, it exhibits many desirable mechanical and
                thermo-physical properties which no other polymer has in this
                combination.
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- High impact strength{"\n"}- Exceptionally high
                abrasion resistance with minimum wear and tear{"\n"}- Hydrolysis
                resistance{"\n"}- High chemical resistance{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Poor UV stability
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Gear wheels, bearings, bushings{"\n"}• Mechanical parts
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt Temperture (range) : 240 °C - 250 °C Mold temperature : 60
                - 80 °C Drying temperature : 80°C Drying time : 2-4 hours Drying
                max moisture : 0.2% Hold Pressure : 50 - 70% of max injection
                pressure{" "}
              </Text>
            </View>
          )}
          {this.state.selected === "PET - Polyethylene Terephthalate" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PET</Text> - Polyethylene Terephthalate
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PET</Text> or <Text style={styles.bold}>PETE</Text> is highly flexible, colorless and semi-crystalline
                resin in its natural state. Depending upon how it is processed,
                it can be semi-rigid to rigid. PET can be used in fibres for
                permanent-press fabrics, blow-molded into disposable beverage
                bottles, or extruded into photographic film and magnetic
                recording tape. PET is the most recycled thermoplastic, and has
                the number "1" as its recycling symbol.{" "}
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- High strength and stiffness than PBT{"\n"}- Very
                strong and lightweight{"\n"}- Excellent electrical insulating
                properties{"\n"}- Low gas permeability{"\n"}- Higher heat
                distortion temperature than PBT{"\n"}- Shatterproof{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Lower impact strength than PBT{"\n"}- Slow
                crystallization rate{"\n"}- Poor burning behavior
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Drink bottles, container for packing food{"\n"}• Personal care
                products, bath bottles
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Type: Glass reinfored PET{"\n"}Melt temperature (nominal) : 285
                °C Melt Temperture (range) : 280 - 300°C Mold temperature : 80 -
                120°C Drying temperature : 120°C Drying time : 4 hours Drying
                max moisture : 0.02% Drying dew point : -40°C Hold Pressure : 55
                - 55 MPa Max peripheral screw speed : 0,2 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "PMMA - Polymethyl Methacrylate" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PMMA</Text> - Polymethyl Methacrylate
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>PMMA</Text> or Arcylic, is a transparent and rigid thermoplastic
                material widely used as a shatterproof replacement for high cost
                and less resilient glass. It is seen as a cheap alternative to
                polycarbonate (PC) when high strength is not necessarily a
                stringent requirement for the given application.{" "}
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- Outstanding resistance to UV light and weathering
                compared to PC, PS{"\n"}- Excellent light transmission{"\n"}-
                Unlimited coloring options{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Poor impact resistance{"\n"}- Limited
                chemical resistance{"\n"}- Poor wear and abrasion resistance
                {"\n"}- Cracking under load possible
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • LED light, car headlight, bucket{"\n"}• Glass roofing,
                furniture and windshields
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Melt temperature (nominal) : 230 °C Melt Temperture (range) :
                190 - 260°C Mold temperature : 30 - 80°C Drying temperature :
                80°C Drying time : 4 hours Drying max moisture : 0.05% Drying
                dew point : -18°C Hold Pressure : 60 - 80 MPa Max peripheral
                screw speed : 0,6 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "POM - Polyoxymethylene" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
              <Ionicons name="flask-outline" size={20}/>
                <Text style={styles.contentHeaderText}>TYPE OF MATERIAL</Text>
              </View>
              <Text style={styles.contentText}><Text style={styles.bold}>POM</Text> - Polyoxymethylene</Text>
              <View style={styles.contentHeader}>
                <Ionicons name="md-document-text-outline" size={20}/>
                <Text style={styles.contentHeaderText}>DESCRIPTION</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>POM</Text> or Acetal, is a formaldehyde-based, semi-crystalline
                engineering thermoplastic and widely used to produce precision
                parts with high lubricity. Different manufacturing processes are
                used to produce the homopolymer and copolymer versions of POM.
                In alkaline environments, copolymers are more stable than the
                homopolymers. On the other hand, homopolymers provide better
                mechanical properties than copolymers.{" "}
              </Text>
              <View style={styles.contentHeader}>
                <Fontisto name="preview" size={20}/>
                <Text style={styles.contentHeaderText}>PROPERTIES</Text>
              </View>
              <Text style={styles.contentText}>
              <Text style={styles.bold}>Pros</Text> :{"\n"}- High strength, rigidity and toughness{"\n"}- Good
                impact strength and wear resistance{"\n"}- Excellent
                machinability, good creep resistance{"\n"}
                {"\n"}<Text style={styles.bold}>Cons</Text> :{"\n"}- Poor UV stability{"\n"}- High mold shrinkage
                {"\n"}- Poor resistance to strong acids, bases and oxidizing
                agents
              </Text>
              <View style={styles.contentHeader}>
                <Ionicons name="cube-outline" size={20}/>
                <Text style={styles.contentHeaderText}>APPLICATION</Text>
              </View>
              <Text style={styles.contentText}>
                • Fuel systems, seatbelt adjuster, car lock{"\n"}• Gears, pipes,
                water meters
              </Text>
              <View style={styles.contentHeader2}>
                <Ionicons name="md-thumbs-up-outline" size={20}/>
                <Text style={styles.contentHeaderText2}>
                  RECOMMENDED MOLDING PARAMETERS
                </Text>
              </View>
              <Text style={styles.contentText2}>
                Type: POM Homopolymer{"\n"}Melt temperature (nominal) : 215 °C
                Melt Temperture (range) : 210 - 220°C Mold temperature : 90 -
                120°C Drying temperature : Usually need no drying {"\n"}Hold
                Pressure : 60 - 80 MPa Max peripheral screw speed : 0,3 m/s
                {"\n"}
                {"\n"}Type: POM Copolymer{"\n"}Melt temperature (nominal) : 205
                °C Melt Temperture (range) : 200 - 220°C Mold temperature : 90 -
                120°C Drying temperature : Usually need no drying {"\n"}Hold
                Pressure : 60 - 80 MPa Max peripheral screw speed : 0,4 m/s
              </Text>
            </View>
          )}
          {this.state.selected === "Citation" && (
            <View style={styles.contentContainer}>
              <View style={styles.contentHeader}>
                <Text style={styles.contentHeaderText}>Citation</Text>
              </View>
              <Text style={styles.contentText}>
                - Omnexus.specialchem.com. 2020. Plastic Materials | Free Online
                Database For Plastic Industry. [online] Available at:
                omnexus.specialchem.com [Accessed 6 May 2020].
                {"\n\n"}- Polymerdatabase.com. 2019. A-B Polymer Class Index.
                [online] Available at: polymerdatabase.com/home.html [Accessed 7
                May 2020].
                {"\n\n"}- Dielectric Manufacturing. 2020. Thermoset,
                Thermoplastic Materials For Parts Fabrication. [online]
                Available at: dielectricmfg.com/materials/plastics [Accessed 7
                May 2020].
                {"\n\n"}- Plastic Molded Concepts. 2020. Engineered Resins.
                [online] Available at: www.pmcplastics.com/materials [Accessed 6
                May 2020].
                {"\n\n"}- AZoM.com. 2020. Materials Information By Material.
                [online] Available at: www.azom.com/materials.aspx [Accessed 7
                May 2020].
              </Text>
            </View>
          )}
        </ScrollView>
      </View>
    );
  }
}

export default MouldingGuideScreen;
