import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  productContainer: {
    flex: 1
  },
  modeBar: {
    backgroundColor: 'green',
    height: 50,
  },
  modeText: {
    textAlign: 'center',
    color: 'white',
    fontSize: 25,
    paddingTop: 5
  },
  text:{fontSize:35,fontWeight:"bold",color: "green", paddingLeft:"6%"},
  scrollViewContainer: { 
    flexShrink: 1,
    justifyContent: "center",
    paddingVertical: 15, 
  },
  formContainer: {
   flex:1
  },
  icon:{   
    marginTop:"-28%",
    marginLeft:"70%",
    marginBottom:"25%"
  }
});

export default styles;