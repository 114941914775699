import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  mouldingContainer: {
    backgroundColor: "#F2F1F1",
    flex: 1,
  },
  item: {
    flexDirection: "row",
    flexGrow: 1,
    borderWidth: 0.5,
    borderColor: "gray",
    alignSelf: "stretch",
    padding: 10,
    backgroundColor: "white",
    maxHeight: "10%",
  },
  itemText: {
    fontSize: 19,
    fontWeight: "bold",
    paddingLeft: 13,
    color: "black",
  },
  contentContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  backButton: {
    borderWidth: 0.5,
    borderColor: "gray",
    alignSelf: "stretch",
    padding: 10,
    backgroundColor: "white",
  },
  contentHeader: {
    marginLeft: 20,
    marginRight: "20%",
    backgroundColor: "#F7FDF5",
    zIndex: 1,
    marginTop: "5%",
    padding: 5,
    borderRadius: 8,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
    marginBottom: -10,
    flexDirection: "row",
  },
  contentHeader2: {
    marginLeft: 20,
    marginRight: "20%",
    backgroundColor: "#F7FDF5",
    zIndex: 1,
    marginTop: "5%",
    padding: 5,
    borderRadius: 8,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
    marginBottom: -30,
    flexDirection: "row",
  },
  contentHeaderText: {
    fontSize: 20,
    fontWeight: "bold",
    paddingLeft: 5,
  },
  contentHeaderText2: {
    fontSize: 20,
    fontWeight: "bold",
    paddingLeft: 5,
  },
  box: {
    backgroundColor: "white",
    height: "100%",
    width: "95%",
  },
  contentText: {
    padding: 5,
    paddingTop: 20,
    borderColor: "#374957",
    borderWidth: 2,
    fontSize: 16,
    fontWeight: "500",
  },
  contentText2: {
    padding: 5,
    paddingTop: 35,
    borderColor: "#374957",
    borderWidth: 2,
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 15,
  },
  bold: {
    fontWeight: "800",
    
  },
});

export default styles;
