import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  orderContainer: {
    flex: 1
  },
  emptyMessage: {
    alignSelf: 'center',
    justifyContent: 'center'
  },
  refreshIcon: {
    alignSelf: 'center'
  }
});

export default styles;