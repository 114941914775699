import React, { Component } from 'react';
import {
  View, Text, ScrollView, StyleSheet,
  Image, TouchableOpacity
} from 'react-native'; 
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import * as Notifications from 'expo-notifications';
// import styles from '../styles/supplierHomeScreen.styles';
import Header from '../components/header';
import Icon from "react-native-vector-icons/Ionicons";
import Icons from 'react-native-vector-icons/AntDesign';
import Category from "../components/Category.js";

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class SupplierHomeScreen extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
    this.handleNotificationResponse = this.handleNotificationResponse.bind(this);
  }

  componentDidMount() {
    Notifications.addNotificationResponseReceivedListener(this.handleNotificationResponse);
  }

  handleNotificationResponse(notification: any) {
    if (notification.origin === 'selected') {
      this.props.navigation.navigate('Notification');
    }
  }

  onNavigate(destination: string) {
    this.props.navigation.navigate(destination);
  }

  render() {
    return (
      <>
      <Header headerText='Home' onBack={() => this.props.navigation.goBack()} back={false} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti} />
      <ScrollView scrollEventThrottle={16} style={{ backgroundColor: "white" }}>
        <View style={{alignItems:"center"}}>
          <View
            style={{
              flex: 1,
              backgroundColor: "white",
              paddingTop: 20,
            }}
          >
            <View style={{ paddingLeft: 20 }}>
              <Text style={{ fontSize: 24, color: "#16773C",
                fontWeight: "bold", paddingBottom: 20 }}>
                What We Do
              </Text>
            </View>
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              style={{ height: 230 }}
            >
              <Category
                imageUri={require("../assets/images/welding.jpg")}
                name="Welding"
              />
              <Category
                imageUri={require("../assets/images/chemical.jpg")}
                name="Chemical"
              />
              <Category
                imageUri={require("../assets/images/lighting.jpg")}
                name="Lighting"
              />
            </ScrollView>
          </View>


          <View
            style={{
              alignContent: "space-between",
              justifyContent: "space-evenly",
              flexDirection: "row",
              height:130,
              width: 400,
            }}
          >
            <TouchableOpacity onPress={() => this.onNavigate('MeScreen')}>
              <View style={[styles.homeButton, styles.shadowButton]}>
                <Icon name="person-outline" color="black" size={40} />
              </View>
              <Text style={[styles.textButton]}>About Me</Text>
            </TouchableOpacity>
            
            <TouchableOpacity onPress={() => this.onNavigate('QuotingScreen')}>
              <View style={[styles.homeButton, styles.shadowButton]}>
                <Icon name="cube-outline" style={{paddingLeft:"5%"}} color="black" size={43} />
              </View>
              <Text style={[styles.textButton]}>Quote Product</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.onNavigate('Message')}>
              <View style={[styles.homeButton, styles.shadowButton]}>
                <Icon name="call-outline" color="black" size={40} />
              </View>
              <Text style={[styles.textButton]}>Contact Us</Text>
            </TouchableOpacity>
          </View>

          <View
            style={{
              alignContent: "space-between",
              justifyContent: "space-evenly",
              flexDirection: "row",
              height: 100,
              width: 290
            }}
          >
            <TouchableOpacity onPress={() => this.onNavigate('ProjectDevelopmentListing')}>
              <View style={[styles.homeButton, styles.shadowButton]}>
                <Icon name="folder-outline" style={{ paddingLeft: "5%" }} color="black" size={40} />
              </View>
              <Text style={[styles.textButton]}>Project{"\n"}Development</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => this.onNavigate('ConsumptionListing')}>
              <View style={[styles.homeButton, styles.shadowButton, { paddingLeft: 2 },]}>
                <Icons name="solution1" color="black" size={40} />
              </View>
              <Text style={[styles.textButton]}>Consumption{"\n"}List</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.logoContainer}>
          <Text>Euro Potential Sdn. Bhd.</Text>
          <Image
            style={styles.logo}
            source={require("../assets/images/logo_ep_ios.png")}
          />
        </View>
      </ScrollView>
    </>
    );
  }
}

const styles = StyleSheet.create({
  homeButton: {
    height: 80,
    width: 80,
    // backgroundColor: "#E2F0D9",
    backgroundColor: "#E9E9E9", //grey color
    //backgroundColor: "#F7FDF5", // light green color
    borderRadius: 60,
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    opacity: 0.4,
    paddingTop: 50,
    alignItems: "center"
  },
  logo: {
    width: 100,
    height: 100,
  },
  textButton: {
    color: "#374957",
    paddingTop: 7,
    fontSize: 14,
    textAlign: "center",
  },
  shadowButton: {
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
  },
});


export default SupplierHomeScreen;
