import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  purchaseContainer: {
    flex: 1
  },
  scrollViewContainer: { 
    flexShrink: 1,
    justifyContent: "center",
    paddingVertical: 15, 
  },
  formContainer: {
   flex:1
  },
  icon:{   
    marginTop:"-28%",
    marginLeft:"70%",
    marginBottom:"25%"
  }
});

export default styles;