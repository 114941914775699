/***************************************************/
// Navigation & Linking Page for Customer
/***************************************************/

import React from 'react';
// import { NavigationContainer } from "@react-navigation/native";
// import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
// import { createStackNavigator } from "@react-navigation/stack";
import { createAppContainer } from 'react-navigation';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';
import Icon from '../components/ionicon';
import SimpleIcon from "react-native-vector-icons/SimpleLineIcons";
import HomeScreen from './homeScreen';
import ProductListing from './productListing';
import PurchaseScreen from './purchaseScreen';
import OrderListing from './orderListing';
import MessageTargetListing from './messageTargetListing';
import MessageScreen from './messageScreen';
import MeScreen from './aboutMeScreen';
import CustomerSettingsScreen from './customerSettingScreen';
import QuotationScreen from './quotationScreen';
import NotificationScreen from './notificationScreen';
import CompanyDetailScreen from './companyDetailScreenNonEditable';
import MouldingGuideScreen from './mouldingGuideScreen';
import CreditLimitScreen from './creditLimitScreen';
import FeedScreen from './feedScreen';
import MessageListing from './messageListing';
import UploadDocumentScreen from './uploadDocumentScreen';

const MeStack = createStackNavigator({
  NotificationScreen: { screen: NotificationScreen },
  HomeScreen: { screen: HomeScreen },
  MeScreen: { screen: MeScreen },
  OrderListing: { screen: OrderListing },
  QuotationScreen: { screen: QuotationScreen },
  CompanyDetailScreen: { screen: CompanyDetailScreen },
  MouldingGuideScreen: { screen: MouldingGuideScreen },
  CreditLimitScreen: { screen: CreditLimitScreen },
  UploadDocumentScreen:{ screen: UploadDocumentScreen},
}, {
  headerMode: 'none',
  mode: 'card',
  initialRouteName: 'HomeScreen',
});
const MenuStack = createStackNavigator({
  CustomerSettingsScreen:{ screen: CustomerSettingsScreen},
  MeScreen: { screen: MeScreen},
  QuotationScreen: { screen: QuotationScreen },
  CompanyDetailScreen: { screen: CompanyDetailScreen },
  MouldingGuideScreen: { screen: MouldingGuideScreen },
  CreditLimitScreen: { screen: CreditLimitScreen },
  UploadDocumentScreen:{ screen: UploadDocumentScreen},
}, {
  headerMode: 'none',
  mode: 'card',
  initialRouteName: 'CustomerSettingsScreen',
});

const MessageStack = createStackNavigator({
  NotificationScreen: { screen: NotificationScreen },
  Chats: { screen: MessageListing },
  MessageTargetListing: { screen: MessageTargetListing },
  MessageScreen: { screen: MessageScreen }
}, {
  headerMode: 'none',
  mode: 'card',
  initialRouteName: 'Chats',
});

const TabNavigator = createBottomTabNavigator({
  Home: MeStack,
  News: FeedScreen,
  Material: ProductListing,
  Enquiry: PurchaseScreen,
  Message: MessageStack,
  Menu: MenuStack,
}, {
  defaultNavigationOptions: ({ screenProps, navigation }) => ({
    tabBarIcon: ({ focused, tintColor }) => {
      const rn: string = navigation.state.routeName;

      let iconName: string = '';
      let badgeCount: number = 0;
      let color: string = tintColor || 'gray';
      let iconSize: number = 0;

      if (rn === 'Home') {
        iconName = focused ? "home" : "home-outline";
        iconSize = 26;
      } else if (rn === 'News') {
        iconName = focused ? "ios-reader-sharp" : "ios-reader-outline";
        iconSize = 26;
      } else if (rn === 'Notification') {
        iconName = focused ? "notifications" : "notifications-outline";
        iconSize = 26;
      } else if (rn === 'Message') {
        iconName = focused
          ? "md-chatbox-ellipses"
          : "md-chatbox-ellipses-outline";
        iconSize = 26;
        badgeCount = screenProps.unreadCount;
      } else if (rn === 'Enquiry') {
        iconName = focused ? "information" : "information-outline";
        iconSize = 26;
      } else if (rn === 'Material') {
        iconName = focused ? "md-layers" : "md-layers-outline";
        iconSize = 26;
      } else if (rn === 'Menu') {
        iconName = "menu";
        iconSize = 24;
      }

      return (rn === 'Menu' ? <SimpleIcon
        name={iconName}
        size={iconSize}
        color={color}
        style={{
          shadowColor: "#C8E5BD",
          shadowOpacity: 1,
          shadowRadius: 1,
          shadowOffset: {
            width: 2,
            height: 1.5,
          },
          elevation: 10, //android shadow but cannot use
          textShadowOffset: { width: 5, height: 2 }, // andriod shadow but cannot use
        }}
      /> : <Icon name={iconName} color={color} badgeCount={badgeCount} size={iconSize}/>);
    }
  }),
  tabBarOptions: {
    activeTintColor: "#0E6B35",
    inactiveTintColor: "#8fad86",
    keyboardHidesTabBar: false,
  }
});

export default createAppContainer(TabNavigator);
