import React, { Component } from "react";
import { View, ScrollView, Text } from "react-native";
import styles from "./productList.styles";
import Product from "./product";

//Content card list

interface CustomInputProps {
  dataList: Array<any>;
  active: Number;
}

class ProductList extends Component<CustomInputProps> {
  render() {
    return (
      <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.cardList}>
        
        {this.props.dataList.map((item, key) => (
          <Product
            key={key}
            title={item.title}
            content={item.content}
            action={item.buttonText}
            unread={item.unread || false}
            onPress={item.buttonOnPress}
            active={item.active}
          />
        ))}
      </ScrollView>
    );
  }
}

export default ProductList;
