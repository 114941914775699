//Authenticate Service

import axios from 'axios';
import * as apis from '../configs/devApis';

export const login = async (email: string, password: string) => {
  try {
    const loginResponse: any = await axios.post(apis.LOGIN_API, { email: email, password: password }, { headers: { 'Content-Type': 'application/json' }, timeout: 2000 });
    if (loginResponse.data) {
      if (loginResponse.data.validLogin) {
        return loginResponse.data;
      } //if (loginResponse.data.validLogin)
    } //if(loginResponse.data)
    return false;
  } catch (err) {
    console.log(`Error returned from login API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  } //catch (err)
} //export const login