export const LOGIN_API = 'https://eplimitless.com:6040/login'
export const ORDER_API = 'https://eplimitless.com:6010'
export const MESSAGE_API = 'https://eplimitless.com:6020'
export const NOTIFICATION_API = 'https://eplimitless.com:6030'
export const USER_API = 'https://eplimitless.com:6040'
export const COMPANY_API = 'https://eplimitless.com:6050'
export const SUPPLIER_API = 'https://eplimitless.com:6060'
export const PRODUCT_API = 'https://eplimitless.com:6070'
export const FEED_API = 'https://eplimitless.com:6080'
export const PROJECT_API = 'https://eplimitless.com:6090'
export const STATIC_PATH = 'https://eplimitless.com/static/media/feed/'
export const CONSUMPTION_API = 'https://eplimitless.com:6100'
export const CURRENT_VERSION = '1.8.2'

//Development Server