import React, { Component } from 'react';
import { Text, View, SafeAreaView, TouchableOpacity, Pressable } from 'react-native';
import styles from './header.styles';
import Ionicons from "react-native-vector-icons/Ionicons";
import IconBadge from './iconBadge';
import Icon from './ionicon';
import { RFPercentage } from 'react-native-responsive-fontsize';

interface CustomInputProps {
  headerText: string;
  onPress: Function;
  onBack: Function;
  back: boolean;
  notification: boolean;
  badgeCount: number;
}

class Header extends Component<CustomInputProps> {
  static defaultProps = {
    headerText: '',
    back: false,
    badgeCount: 0
  }
  onPress() {
    this.props.onPress();
  }
  onBack() {
    this.props.onBack();
  }

  render() {
    return (
      <SafeAreaView style={styles.header}>
        <View style={styles.headerButtonContainer}>
          {this.props.back === true &&
            <TouchableOpacity style={styles.headerButton} onPress={this.onBack.bind(this)}>
              <Ionicons name="chevron-back" size={RFPercentage(3.5)} color='#F7FDF5' />
            </TouchableOpacity>}
        </View>
        <View style={[styles.headerTextContainer]}><Text style={styles.headerText}>{this.props.headerText}</Text></View>
        <View style={styles.headerButtonContainer}>
          {this.props.notification === true &&
          <TouchableOpacity style={styles.headerButton} onPress={this.onPress.bind(this)}>
            <Icon name="md-notifications" size={RFPercentage(2.7)} color='#F7FDF5' badgeCount={this.props.badgeCount} />
          </TouchableOpacity>}
        </View>
      </SafeAreaView>
    );
  }
}

export default Header;