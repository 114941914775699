import { View, Text, TouchableOpacity, Platform } from "react-native";
import React from "react";
import { setStatusBarBackgroundColor } from "expo-status-bar";

export default function Btn({ bgColor, btnLabel, textColor, press}) {
  return (
    <TouchableOpacity
      onPress={press}
      style={{
        backgroundColor: bgColor,
        borderRadius: 100,
        alignItems: "center",
        width: 200,
        paddingVertical: "4%",
        marginTop: "20%",
        marginBottom: "4%",
      }}
    >
      <Text style={{ color: textColor, fontSize: Platform.OS === 'ios' ? 22 : 16, fontWeight: "bold" }}>
        {btnLabel}
      </Text>
    </TouchableOpacity>
  );
}
