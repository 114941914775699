import axios from 'axios';
import * as apis from '../configs/devApis';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

export const getNotification = async (token: string) => {
  try {
    const getNotificationResponse: any = await axios.get(apis.NOTIFICATION_API + '/notification', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getNotificationResponse.data;
  } catch (err) {
    console.log(`Error returned from GET notification API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getLimitNotification = async (token: string, limit: number) => {
  try {
    const getNotificationResponse: any = await axios.get(apis.NOTIFICATION_API + '/notification/' + limit, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getNotificationResponse.data;
  } catch (err) {
    console.log(`Error returned from GET notification API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const readNotification = async (token: string, notificationId: string) => {
  try {
    await axios.put(apis.NOTIFICATION_API + '/notification/' + notificationId + '/read', {}, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from PUT notification read API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const registerForPushNotificationsAsyn = async (token: string) => {
  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('chat-messages', {
      name: 'Chat messages',
      importance: Notifications.AndroidImportance.MAX,
    });
  }

  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus: string = existingStatus;

  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }

  if (finalStatus !== 'granted') {
    alert('Failed to get push token for push notification!');
    return;
  }

  let expoToken: string = (await Notifications.getExpoPushTokenAsync()).data;
  await axios.post(apis.NOTIFICATION_API + '/expoToken', { expoToken: expoToken }, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
}

export const postNotification = async (token: string, userId: string, title: string, message: string, destination: string, destinationId: string) => {
  try {
    await axios.post(apis.NOTIFICATION_API + '/notification', { 'userId': userId, 'title': title, 'message': message, 'destination': destination, 'destinationId': destinationId }, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from POST notification API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}
