import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  managementUserContainer: {
    backgroundColor: "#F2F1F1",
    flex: 1,
  },
  item: {
    maxHeight: 100,
    flexGrow: 1,
    borderWidth: 0.5,
    borderColor: "gray",
    alignSelf: "stretch",
    justifyContent: "center",
    paddingLeft: 10,
  },
  itemText: {
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: 20,
  },
  btn: {
    flexDirection: "row",
    borderRadius: 8,
    paddingLeft: 10,
    alignItems: "center",
    backgroundColor: "#1A8A45",
    height: 40,
  },
  shadow: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 5,
  },
  tableHeader: {
    
   
    justifyContent:"center",
    flexDirection:"row"
  },
  headerContainer: {
    width:"50%",backgroundColor:"#1A8A45"
    
  },
  headerText: {
    textAlign:"center",
    fontSize: 22,
    fontWeight: "bold",
    color: "white",
    width:"100%",
  borderBottomLeftRadius:10,
    padding:20,
    
    
  },
  list: {
    height: 50,
    backgroundColor: "white",
  },
  listText: {
    textAlign: "center",
    marginRight: 6,
  },
  dataWrapper: {
    marginTop:15,
    paddingTop:0,
    padding: 15,
    
  },
  emptyMessage: {
    alignSelf: "center",
    justifyContent: "center",
  },
  tableText: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "500",
    
  },
  tableRow: {
    height: 60,
    backgroundColor: "white",
  },
});

export default styles;
