/***************************************************/
// Navigation & Linking Page for EP management
/***************************************************/

import React from 'react';
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import Icon from '../components/ionicon';
import OrderListing from './orderListing';
import QuotationScreen from './quotationScreen';
import ManagementOrderListing from './managementOrderListing';
import NotificationScreen from './notificationScreen';
import ManagementUserScreen from './managementUserScreen';
import CreateUserScreen from './createUserScreen';
import ProjectDevelopmentScreen from './projectDevelopmentScreen';
import ProjectDevelopmentListing from './projectDevelopmentListing';
import ProjectDevelopmentListingByCustomer from './projectDevelopmentListingByCustomer';
import MessageTargetListing from './messageTargetListing';
import MessageScreen from './messageScreen';
import QuotingScreen from './supplierQuotingScreen';
import QuoteListing from './quoteListing';
import ManagementAdminScreen from './managementAdminScreen';
import ManagementFeedScreen from './managementFeedScreen';
import UploadFeedScreen from './uploadFeedScreen';
import FeedScreen from './feedScreen';
import ConsumptionListing from './consumptionListing';
import MessageListing from './messageListing';

//Management Home Screen...Navigation Bar
const OrderStack = createStackNavigator({
  ManagementOrderListing: { screen: ManagementOrderListing },
  OrderListing: { screen: OrderListing },
  QuotationScreen: { screen: QuotationScreen },
  NotificationScreen: {screen: NotificationScreen}
}, {
  headerMode: 'none',
  initialRouteName: 'ManagementOrderListing'
});

const AdminStack = createStackNavigator({
  ManagementAdminScreen: { screen: ManagementAdminScreen },
  ManagementFeedScreen: { screen: ManagementFeedScreen },
  UploadFeedScreen: { screen: UploadFeedScreen },
  FeedScreen: { screen: FeedScreen },
  ManagementUserScreen: { screen: ManagementUserScreen },
  CreateUserScreen: { screen: CreateUserScreen },
  // AllCustomerListing: { screen: AllCustomerListing },
  ConsumptionListing: { screen: ConsumptionListing },
  NotificationScreen: { screen: NotificationScreen },
}, {
  headerMode: 'none'
});

const ProjectDevelopmentStack = createStackNavigator({
  ProjectDevelopmentListing: { screen: ProjectDevelopmentListing },
  ProjectDevelopmentListingByCustomer: { screen: ProjectDevelopmentListingByCustomer },
  ProjectDevelopmentScreen: { screen: ProjectDevelopmentScreen },
  NotificationScreen: { screen: NotificationScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'ProjectDevelopmentListing'
});

const MessageStack = createStackNavigator({
  MessageListing: { screen: MessageListing },
  MessageTargetListing: { screen: MessageTargetListing },
  MessageScreen: { screen: MessageScreen },
  NotificationScreen: { screen: NotificationScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'MessageListing'
});

const QuotingStack = createStackNavigator({
  QuotingListing: { screen: QuoteListing },
  QuotingScreen: { screen: QuotingScreen },
  NotificationScreen: { screen: NotificationScreen }
}, {
  headerMode: 'none'
});


const TabNavigator = createBottomTabNavigator({
  Enquiry: OrderStack,
  'Project Dev': ProjectDevelopmentStack,
  Quoting: QuotingStack,
  Messages: MessageStack,
  Menu: AdminStack,
}, {
  defaultNavigationOptions: ({ screenProps, navigation }) => ({
    tabBarIcon: ({ focused, tintColor }) => {
      const routeName: string = navigation.state.routeName;

      let iconName: string = '';
      let badgeCount: number = 0;
      let color: string = tintColor || "#F7FDF5";
      let iconSize: number = 0;

      if (routeName === 'Enquiry') {
        iconName = focused ? "information" : "information-outline";
        iconSize = 26;
      } else if (routeName === 'Quoting') {
        iconName = focused ? "ios-document-text" : "ios-document-text-outline";
        iconSize = 26;
      } else if (routeName === 'Menu') {
        iconSize = 28;
        iconName = focused ? "menu" : "menu-outline";
        
      } else if (routeName === 'Messages') {
        iconName = focused
          ? "md-chatbox-ellipses"
          : "md-chatbox-ellipses-outline";
        iconSize = 26;
        badgeCount = screenProps.unreadCount;
      } else if (routeName === 'Project Dev') {
        iconName = focused ? "md-briefcase" : "md-briefcase-outline";
        iconSize = 26;
      } 

      return <Icon name={iconName} color={color} badgeCount={badgeCount} size={iconSize}/>;
    }
  }),
  tabBarOptions: {
    activeTintColor: "#0E6B35",
    inactiveTintColor: "#8fad86",
    keyboardHidesTabBar: false,
    
  }
});

export default createAppContainer(TabNavigator);