import React, { Component } from 'react';
import { Platform } from 'react-native';
import AnimatedLoader from 'react-native-animated-loader';
import styles from './loader.styles';

interface CustomInputProps {
  isLoading: boolean;
}

class Loader extends Component<CustomInputProps> {
  render() {
    return (
      Platform.OS !== 'web' && <AnimatedLoader
        visible={this.props.isLoading}
        overlayColor='rgba(0,0,0,0.85)'
        source={require("./loader.json")}
        animationStyle={styles.loader}
        speed={1}>
      </AnimatedLoader>
    );
  }
}

export default Loader;