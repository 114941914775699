import React, { Component } from 'react';
import { View, Image, TextInput, Platform, Alert, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { ScrollView } from 'react-native-gesture-handler';
import * as ImagePicker from 'expo-image-picker';
import moment from 'moment';
import styles from '../styles/uploadFeedScreen.styles';
import Header from '../components/header';
import Loader from '../components/loader';
import * as feedService from '../services/feedService';
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { LinearGradient } from "expo-linear-gradient";
import mime from 'mime';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  feedCaption: string;
  photo: any;
  isLoading: boolean;
  createdBy: string;
  hideComponent: boolean;
}

class UploadFeedScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      feedCaption: '',
      photo: null,
      isLoading: false,
      createdBy: '',
      hideComponent: true
    }

    this.handleChoosePhoto = this.handleChoosePhoto.bind(this);
    this.uploadFeed = this.uploadFeed.bind(this);
  }

  async componentDidMount() {
    if (this.props.screenProps.role === 'management') {

      if (this.props.screenProps.department === 'all') {
        this.setState({ createdBy: 'Management All' });
      }
      else if (this.props.screenProps.department === 'chemicals') {
        this.setState({ createdBy: 'Management Chemicals' });
      }
      else if (this.props.screenProps.department === 'welding') {
        this.setState({ createdBy: 'Management Welding' });
      }
      else if (this.props.screenProps.department === 'lighting') {
        this.setState({ createdBy: 'Management Lighting' });
      } else if (this.props.screenProps.department === 'super') {
        this.setState({ createdBy: 'Management Super' });
      }
    }
  }

  async handleChoosePhoto() {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      // base64: true,
      aspect: [4, 3],
      quality: 0.1,
    });

    if (!result.cancelled) {
      this.setState({ photo: result, hideComponent: false });
    }
    console.log(this.state.photo);
  }

  async uploadFeed() {
    try {
      let proceed: string = await this.onConfirm();

      if (proceed !== 'proceed') {
        return;
      }
      this.setState({ isLoading: true });
      let photo: any = {};
      if(this.state.photo === null){
        alert("Please select a photo!");
      }
      else{
        if (Platform.OS === 'web') {
          const byteString: string = atob(this.state.photo.uri.split(',')[1]);
          const ab: any = new ArrayBuffer(byteString.length);
          const arr: any = new Uint8Array(ab);

          for (let i = 0; i < byteString.length; i++) {
            arr[i] = byteString.charCodeAt(i);
          }
          const blob: any = new Blob([arr], { type: 'image/jpeg' });
          photo = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
        } else {
          const uriParts = this.state.photo.uri.split('.');
          const fileType = uriParts[uriParts.length - 1];
          const imageURI = Platform.OS === "android" ? this.state.photo.uri : this.state.photo.uri.replace("file://", "")
          photo = {
            uri: imageURI,
            type: mime.getType(imageURI),
            name: `photo.${fileType}`
          };
        }
        let body: any = new FormData();
        body.append('caption', this.state.feedCaption);
        body.append('photo', photo);
        body.append('uploadDate', moment().format());
        body.append('createdBy', this.state.createdBy);
        await feedService.postFeed(this.props.screenProps.token, body);
      }
    } catch(error) {
      console.log("Error fetching remote HTML: ", error);
    }   
    //Added code below to stop the animation and navigate to Feed Screen
    this.setState({ isLoading: false })
    this.props.navigation.navigate('FeedScreen');
    
  }

  onConfirm() {
    return new Promise((resolve, reject) => {
      if (Platform.OS === 'web') {
        let res: any = window.confirm(`Confirm To Proceed?`);

        if (res) {
          resolve('proceed');
        } else {
          resolve('cancel');
        }
      } else {
        Alert.alert('Confirm To Proceed?', '', [
          { text: 'Cancel', onPress: () => resolve('cancel'), style: 'cancel' },
          { text: 'OK', onPress: () => resolve('proceed') }
        ], { cancelable: false });
      }
    });
  }

  render() {
    // const placeholder = {
    //   label: 'Select your role...',
    //   value: null,
    //   color: '#9EA0A4',
    // };
    return (
      <View style={styles.feedContainer}>
        <Header headerText='Upload Feed' onLogout={this.props.screenProps.onLogout} />
        {/* <BackBar onPress={() => this.props.navigation.navigate('ManagementFeedScreen')} /> */}
        
        <ScrollView>

          <View style={[styles.ctn, styles.shadow]} >
            {this.state.hideComponent ? (
              <TouchableOpacity style={[styles.photoIcon,]} onPress={this.handleChoosePhoto}>
              <MaterialIcons name="add-a-photo" size={60} />
            </TouchableOpacity>
            ): this.state.photo && (
              <TouchableOpacity style={[styles.photoIcon,]} onPress={this.handleChoosePhoto}>
              <Image
                source={{ uri: this.state.photo.uri }}
                style={{ width: 280, height: 210 }}
              />
              </TouchableOpacity>
            )}
             
            <TextInput
              multiline
              scrollEnabled
              maxLength={500}
              numberOfLines={Platform.OS === "ios" ? null : 4}
              style={[styles.textinput, styles.shadow, {minHeight: 200, maxHeight: 200}]}
              onChangeText={(text) => this.setState({ feedCaption: text })}
              value={this.state.feedCaption} placeholder={'Write a caption for your feed here'}
            />

            <TouchableOpacity style={[styles.btn, styles.shadow]} onPress={this.uploadFeed} >
              <LinearGradient
                start={{ x: 1, y: 0 }}
                end={{ x: -1, y: 0 }}
                colors={["#6DD34D", "#2f883C", "#0E6B35"]}
                style={[styles.btn]}
              >
                <Text style={styles.text}>
                  Upload Feed
                </Text>
              </LinearGradient>
            </TouchableOpacity>
            


          </View>
        </ScrollView>

        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default UploadFeedScreen;