import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import styles from './rowMessage.styles';
import Icon from './icon';
import Row from './row';

interface CustomInputProps {
    key: number;
    title: string;
    content: string;
    date: string;
    unread: number;
    id: string;
    onPress: Function;
}

class RowMessage extends Component<CustomInputProps> {
    onPress() {
        this.props.onPress();
        console.log(this.props.key);
    }

    render() {
        return (
            <TouchableOpacity style={styles.row} onPress={this.onPress.bind(this)}>
                <View style={ this.props.unread  ? styles.container1 : styles.container2}>
                    <View style={styles.rowTitleIcon}>
                        {this.props.unread !== 0 && <Icon name={'user'} color={'black'} badgeCount={this.props.unread} />}
                    </View>
                    <View style={styles.rowTextContainer}>
                        <Text style={styles.rowTitleText}>{this.props.title}</Text>
                        <Text numberOfLines={5} ellipsizeMode='tail' style={styles.rowContentText}>{this.props.content}</Text>
                        {this.props.date !== '' && <View style={styles.rowDate}>
                            <Text style={styles.rowDateText}>{this.props.date}</Text>
                        </View>}
                    </View>

                </View>
            </TouchableOpacity>
        );
       
    }
}

export default RowMessage;