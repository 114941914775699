import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
    width: 150,
    height: 70,
    margin:10,
    borderWidth: 1,
    borderColor: '#EBE9E9',
    justifyContent: "center",
    
    
    borderRadius:30,
  },
  cardActive: {
    alignItems: "center",
    backgroundColor: "#0E6B35",
    borderColor: "#0E6B35",
    borderRadius: 30,
    borderWidth: 1,
    height: 45,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
  },
  cardTitle: {
  },
  cardTitleText: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardContent: {
    paddingLeft: 10
  },
  ContentText: {
    fontSize: 14,
    textAlign: "center"
  },
  cardproductAction: {
    width: 90,
    paddingTop: 35,
    alignSelf: 'center'
  }
});

export default styles;