import React, { Component } from 'react';
import { View,Text } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/salesAboutMeScreen.styles';
import Header from '../components/header';
import { getUserById } from '../services/userService';
import FontAwesome from "react-native-vector-icons/FontAwesome";

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  department: string;
  salesMonthly: string;
  salesYearly: string;
}

class SalesAboutMeScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      department: '',
      salesMonthly: '',
      salesYearly: ''
    }
  }

  async componentDidMount() {
    let getSalesResponse: any = await getUserById(this.props.screenProps.token, this.props.screenProps.userId);

    if (getSalesResponse) {
      this.setState({
        department: getSalesResponse.department,
        salesMonthly: getSalesResponse.salesMonthly,
        salesYearly: getSalesResponse.salesYearly,
      });
    }
  }

  render() {
    return (
      <View style={styles.aboutMeContainer}>
        <Header headerText='About Me' onBack={() => this.props.navigation.goBack()} back={true} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti} />
        {/* <Table>
          <Row data={['Department', this.state.department]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
          <Row data={['Sales Monthly', this.state.salesMonthly]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
          <Row data={['Sales Yearly', this.state.salesYearly]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
        </Table> */}
        <View style={[styles.box,styles.shadow]}>
        <Text style={[styles.title1]}>Department</Text>

      <View style={[styles.ctn, styles.shadow]}>
        <View style={[styles.icon]}>
          <FontAwesome name="building-o" size={50} color="#374957" />
        </View>
        <View style={[styles.text, styles.shadow]}>
          <Text style={[styles.textInside]}>{this.state.department}</Text>
        </View>
      </View>

      <Text style={[styles.title2]}>Sales Monthly</Text>
      <View style={[styles.ctn, styles.shadow]}>
        <View style={[styles.icon]}>
          <FontAwesome name="dollar" size={50} color="#374957" />
        </View>
        <View style={[styles.text, styles.shadow]}>
          <Text style={[styles.textInside]}>{this.state.salesMonthly}</Text>
        </View>
      </View>

      <Text style={[styles.title2]}>Sales Yearly</Text>
      <View style={[styles.ctn, styles.shadow]}>
        <View
          style={[styles.icon]}
        >
          <FontAwesome name="dollar" size={50} color="#374957" />
        </View>
        <View style={[styles.text, styles.shadow]}>
          <Text style={[styles.textInside]}>{this.state.salesYearly}</Text>
        </View>
      </View>
      </View>
      </View>
    );
  }
}

export default SalesAboutMeScreen;
