import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  cardList: {
    flex: 1,
    flexWrap: 'wrap',
    padding:15,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
  },
  item: {

    padding: 20,
    borderBottomColor: "#E2E2E2",
    borderBottomWidth: 2
  },
  header: {
    fontSize: 32,
    backgroundColor: "#A0CDAA",
    padding: 5,
    paddingLeft: 10
  },
  title: {
    fontSize: 24,
  },
});

export default styles;