import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Platform, Alert } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { ScrollView } from 'react-native-gesture-handler';
import { cos } from 'react-native-reanimated';
import styles from '../styles/productListing.styles';
import Header from '../components/header';
import Product from '../components/productList';
import EnquiryForm from '../components/EnquiryForm';
import moment from 'moment';
import Icon from "react-native-vector-icons/Ionicons";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import * as OrderService from '../services/orderService';
import * as ProductService from '../services/productService';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  productList: Array<any>;
  filter: Array<any>;
  optionDisplayed: Array<any>;
  mode: string;
  formInput: Array<any>;
  brand: string;
  material: string;
  subMaterial: string;
  grade: string;
  colour: string;
  other: string;
  quantity: number;
  deliveryDate: string;
  deliveryDay: string;
  deliveryMonth: string;
  deliveryYear: string;
  haveError: boolean;
  errorMessage: string;
  isLoading: boolean;
  freeTextInput: boolean;
  datePickerVisible: boolean;
  selectedDate: string;
  active: Number;
}

class ProductListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      productList: [],
      filter: [],
      optionDisplayed: [],
      mode: 'category',
      formInput: [],
      brand: '',
      material: '',
      subMaterial: '',
      grade: '',
      colour: '',
      other: '',
      quantity: 0,
      deliveryDate: '',
      deliveryDay: '',
      deliveryMonth: '',
      deliveryYear: '',
      haveError: false,
      errorMessage: '',
      isLoading: false,
      freeTextInput: false,
      datePickerVisible: false,
      selectedDate: '',
      active: 0
    }

    this.onSelect = this.onSelect.bind(this);
    this.onBack = this.onBack.bind(this);
    this.regenerateList = this.regenerateList.bind(this);
    this.switchMode = this.switchMode.bind(this);
    this.generateForm = this.generateForm.bind(this);
    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.onChangeMaterial = this.onChangeMaterial.bind(this);
    this.onChangeSubMaterial = this.onChangeSubMaterial.bind(this);
    this.onChangeGrade = this.onChangeGrade.bind(this);
    this.onChangeColour = this.onChangeColour.bind(this);
    this.onChangeOther = this.onChangeOther.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onChangeDeliveryDate = this.onChangeDeliveryDate.bind(this);
    this.showDatePicker = this.showDatePicker.bind(this)
    this.showDatePicker = this.showDatePicker.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.onConfirm = this.onConfirm.bind(this);
  }

  async componentDidMount() {
    let productListingResponse: any = [];
    productListingResponse = await ProductService.getProductByStatus(this.props.screenProps.token, 'active');

    if (this.props.navigation.getParam('mode', '') === 'brand') {
      this.setState({ mode: 'brand' });
    }

    if (productListingResponse) {
      let productListing: Array<any> = productListingResponse.map(a => Object.assign({}, a));
      this.setState({ productList: productListing }, () => this.regenerateList());
    }

  }

  onSelect(key: string, value: string) {

    let filter: Array<any> = this.state.filter.map(a => Object.assign({}, a));
    filter.push({ key: key, value: value });
    this.setState({ filter: filter }, () => this.regenerateList());
  }

  onBack() {
    let filter: Array<any> = this.state.filter.map(a => Object.assign({}, a));
    filter.pop();
    this.setState({ filter: filter }, () => this.regenerateList());
  }

  regenerateList() {
    let option: Array<string>;
    let key: string;
    let productListing: Array<any> = this.state.productList.map(a => Object.assign({}, a));
    let filter: Array<any> = this.state.filter.map(a => Object.assign({}, a));

    filter.map((item, index) => {
      productListing = productListing.filter(function (product: any) {
        return product[item.key] === item.value;
      });
    });

    if (filter.length === 0) {
      if (this.state.mode === 'brand') {
        option = productListing.map(item => item.brand).filter((value, index, self) => self.indexOf(value) === index);
        key = 'brand';
      } else {
        option = productListing.map(item => item.category).filter((value, index, self) => self.indexOf(value) === index);
        key = 'category';
      }
    } else {
      let lastFilter: string = filter[filter.length - 1].key;


      if (lastFilter === 'category' && this.state.mode === 'category' || lastFilter === 'category' && this.state.mode === 'brand') {
        option = productListing.map(item => item.subCategory).filter((value, index, self) => self.indexOf(value) === index);
        key = 'subCategory';
      }
      else if (lastFilter === 'subCategory' && this.state.mode === 'category' || lastFilter === 'category' && this.state.mode === 'brand') {
        option = productListing.map(item => item.material).filter((value, index, self) => self.indexOf(value) === index);
        key = 'material';
      }
      else if (lastFilter === 'material' && this.state.mode === 'category') {
        let params: any = {};
        filter.map((item, index) => { params[item.key] = item.value });

        this.setState({ filter: [] });
        this.setState({ material: params.material }, this.generateForm);
        return;
      }
      else if (lastFilter === 'brand' && this.state.mode === 'brand') {
        option = productListing.map(item => item.material).filter((value, index, self) => self.indexOf(value) === index);
        let params: any = {};
        filter.map((item, index) => { params[item.key] = item.value });
        this.setState({ filter: [] });

        this.setState({ brand: params.brand }, this.generateForm);
        console.log('option: ', option);
        console.log('brnad');
        return;
      }

    }

    option.sort();

    let optionDisplayed: Array<any> = [];
    option.map((item: any, index: any) => (
      optionDisplayed.push({
        'title': item,
        'content': '',
        'buttonText': 'SELECT',
        'buttonOnPress': () => this.onSelect(key, item),
        'active': 0
      })
    ));
    this.setState({ optionDisplayed: optionDisplayed });
  }

  switchMode(mode: string) {
    this.setState({ mode: mode, filter: [] }, () => this.regenerateList());
  }


  onConfirm() {
    if (Platform.OS === 'web') {
      let res: any = window.confirm(`Confirm To Proceed?`);
      
      if (res) {
        this.onPurchase();
      } 
    } else {
      Alert.alert('Confirm To Proceed?', '', [ 
        { text: 'Cancel', onPress: () => {}, style: 'cancel' }, 
        { text: 'OK', onPress: () => this.onPurchase() } 
      ], { cancelable: false } );
    }  
  }
  generateForm() {
    let formInput: Array<any> = [];
    formInput.push({ placeholder: 'Customer', onChangeText: () => { }, value: this.props.screenProps.companyId, disabled: true });
    if (this.state.freeTextInput) {
      formInput.push(
        { placeholder: 'Brand', onChangeText: this.onChangeBrand, value: this.state.brand },
        { placeholder: 'Sub Material', onChangeText: this.onChangeSubMaterial, value: this.state.subMaterial },
        { placeholder: 'Grade', onChangeText: this.onChangeGrade, value: this.state.grade },
        { placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour },

      );
    } else {
      let productListing: Array<any> = this.state.productList.map(a => Object.assign({}, a));
      let brandOption: Array<string> = productListing.map(item => item.brand).filter((value, index, self) => self.indexOf(value) === index);
      let materialOption: Array<string> = productListing.map(item => item.material).filter((value, index, self) => self.indexOf(value) === index);
      let subMaterialOption: Array<string> = [];
      let gradeOption: Array<string> = [];
      let colourOption: Array<string> = ['Natural', 'Black', 'White', 'Other'];


      if (this.state.mode === 'category') {
        materialOption.sort();
        if (this.state.material) {
          let materialSelected: string = this.state.material;
          productListing = productListing.filter(function (product: any) {
            return product['material'] === materialSelected;
          });

          brandOption = productListing.map(item => item.brand).filter((value, index, self) => self.indexOf(value) === index);
          brandOption.sort();
          console.log('iam brand: ', brandOption);

          if (this.state.brand) {
            let brandSelected: string = this.state.brand;
            productListing = productListing.filter(function (product: any) {
              return product['brand'] === brandSelected;
            });

            subMaterialOption = productListing.map(item => item.subMaterial).filter((value, index, self) => self.indexOf(value) === index);
            subMaterialOption.sort();

            if (this.state.subMaterial) {
              let subMaterialSelected: string = this.state.subMaterial;
              productListing = productListing.filter(function (product: any) {
                return product['subMaterial'] === subMaterialSelected;
              });

              gradeOption = productListing.map(item => item.grade).filter((value, index, self) => self.indexOf(value) === index);
              gradeOption.sort();

            }
          }
        }
        formInput.push(
          { placeholder: 'Brand', onChangeText: this.onChangeBrand, value: this.state.brand, option: brandOption, inputType: 'picker' },
          { placeholder: 'Material', onChangeText: () => { this.onChangeMaterial }, value: this.state.material, disabled: true },
          { placeholder: 'Sub Material', onChangeText: this.onChangeSubMaterial, value: this.state.subMaterial, option: subMaterialOption, inputType: 'picker' },
          { placeholder: 'Grade', onChangeText: this.onChangeGrade, value: this.state.grade, option: gradeOption, inputType: 'picker' }
        );
      }
      else if (this.state.mode === 'brand') {
        brandOption.sort();
        if (this.state.brand) {
          let brandSelected: string = this.state.brand;
          productListing = productListing.filter(function (product: any) {
            return product['brand'] === brandSelected;
          });

          materialOption = productListing.map(item => item.material).filter((value, index, self) => self.indexOf(value) === index);
          materialOption.sort();

          if (this.state.material) {
            let materialSelected: string = this.state.material;
            productListing = productListing.filter(function (product: any) {
              return product['material'] === materialSelected;
            });

            subMaterialOption = productListing.map(item => item.subMaterial).filter((value, index, self) => self.indexOf(value) === index);
            subMaterialOption.sort();

            if (this.state.subMaterial) {
              let subMaterialSelected: string = this.state.subMaterial;
              productListing = productListing.filter(function (product: any) {
                return product['subMaterial'] === subMaterialSelected;
              });

              gradeOption = productListing.map(item => item.grade).filter((value, index, self) => self.indexOf(value) === index);
              gradeOption.sort();
            }
          }
        }
        formInput.push(
          { placeholder: 'Brand', onChangeText: () => { this.onChangeBrand }, value: this.state.brand, option: brandOption, disabled: true },
          { placeholder: 'Material', onChangeText: this.onChangeMaterial, value: this.state.material, option: materialOption, inputType: 'picker' },
          { placeholder: 'Sub Material', onChangeText: this.onChangeSubMaterial, value: this.state.subMaterial, option: subMaterialOption, inputType: 'picker' },
          { placeholder: 'Grade', onChangeText: this.onChangeGrade, value: this.state.grade, option: gradeOption, inputType: 'picker' }
        );

      }


      if (this.state.colour === 'Other') {
        formInput.push(
          { placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour, option: colourOption, inputType: 'picker' },
          { placeholder: 'Other', onChangeText: this.onChangeOther, keyboardType: 'default', value: this.state.other.toString() });
      } else {
        formInput.push({ placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour, option: colourOption, inputType: 'picker' });
      }
      formInput.push({ placeholder: 'Quantity', onChangeText: this.onChangeQuantity, keyboardType: 'number-pad', value: this.state.quantity.toString() });
      formInput.push({ placeholder: 'Delivery Date(DD/MM/YYYY)', onChangeText: this.onChangeDeliveryDate, keyboardType: 'default', value: this.state.deliveryDate });
      console.log('date: ', this.state.deliveryDate)
    }

    this.setState({ formInput: formInput });
  }


  onChangeBrand(selected: string) {
    if (selected === 'Others') {
      this.setState({ brand: "Other", freeTextInput: true }, () => this.generateForm());
    } else {
      this.setState({ brand: selected, freeTextInput: false }, () => this.generateForm());
    }
  }

  onChangeMaterial(selected: string) {
    this.setState({ material: selected }, () => this.generateForm());
  }

  onChangeSubMaterial(selected: string) {
    this.setState({ subMaterial: selected }, () => this.generateForm());
  }

  onChangeGrade(selected: string) {
    this.setState({ grade: selected }, () => this.generateForm());
  }

  onChangeColour(selected: string) {
    this.setState({ colour: selected }, () => this.generateForm());
  }

  onChangeOther(input: string) {
    this.setState({ other: input }, () => this.generateForm());
  }

  onChangeQuantity(input: string) {
    if (input) {
      this.setState({ quantity: parseInt(input.replace(/[^0-9]/g, '')) }, () => this.generateForm());
    } else {
      this.setState({ quantity: 0 }, () => this.generateForm());
    }
  }

  onChangeDeliveryDate(input: string) {
    let newInput: string = input.replace(/[^0-9]/g, '');

    if (newInput.length > 4) {
      newInput = newInput.substring(0, 2) + '/' + newInput.substring(2, 4) + '/' + newInput.substring(4, newInput.length);
    } else if (newInput.length > 3) {
      newInput = newInput.substring(0, 2) + '/' + newInput.substring(2, 4) + '/';
    } else if (newInput.length > 1) {
      newInput = newInput.substring(0, 2) + '/' + newInput.substring(2, newInput.length);
    }

    this.setState({ deliveryDate: newInput }, () => this.generateForm());
  }

  //Below Three codes are needed for datetimepicker
  showDatePicker = () => {
    this.setState({ datePickerVisible: true });
  };

  hideDatePicker = () => {
    this.setState({ datePickerVisible: false });
  };

  handleConfirm = (date) => {
    this.setState({ deliveryDate: moment(date).format("DD/MM/YYYY") }, this.generateForm);
    this.hideDatePicker();
  };

  async onPurchase() {
    if(this.state.other !== '' ) {this.setState({ colour : this.state.other })}
    if (this.state.quantity !== 0 && this.state.brand && this.state.material && this.state.subMaterial && this.state.grade && this.state.colour && this.state.deliveryDate) {
	     if(moment(this.state.deliveryDate, 'DD/MM/YYYY', true).isValid()) {
		      this.setState({ isLoading: true });
		      let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
        let department: string = getCompanyResponse[0].department;
		      let success: boolean = await OrderService.postOrder(this.props.screenProps.token, { 'brand': this.state.brand, 'material': this.state.material + ' - ' + this.state.subMaterial, 'materialType': this.state.grade, 'colour': this.state.colour, 'quantity': this.state.quantity, 'deliveryDate': this.state.deliveryDate, 'orderBy': this.props.screenProps.companyId, 'department': department });
		      this.setState({ isLoading: false });
		
		      if (success) {
		        this.props.navigation.navigate('OrderListing', { showIncompleteOnly: true });
		      } else {
		        this.setState({
		          haveError: true,
		          errorMessage: 'An error has occured. Please retry.'
		        });
		      }
      } else {
        this.setState({
		        haveError: true,
		        errorMessage: 'Invalid Date! Please input in format 31/12/2020.'
		      });
          
      }
    } else {
      this.setState({
        haveError: true,
        errorMessage: 'Field can not be empty!' + this.state.brand + this.state.material + this.state.subMaterial + this.state.grade + this.state.colour + this.state.deliveryDate 
      });
    }
  }

  render() {
    return (
      <View style={styles.productContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='All Product' onBack={this.onBack} back={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} notification={true} badgeCount={this.props.screenProps.unreadNoti} />
        {this.state.mode === 'category' && <TouchableOpacity style={styles.modeBar} onPress={() => this.switchMode('brand')}><Text style={styles.modeText}>Switch to Brand Mode</Text></TouchableOpacity>}
        {this.state.mode === 'brand' && <TouchableOpacity style={styles.modeBar} onPress={() => this.switchMode('category')}><Text style={styles.modeText}>Switch to Category Mode</Text></TouchableOpacity>}

        <Product dataList={this.state.optionDisplayed} active={this.state.active} />
        {this.state.mode === 'category' && <Text style={styles.text}>{this.state.material}</Text>}
        {this.state.mode === 'brand' && <Text style={styles.text}>{this.state.brand}</Text>}

        <KeyboardAwareScrollView style={styles.formContainer} scrollEnabled={true} resetScrollToCoords={{ x: 0, y: 0 }}>
          <ScrollView contentContainerStyle={styles.scrollViewContainer}>
            { this.state.formInput.length > 0 &&<EnquiryForm formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Enquire'} buttonOnPress={this.onConfirm} />}
                     

            {/*Calendar */}
            <View style={styles.icon}>


              <Icon name='calendar-outline' size={25} onPress={this.showDatePicker} />
              {/*If the date time picker is not working on IOS, check the version of react-native-community/datetimepicker */}
              <DateTimePickerModal
                isVisible={this.state.datePickerVisible}
                mode="date"
                display={Platform.OS === 'ios' ? 'inline' : 'default'}
                onConfirm={this.handleConfirm}
                onCancel={this.hideDatePicker}
              />
            </View>
          </ScrollView>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

export default ProductListing;