// import React, { Component } from 'react';
// import { View, Alert, Platform } from 'react-native';
// import { NavigationEvents } from 'react-navigation';
// import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
// import moment from 'moment';
// import styles from '../styles/purchaseScreen.styles';
// import Header from '../components/header';
// import Form from '../components/form';
// import Loader from '../components/loader';
// import * as OrderService from '../services/orderService';
// import * as ProductService from '../services/productService';
// import * as CompanyService from '../services/companyService';

// interface CustomInputProps {
//   navigation: NavigationStackProp;
//   screenProps: NavigationStackScreenProps;
// }

// interface CustomInputStates {
//   formInput: Array<any>;
//   brand: string;
//   material: string;
//   subMaterial: string;
//   grade: string;
//   colour: string;
//   other: string;
//   quantity: number;
//   deliveryDate: string;
//   deliveryDay: string;
//   deliveryMonth: string;
//   deliveryYear: string;
//   haveError: boolean;
//   errorMessage: string;
//   isLoading: boolean;
//   freeTextInput: boolean;
//   productList: Array<any>;
// }

// class PurchaseScreen extends Component<CustomInputProps, CustomInputStates> {
//   constructor(props: CustomInputProps) {
//     super(props);

//     this.state = {
//       formInput: [],
//       brand: '',
//       material: '',
//       subMaterial: '',
//       grade: '',
//       colour: '',
//       other: '',
//       quantity: 0,
//       deliveryDate: '',
//       deliveryDay: '',
//       deliveryMonth: '',
//       deliveryYear: '',
//       haveError: false,
//       errorMessage: '',
//       isLoading: false,
//       freeTextInput: false,
//       productList: []
//     }

//     this.onChangeBrand = this.onChangeBrand.bind(this);
//     this.onChangeMaterial = this.onChangeMaterial.bind(this);
//     this.onChangeSubMaterial = this.onChangeSubMaterial.bind(this);
//     this.generateForm = this.generateForm.bind(this);
//     this.onChangeGrade = this.onChangeGrade.bind(this);
//     this.onChangeColour = this.onChangeColour.bind(this);
//     this.onChangeOther = this.onChangeOther.bind(this);
//     this.onChangeQuantity = this.onChangeQuantity.bind(this);
//     this.onChangeDeliveryDate = this.onChangeDeliveryDate.bind(this);
//     // this.onChangeDeliveryDay = this.onChangeDeliveryDay.bind(this);
//     // this.onChangeDeliveryMonth = this.onChangeDeliveryMonth.bind(this);
//     // this.onChangeDeliveryYear = this.onChangeDeliveryYear.bind(this);
//     this.onPurchase = this.onPurchase.bind(this);
//     this.onConfirm = this.onConfirm.bind(this);
//   }

//   async componentDidMount() {
//     let productListingResponse: any = [];
//     let productListing: Array<any> = [];
//     this.setState({ isLoading: true });
//     productListingResponse = await ProductService.getProductByStatus(this.props.screenProps.token, 'active');
//     this.setState({ isLoading: false });
    
//     if (productListingResponse) {
// 		    productListing = productListingResponse.map(a => Object.assign({}, a));
//     } 
  
//     this.setState({
//       brand: this.props.navigation.getParam('brand', ''),
//       material: this.props.navigation.getParam('material', ''),
//       subMaterial: this.props.navigation.getParam('subMaterial', ''),
//       grade: this.props.navigation.getParam('grade', ''),
//       colour: this.props.navigation.getParam('colour', ''),
//       quantity: parseInt(this.props.navigation.getParam('quantity', 0)),
//       deliveryDate: this.props.navigation.getParam('deliveryDate', ''),
//       productList: productListing
//     }, () => { this.generateForm() });
//   }

//   generateForm() {
//     let formInput: Array<any> = [];
    
//     formInput.push({ placeholder: 'Customer', onChangeText: () => {}, value: this.props.screenProps.companyId, disabled: true });
    
//     if (this.state.freeTextInput) { 
//       formInput.push(
//         { placeholder: 'Brand', onChangeText: this.onChangeBrand, value: this.state.brand },
//         { placeholder: 'Material', onChangeText: this.onChangeMaterial, value: this.state.material },
//         { placeholder: 'Sub Material', onChangeText: this.onChangeSubMaterial, value: this.state.subMaterial },
//         { placeholder: 'Grade', onChangeText: this.onChangeGrade, value: this.state.grade },
//         { placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour },
//         { placeholder: 'Quantity', onChangeText: this.onChangeQuantity, keyboardType: 'numeric', value: this.state.quantity.toString() },
//         { placeholder: 'Delivery Date(DD/MM/YYYY)', onChangeText: this.onChangeDeliveryDate, value: this.state.deliveryDate }
//       );
//     } else {
//       let productListing: Array<any> = this.state.productList.map(a => Object.assign({}, a));    
//       let brandOption: Array<string> = productListing.map(item => item.brand).filter((value, index, self) => self.indexOf(value) === index);
//       let materialOption: Array<string> = [];
//       let subMaterialOption: Array<string> = [];
//       let gradeOption: Array<string> = [];
//       let colourOption: Array<string> = ['Natural', 'Black', 'White', 'Other'];
      
//       brandOption.sort();
//       brandOption.push('Others');

//       if (this.state.brand) {
//         let brandSelected: string = this.state.brand;
//         productListing = productListing.filter(function(product: any) {
//           return product['brand'] === brandSelected;
//         });
        
//         materialOption = productListing.map(item => item.material).filter((value, index, self) => self.indexOf(value) === index);
//         materialOption.sort();
        
//         if (this.state.material) {
// 		        let materialSelected: string = this.state.material;
//           productListing = productListing.filter(function(product: any) {
// 		          return product['material'] === materialSelected;
// 		        });
		        
// 		        subMaterialOption = productListing.map(item => item.subMaterial).filter((value, index, self) => self.indexOf(value) === index);
// 		        subMaterialOption.sort();
		        
// 		        if (this.state.subMaterial) {
// 				        let subMaterialSelected: string = this.state.subMaterial;
//            productListing = productListing.filter(function(product: any) {
// 				          return product['subMaterial'] === subMaterialSelected;
// 				        });
				        
// 				        gradeOption = productListing.map(item => item.grade).filter((value, index, self) => self.indexOf(value) === index);
// 				        gradeOption.sort();
// 				      }
// 		      }
//       }
  
//       formInput.push(
//         { placeholder: 'Brand', onChangeText: this.onChangeBrand, value: this.state.brand, option: brandOption, inputType: 'picker' },
//         { placeholder: 'Material', onChangeText: this.onChangeMaterial, value: this.state.material, option: materialOption, inputType: 'picker' },
//         { placeholder: 'Sub Material', onChangeText: this.onChangeSubMaterial, value: this.state.subMaterial, option: subMaterialOption, inputType: 'picker' },
//         { placeholder: 'Grade', onChangeText: this.onChangeGrade, value: this.state.grade, option: gradeOption, inputType: 'picker' }
//       );  

//       if (this.state.colour === 'Other') {
//         formInput.push(
//           { placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour, option: colourOption, inputType: 'picker' },
//           { placeholder: 'Other', onChangeText: this.onChangeOther, keyboardType: 'default', value: this.state.other.toString() });
//       } else {
//         formInput.push({ placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour, option: colourOption, inputType: 'picker' });
//       }

//       formInput.push({ placeholder: 'Quantity', onChangeText: this.onChangeQuantity, keyboardType: 'number-pad', value: this.state.quantity.toString() });
//       formInput.push({ placeholder: 'Delivery Date(DD/MM/YYYY)', onChangeText: this.onChangeDeliveryDate, keyboardType: 'default', value: this.state.deliveryDate });
//     }

//     this.setState({ formInput: formInput });
//   }

//   onChangeBrand(selected: string) {
//     if (selected === 'Others') {
//       this.setState({ brand: "Other", freeTextInput: true }, () => this.generateForm());
//     }  else {
//       this.setState({ brand: selected, material: '', subMaterial: '', grade: '', freeTextInput: false }, () => this.generateForm());
//     }
//   }

//   onChangeMaterial(selected: string) {
//     this.setState({ material: selected, subMaterial: '', grade: '' }, () => this.generateForm());
//   }
  
//   onChangeSubMaterial(selected: string) {
//     this.setState({ subMaterial: selected, grade: '' }, () => this.generateForm());
//   }

//   onChangeGrade(selected: string) {
//     this.setState({ grade: selected }, () => this.generateForm());
//   }

//   onChangeColour(selected: string) {
//     this.setState({ colour: selected }, () => this.generateForm());
//   }

//   onChangeOther(input: string) {
//     this.setState({ other: input }, () => this.generateForm());
//   }

//   onChangeQuantity(input: string) {
//     if (input) {
//       this.setState({ quantity: parseInt(input.replace(/[^0-9]/g, '')) }, () => this.generateForm());
//     } else {
//       this.setState({ quantity: 0 }, () => this.generateForm());
//     }
//   }

//   onChangeDeliveryDate(input: string) {
//     let newInput: string = input.replace(/[^0-9]/g, '');
    
//     if (newInput.length > 4) {
//       newInput = newInput.substring(0,2) + '/' + newInput.substring(2,4) + '/' + newInput.substring(4,newInput.length);
//     } else if (newInput.length > 3) {
//       newInput = newInput.substring(0,2) + '/' + newInput.substring(2,4) + '/';
//     } else if (newInput.length > 1) {
//       newInput = newInput.substring(0,2) + '/' + newInput.substring(2,newInput.length);
//     } 
    
//     this.setState({ deliveryDate: newInput }, () => this.generateForm());
//   }
//   // onChangeDeliveryDay(input: string) {
//   //   this.setState({ deliveryDay: input }, () => this.generateForm());
//   // }
//   // onChangeDeliveryMonth(input: string) {
//   //   this.setState({ deliveryMonth: input }, () => this.generateForm());
//   // }
//   // onChangeDeliveryYear(input: string) {
//   //   this.setState({ deliveryYear: input }, () => this.generateForm());
//   // }

//   async onPurchase() {
//     if(this.state.other !== '' ) {this.setState({ colour : this.state.other })}
//     if (this.state.quantity !== 0 && this.state.brand && this.state.material && this.state.subMaterial && this.state.grade && this.state.colour && this.state.deliveryDate) {
// 	     if(moment(this.state.deliveryDate, 'DD/MM/YYYY', true).isValid()) {
// 		      this.setState({ isLoading: true });
// 		      let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
//         let department: string = getCompanyResponse[0].department;
// 		      let success: boolean = await OrderService.postOrder(this.props.screenProps.token, { 'brand': this.state.brand, 'material': this.state.material + ' - ' + this.state.subMaterial, 'materialType': this.state.grade, 'colour': this.state.colour, 'quantity': this.state.quantity, 'deliveryDate': this.state.deliveryDate, 'orderBy': this.props.screenProps.companyId, 'department': department });
// 		      this.setState({ isLoading: false });
		
// 		      if (success) {
// 		        this.props.navigation.navigate('OrderListing', { showIncompleteOnly: true });
// 		      } else {
// 		        this.setState({
// 		          haveError: true,
// 		          errorMessage: 'An error has occured. Please retry.'
// 		        });
// 		      }
//       } else {
//         this.setState({
// 		        haveError: true,
// 		        errorMessage: 'Invalid Date! Please input in format 31/12/2020.'
// 		      });
//       }
//     } else {
//       this.setState({
//         haveError: true,
//         errorMessage: 'Field can not be empty!' + this.state.brand + this.state.material + this.state.subMaterial + this.state.grade + this.state.colour + this.state.deliveryDate 
//       });
//     }
//   }
  
//   onConfirm() {
//     if (Platform.OS === 'web') {
//       let res: any = window.confirm(`Confirm To Proceed?`);
      
//       if (res) {
//         this.onPurchase();
//       } 
//     } else {
//       Alert.alert('Confirm To Proceed?', '', [ 
//         { text: 'Cancel', onPress: () => {}, style: 'cancel' }, 
//         { text: 'OK', onPress: () => this.onPurchase() } 
//       ], { cancelable: false } );
//     }  
//   }

//   render() {
//     return (
//       <View style={styles.purchaseContainer}>
//         <NavigationEvents onDidFocus={() => this.componentDidMount()} />
//         <Header headerText='Enquiry' onBack={() => this.props.navigation.goBack()} back={false} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} />
//         <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Enquire'} buttonOnPress={this.onConfirm} />
//         <Loader isLoading={this.state.isLoading} />
//       </View>
//     );
//   }
// }

import React, { useState, useEffect } from 'react';
import { View, Alert, Platform,  } from 'react-native';
import moment from 'moment';
import styles from '../styles/purchaseScreen.styles';
import Header from '../components/header';
import EnquiryForm from '../components/EnquiryForm';
import Loader from '../components/loader';
import * as OrderService from '../services/orderService';
import * as ProductService from '../services/productService';
import * as CompanyService from '../services/companyService';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import Icon from "react-native-vector-icons/Ionicons";
import { ScrollView } from 'react-native-gesture-handler';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const PurchaseScreen = (props) => {
  const [formInput, setFormInput] = useState([] as any);
  const [brand, setBrand] = useState('');
  const [material, setMaterial] = useState('');
  const [subMaterial, setSubMaterial] = useState('');
  const [grade, setGrade] = useState('');
  const [colour, setColor] = useState('');
  const [other, setOther] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryDay, setDeliveryDay] = useState('');
  const [deliveryMonth, setDeliveryMonth] = useState('');
  const [deliveryYear, setDeliveryYear] = useState('');
  const [haveError, setHaveError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [freeTextInput, setFreeTextInput] = useState(false);
  const [productList, setProductList] = useState([] as any);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState();

  //this useEffect is used to get the list of products from database 
  useEffect(() => {
    getProductListingResponse();
  }, [])

  // this useEffect is used to handle the input form
  useEffect(() => {
    generateForm();
  }, [brand, material, subMaterial, grade, colour, quantity, deliveryDate, productList])

  const getProductListingResponse = async () => {
    let productListingResponse: any = [];
    let productListing: Array<any> = [];
    setIsLoading(true)
    productListingResponse = await ProductService.getProductByStatus(props.screenProps.token, 'active');
    setIsLoading(false)

    if (productListingResponse) {
      productListing = productListingResponse.map(a => Object.assign({}, a));
    }

    setBrand(props.navigation.getParam('brand', ''))
    setMaterial(props.navigation.getParam('material', ''))
    setSubMaterial(props.navigation.getParam('subMaterial', ''))
    setGrade(props.navigation.getParam('grade', ''))
    setColor(props.navigation.getParam('colour', ''))
    setQuantity(parseInt(props.navigation.getParam('quantity', 0)))
    setDeliveryDate(props.navigation.getParam('deliveryDate', ''))
    setProductList(productListing)
    generateForm()
  }

  function generateForm() {
    let formInput: Array<any> = [];

    formInput.push({ placeholder: 'Customer', onChangeText: () => { }, value: props.screenProps.companyId, disabled: true });

    if (freeTextInput) {
      formInput.push(
        { placeholder: 'Brand', onChangeText: onChangeBrand, value: brand },
        { placeholder: 'Material', onChangeText: setMaterial, value: material },
        { placeholder: 'Sub Material', onChangeText: setSubMaterial, value: subMaterial },
        { placeholder: 'Grade', onChangeText: setGrade, value: grade },
        { placeholder: 'Colour', onChangeText: setColor, value: colour },
        { placeholder: 'Quantity', onChangeText: onChangeQuantity, keyboardType: 'numeric', value: quantity.toString() },
        { placeholder: 'Delivery Date(DD/MM/YYYY)', onChangeText: onChangeDeliveryDate, value: deliveryDate }
      );
    } else {
      let productListing: Array<any> = productList.map(a => Object.assign({}, a));
      let brandOption: Array<string> = productListing.map(item => item.brand).filter((value, index, self) => self.indexOf(value) === index);
      let materialOption: Array<string> = [];
      let subMaterialOption: Array<string> = [];
      let gradeOption: Array<string> = [];
      let colourOption: Array<string> = ['Natural', 'Black', 'White', 'Other'];

      brandOption.sort();
      brandOption.push('Others');

      if (brand) {
        let brandSelected: string = brand;
        productListing = productListing.filter(function (product: any) {
          return product['brand'] === brandSelected;
        });

        materialOption = productListing.map(item => item.material).filter((value, index, self) => self.indexOf(value) === index);
        materialOption.sort();

        if (material) {
          let materialSelected: string = material;
          productListing = productListing.filter(function (product: any) {
            return product['material'] === materialSelected;
          });

          subMaterialOption = productListing.map(item => item.subMaterial).filter((value, index, self) => self.indexOf(value) === index);
          subMaterialOption.sort();

          if (subMaterial) {
            let subMaterialSelected: string = subMaterial;
            productListing = productListing.filter(function (product: any) {
              return product['subMaterial'] === subMaterialSelected;
            });

            gradeOption = productListing.map(item => item.grade).filter((value, index, self) => self.indexOf(value) === index);
            gradeOption.sort();
          }
        }
      }

      formInput.push(
        { placeholder: 'Brand', onChangeText: onChangeBrand, value: brand, option: brandOption, inputType: 'picker' },
        { placeholder: 'Material', onChangeText: setMaterial, value: material, option: materialOption, inputType: 'picker' },
        { placeholder: 'Sub Material', onChangeText: setSubMaterial, value: subMaterial, option: subMaterialOption, inputType: 'picker' },
        { placeholder: 'Grade', onChangeText: setGrade, value: grade, option: gradeOption, inputType: 'picker' }
      );

      if (colour === 'Other') {
        formInput.push(
          { placeholder: 'Colour', onChangeText: setColor, value: colour, option: colourOption, inputType: 'picker' },
          { placeholder: 'Other', onChangeText: setOther, keyboardType: 'default', value: other.toString() });
      } else {
        formInput.push({ placeholder: 'Colour', onChangeText: setColor, value: colour, option: colourOption, inputType: 'picker' });
      }

      formInput.push({ placeholder: 'Quantity', onChangeText: onChangeQuantity, keyboardType: 'number-pad', value: quantity.toString() });
      formInput.push({ placeholder: 'Delivery Date(DD/MM/YYYY)', onChangeText: onChangeDeliveryDate, keyboardType: 'default', value: deliveryDate });
    }

    setFormInput(formInput);
  }

  //funciton

  function onChangeBrand(selected: string) {
    if (selected === 'Others') {
      setBrand("Other");
      setFreeTextInput(true);
    }
    else {
      setBrand(selected);
      setFreeTextInput(false);
    }

  }

  // function below only allows numberic number 
  function onChangeQuantity(input: string) {
    const re = /[^0-9]/g;
    if (input !== '' || re.test(input)) {
      setQuantity(parseInt(input));
    } else {
      setQuantity(0);
    }
  }

  function onChangeDeliveryDate(input: string) {
    let newInput: string = input.replace(/[^0-9]/g, '');

    if (newInput.length > 4) {
      newInput = newInput.substring(0, 2) + '/' + newInput.substring(2, 4) + '/' + newInput.substring(4, newInput.length);
    } else if (newInput.length > 3) {
      newInput = newInput.substring(0, 2) + '/' + newInput.substring(2, 4) + '/';
    } else if (newInput.length > 1) {
      newInput = newInput.substring(0, 2) + '/' + newInput.substring(2, newInput.length);
    }

    setDeliveryDate(newInput);
  }
  // onChangeDeliveryDay(input: string) {
  //   setState({ deliveryDay: input }, () => generateForm());
  // }
  // onChangeDeliveryMonth(input: string) {
  //   setState({ deliveryMonth: input }, () => generateForm());
  // }
  // onChangeDeliveryYear(input: string) {
  //   setState({ deliveryYear: input }, () => generateForm());
  // }

  const onPurchase = async () => {
    if (other !== '') { setColor(other) }
    if (quantity !== 0 && brand && material && subMaterial && grade && colour && deliveryDate) {
      if (moment(deliveryDate, 'DD/MM/YYYY', true).isValid()) {
        setIsLoading(true);
        let getCompanyResponse: any = await CompanyService.getCompany(props.screenProps.token, props.screenProps.companyId);
        let department: string = getCompanyResponse[0].department;
        let success: boolean = await OrderService.postOrder(props.screenProps.token, { 'brand': brand, 'material': material + ' - ' + subMaterial, 'materialType': grade, 'colour': colour, 'quantity': quantity, 'deliveryDate': deliveryDate, 'orderBy': props.screenProps.companyId, 'department': department });
        setIsLoading(false);

        if (success) {
          props.navigation.navigate('OrderListing', { showIncompleteOnly: true });
          resetToNormal();
        } else {

          setHaveError(true);
          setErrorMessage('An error has occured. Please retry.')
        }
      } else {

        setHaveError(true);
        setErrorMessage('Invalid Date! Please input in format 31/12/2020.')
      }


    } else {
      setHaveError(true);
      setErrorMessage('Field can not be empty!' + brand + material + subMaterial + grade + colour + deliveryDate);
    }
  }

  function onConfirm() {
    if (Platform.OS === 'web') {
      let res: any = window.confirm(`Confirm To Proceed?`);

      if (res) {
        onPurchase();
      }
    } else {
      Alert.alert('Confirm To Proceed?', '', [
        { text: 'Cancel', onPress: () => { }, style: 'cancel' },
        { text: 'OK', onPress: () => onPurchase() }
      ], { cancelable: false });
    }
  }
  // this function is used to clear the state of each variables after enquire 
  function resetToNormal() {
    setBrand('');
    setMaterial('');
    setSubMaterial('');
    setGrade('');
    setColor('');
    setQuantity(0);
    setDeliveryDate('');
    setErrorMessage('');
    setFreeTextInput(false);
  }

  //Below Three codes are needed for datetimepicker
  const showDatePicker = () => {
    setDatePickerVisible(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisible(false);
  };

  const handleConfirm = (date) => {
    setSelectedDate(date);
    setDeliveryDate(moment(date).format("DD/MM/YYYY"));
    // console.log(date);
    // console.log('HI: ', moment(selectedDate).format("MM/DD/YYYY"));
    hideDatePicker();
  };

  return (
    <View style={styles.purchaseContainer}>

      <Header headerText='Enquiry' onBack={() => props.navigation.goBack()} back={false} notification={true} onPress={() => props.navigation.navigate('NotificationScreen')} badgeCount={props.screenProps.unreadNoti}/>
      <KeyboardAwareScrollView style={styles.formContainer} scrollEnabled={true} resetScrollToCoords={{ x: 0, y: 0 }}>
      <ScrollView contentContainerStyle={styles.scrollViewContainer}>
      <EnquiryForm formInput={formInput} haveError={haveError} errorMessage={errorMessage} buttonText={'Enquire'} buttonOnPress={onConfirm} />
      
      {/*Calendar */}
      <View style={styles.icon}>

       
       <Icon name='calendar-outline' size={25} onPress={showDatePicker}/>
       {/*If the date time picker is not working on IOS, check the version of react-native-community/datetimepicker */}
       <DateTimePickerModal
         isVisible={datePickerVisible}
         mode="date"
         display={Platform.OS === 'ios'? 'inline' :'default'}
         onConfirm={handleConfirm}
         onCancel={hideDatePicker}
       />
     </View>

      </ScrollView>
      </KeyboardAwareScrollView>
      <Loader isLoading={isLoading} />
    </View>
  );


}
export default PurchaseScreen;

// export default function (props) {
//   const navigation = useNavigation();

//   return <PurchaseScreen {...props} navigation={navigation} />;
// }