import React, { Component } from 'react';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import * as ProductService from '../services/productService';
import styles from '../styles/stockCheckScreen.styles';
import { View, Text, TouchableOpacity } from 'react-native';
import Header from '../components/header';
import Loader from '../components/loader';
import Icon from '../components/icon';
import { Input } from 'react-native-elements';
import RowListStock from '../components/rowListStock';


interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  formInput: Array<any>;
  category: string;
  subCategory: string;
  material: string;
  subMaterial: string;
  grade: string;
  brand: string;
  productList: Array<any>;
  isLoading: boolean;
  showFilterBar: boolean;
  displayList: Array<any>;
}

class StockCheckScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      formInput: [],
      category: '',
      subCategory: '',
      material: '',
      subMaterial: '',
      grade: '',
      brand: '',
      productList: [],
      isLoading: false,
      showFilterBar: false,
      displayList: [],
    }

    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    let productListingResponse: any = [];
    let productListing: Array<any> = [];
    this.setState({ isLoading: true });
    productListingResponse = await ProductService.getProductByStatus(this.props.screenProps.token, 'active');
    this.setState({ isLoading: false });

    if (productListingResponse) {
      let displayList: Array<any> = [];
      productListingResponse.map((item: any) => (
        displayList.push({
          "content": `Brand: ${item.brand}\nCategory: ${item.category}, Subcategory: ${item.subCategory}, Material: ${item.material}, Sub Material: ${item.subMaterial}, Grade: ${item.grade}`,
          "title": "Stock: " + item.stockAvailability,
          "date": '',
          "unread": false,
          "id": item._id,
          "onPress": () => {}
        })
      ));

      this.setState({ productList: productListingResponse, displayList: displayList });
    }
  }

  onChange(name: string, input: string) {
    this.setState({ [name]: input }, () => {
      let displayList = this.state.productList;
      if (this.state.brand !== '') {
        console.log(this.state.brand)
        console.log(displayList)
        displayList = displayList.filter((item) => item.brand?.toLowerCase().includes(this.state.brand.toLowerCase()));
      }

      if (this.state.category !== '') {
        displayList = displayList.filter((item) => item.category?.toLowerCase().includes(this.state.category.toLowerCase()));
      }

      if (this.state.subCategory !== '') {
        displayList = displayList.filter((item) => item.subCategory?.toLowerCase().includes(this.state.subCategory.toLowerCase()));
      }

      if (this.state.material !== '') {
        displayList = displayList.filter((item) => item.material?.toLowerCase().includes(this.state.material.toLowerCase()));
      }

      if (this.state.subMaterial !== '') {
        displayList = displayList.filter((item) => item.subMaterial?.toLowerCase().includes(this.state.subMaterial.toLowerCase()));
      }

      if (this.state.grade !== '') {
        displayList = displayList.filter((item) => item.grade?.toLowerCase().includes(this.state.grade.toLowerCase()));
      }

      if (displayList) {
        let displayListing: Array<any> = [];
        displayList.map((item: any) => (
          displayListing.push({
            "content": `Brand: ${item.brand}\nCategory: ${item.category}, Subcategory: ${item.subCategory}, Material: ${item.material}, Sub Material: ${item.subMaterial}, Grade: ${item.grade}`,
            "title": "Stock: " + item.stockAvailability,
            "date": '',
            "unread": false,
            "id": item._id,
            "onPress": () => {}
          })
        ));

        this.setState({ displayList: displayListing });
      }
    });
  }

  render() {
    return (
      <View style={styles.stockContainer}>
        <Header headerText='Stock Check' onBack={() => this.props.navigation.goBack()} back={true} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti} />
          <TouchableOpacity style={styles.filterRow} onPress={() => this.setState({ showFilterBar: !this.state.showFilterBar })}>
            <Icon name={'filter'} color={'black'} badgeCount={0} />
            <Text style={styles.filterText}>Click to Filter...</Text>
          </TouchableOpacity>
        { this.state.showFilterBar && <View>
            <View style={styles.filterBarContainer}>
                <Text style={styles.filterInputLabel}>Brand: </Text><Input inputStyle={styles.filterInput} inputContainerStyle={styles.filterInputContainer} onChangeText={(value) => this.onChange('brand', value)} maxLength={20} value={this.state.brand} />
            </View>
            <View style={styles.filterBarContainer}>
                <Text style={styles.filterInputLabel}>Category: </Text><Input inputStyle={styles.filterInput} inputContainerStyle={styles.filterInputContainer} onChangeText={(value) => this.onChange('category', value)} maxLength={20} value={this.state.category} />
            </View>
            <View style={styles.filterBarContainer}>
                <Text style={styles.filterInputLabel}>Subcategory: </Text><Input inputStyle={styles.filterInput} inputContainerStyle={styles.filterInputContainer} onChangeText={(value) => this.onChange('subCategory', value)} maxLength={20} value={this.state.subCategory} />
            </View>
            <View style={styles.filterBarContainer}>
                <Text style={styles.filterInputLabel}>Material:</Text><Input inputStyle={styles.filterInput} inputContainerStyle={styles.filterInputContainer} onChangeText={(value) => this.onChange('material', value)} maxLength={20} value={this.state.material} />
            </View>
            <View style={styles.filterBarContainer}>
                <Text style={styles.filterInputLabel}>SubMaterial: </Text><Input inputStyle={styles.filterInput} inputContainerStyle={styles.filterInputContainer} onChangeText={(value) => this.onChange('subMaterial', value)} maxLength={20} value={this.state.subMaterial} />
            </View>
            <View style={styles.filterBarContainer}>
                <Text style={styles.filterInputLabel}>Grade: </Text><Input inputStyle={styles.filterInput} inputContainerStyle={styles.filterInputContainer} onChangeText={(value) => this.onChange('grade', value)} maxLength={20} value={this.state.grade} />
            </View>
        </View>
        }
        { this.state.displayList.length > 0 && <RowListStock dataList={this.state.displayList} refreshing={false} onRefresh={() => {}} />}
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default StockCheckScreen;
