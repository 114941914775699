import React, { Component } from 'react';
import { View, SectionList, TouchableOpacity, Text } from 'react-native';
import styles from './cardList.styles';

//Content card list

interface CustomInputProps {
    dataList: Array<any>;
}

// interface CustomInputStates {
//     dataListing: Array<any>;
// }


class NameList extends Component<CustomInputProps, CustomInputStates> {

    // constructor(props: CustomInputProps) {
    //     super(props);

    //     this.state = {
    //         dataListing: [],
    //     }
    // }

    // componentDidMount() {
    //     let header = '';
    //     let tempDataList: any = [];
    //     this.props.dataList.map((item: any, key: any) => {
    //         if (header === '') {
    //             header = item.title.substring(0, 1)
    //         }
    //         if (header !== item.title.substring(0, 1)) {
    //             this.state.dataListing.push({
    //                 'title': header,
    //                 'data': tempDataList
    //             });
    //             header = item.title.substring(0, 1)
    //             tempDataList = []
    //         }
    //         tempDataList.push({
    //             'title': item.title,
    //             'content': item.content,
    //             'buttonText': item.buttonText,
    //             'buttonOnPress': item.buttonOnPress
    //         })
    //     });
    // }

    render() {
        return (

            // <View style={styles.cardList}>
            //   {this.props.dataList.map((item, key) => (
            //     <Card key={key} title={item.title} content={item.content} action={item.buttonText} unread={item.unread || false} onPress={item.buttonOnPress} />

            //   ))}
            <SectionList
                sections={this.props.dataList}
                keyExtractor={(item, index) => item + index}
                renderItem={({ item }) => (
                    <TouchableOpacity onPress={item.buttonOnPress}>
                        <View style={styles.item}>
                            <Text style={styles.title}>{item.title}</Text>
                        </View>
                    </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                    <Text style={styles.header}>{title}</Text>
                )}

            />
            // </View>
        );
    }
}

export default NameList;