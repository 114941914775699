import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  stockContainer: {
    flex: 1
  },
  filterRow: {
    flexDirection: 'row',
    
    
  },
  filterText: {
    fontSize: 22,
    color: 'grey',
    paddingTop: 5,
    paddingLeft: 10,
  },
  filterBarContainer: {
    
    flexDirection: 'row',
  },
  filterInputLabel: {
    fontSize: 16,
    paddingTop: 20,
    paddingLeft:20,
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    fontWeight:"bold",
  },
  filterInput: {
    paddingTop: 10,
    fontSize: 16,
  },
  filterInputContainer: {
    width: 200
  }
});

export default styles;
