import { Platform, StyleSheet } from 'react-native';
import { darkGreen } from "./Constants";

const styles = StyleSheet.create({
    form: {
        width: 250,
        flex: 1,
        justifyContent: 'center',
        alignSelf: 'center'
    },
    scrollViewContainer: {
        flexShrink: 1,
        justifyContent: "center",
        paddingVertical: 15,
    },
    formContainer: {
        flex: 2
    },
    button: {
        width: 200,
        paddingTop: 10,
        alignSelf: 'center'
    },
    errorMessage: {
        color: 'red',
        alignSelf: 'center'
    },
    inputContainer: {
        flex: 1,
        borderRadius: 10,
        padding: Platform.OS === 'ios' ? "5%" : "4%",
        width: "80%",
        backgroundColor: "#F7FDF5",
        marginVertical: Platform.OS === 'ios' ? "4%" : "2%",
        shadowColor: "#171717",
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.3,
        shadowRadius: 4,
        alignSelf: 'center',
        elevation: 2
    },
    pickerContainer: {
        paddingLeft: 10,
        paddingBottom: 20
    },
    picker: {
        height: 50,
        width: 200
    },
    labelText: {
        fontSize: 16,
        color: '#999999',
        fontWeight: 'bold'
    },
});

export default styles;