import React, { Component } from "react";
import {
  View,
  Text,
  KeyboardAvoidingView,
  Platform,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import { Button } from "react-native-elements";
import styles from "./form.styles";
import { ScrollView } from "react-native-gesture-handler";
import RNPickerSelect from "react-native-picker-select";
import Btn from "./Btn";
import { grey } from "./Constants";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { LinearGradient } from "expo-linear-gradient";


interface CustomInputProps {
  formInput: Array<any>;
  haveError: boolean;
  errorMessage: string;
  buttonText: string;
  buttonOnPress: Function;
  buttonText2: string;
  buttonOnPress2: Function;
}

class Form extends Component<CustomInputProps> {
  static defaultProps = {
    buttonText2: "",
    buttonOnPress2: () => {},
  };

  constructor(props: CustomInputProps) {
    super(props);

    this.onPress = this.onPress.bind(this);
    this.onPress2 = this.onPress2.bind(this);
  }

  onPress() {
    this.props.buttonOnPress();
  }

  onPress2() {
    this.props.buttonOnPress2();
  }

  render() {
    return (
      <KeyboardAwareScrollView
        style={styles.formContainer}
        scrollEnabled={true}
        resetScrollToCoords={{ x: 0, y: 0 }}
      >
        <ScrollView contentContainerStyle={styles.scrollViewContainer}>
          <View style={styles.form}>
            {this.props.formInput.map(function (item: any, key: number) {
             
                if (item.inputType === "picker") {
                  if (Platform.OS === "ios" || Platform.OS === "macos") {
                    //Can be use on android too but it is better to use new picker in android for future upgrading
                    if (item.value === "") {
                      return (
                        <View key={key}>
                          <Text style={styles.title}>{item.placeholder}</Text>
                          <View style={styles.inputContainer}>
                            <RNPickerSelect
                              onValueChange={item.onChangeText}
                              items={item.option.map(
                                (item: any, key: number) => {
                                  return { label: item, value: item };
                                }
                              )}
                            />
                          </View>
                        </View>
                      );
                    } else {
                      return (
                        <View key={key}>
                          <Text style={styles.title}>{item.placeholder}</Text>
                          <View style={styles.inputContainer}>
                            <RNPickerSelect
                              onValueChange={item.onChangeText}
                              value={item.value}
                              items={item.option.map(
                                (item: any, key: number) => {
                                  return { label: item, value: item };
                                }
                              )}
                            />
                          </View>
                        </View>
                      );
                    }
                  } else {
                    return (
                      <View key={key}>
                        <Text style={styles.title}>{item.placeholder}</Text>
                        <View style={styles.pickerContainer}>
                          <Picker
                            selectedValue={item.value}
                            style={styles.picker}
                            onValueChange={item.onChangeText}
                          >
                            <Picker.Item label="" value="" />
                            {item.option.map((item: any, key: number) => (
                              <Picker.Item
                                label={item}
                                value={item}
                                key={key}
                              />
                            ))}
                          </Picker>
                        </View>
                      </View>
                    );
                  }
               
              } else {
                return (
                  <View>
                    <Text style={[styles.title]}>{item.placeholder}</Text>
                    {console.log(item.placeholder)}
                    <TextInput
                      key={key}
                      style={styles.inputContainer}
                      placeholder={item.placeholder}
                      placeholderTextColor={grey}
                      onChangeText={item.onChangeText || function () {}}
                      secureTextEntry={item.secureTextEntry || false}
                      keyboardType={item.keyboardType || "default"}
                      editable={item.disabled ? false : true}
                      clearButtonMode="while-editing"
                      value={item.value}
                    />
                    
                  </View>
                );
              }
            })}

            {this.props.haveError && (
              <Text style={styles.errorMessage}>{this.props.errorMessage}</Text>
            )}
            {this.props.buttonText2 !== "Requotation" && (
              <View style={{ alignItems: "center" }}>
                <TouchableOpacity
                  onPress={this.onPress}
                  style={[styles.button, styles.shadow]}
                >
                  <LinearGradient
                    start={{ x: 1, y: 0 }}
                    end={{ x: -1, y: 0 }}
                    colors={["#6DD34D", "#2f883C", "#0E6B35"]}
                    style={[styles.gradient]}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontSize: 20,
                        fontWeight: "600",
                      }}
                    >
                      {this.props.buttonText}
                    </Text>
                  </LinearGradient>
                </TouchableOpacity>
              </View>
            )}
            {this.props.buttonText2 !== "" && (
              <View style={{ alignItems: "center" }}>
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    style={[
                      styles.shadow,
                      {
                        backgroundColor: "#0E6B35",
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        alignItems: "center",
                        width: 130,
                        paddingVertical: "4%",
                        marginTop: "20%",
                        marginBottom: "8%",
                        borderRightColor: "white",
                        borderRightWidth: 1.5,
                      },
                    ]}
                    onPress={this.onPress}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontSize: Platform.OS === "ios" ? 22 : 16,
                        fontWeight: "bold",
                      }}
                    >
                      {this.props.buttonText}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      styles.shadow,
                      {
                        backgroundColor: "#F20000",
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                        alignItems: "center",
                        width: 130,
                        paddingVertical: "4%",
                        marginTop: "20%",
                        marginBottom: "8%",
                      },
                    ]}
                    onPress={this.onPress2}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontSize: Platform.OS === "ios" ? 22 : 16,
                        fontWeight: "bold",
                      }}
                    >
                      {this.props.buttonText2}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
            {this.props.buttonText2 !== "" &&
              this.props.buttonText2 !== "Requotation" &&
              this.props.buttonText2 !== undefined && (
                <View style={styles.button}>
                  <Button
                    title={this.props.buttonText2}
                    onPress={this.onPress2}
                  />
                </View>
              )}
          </View>
        </ScrollView>
      </KeyboardAwareScrollView>
    );
  }
}

export default Form;
