import { StyleSheet, StatusBar, Platform, Dimensions } from 'react-native';
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";    

const width = Dimensions.get("screen").width;
const height = Dimensions.get("screen").height;

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#0E6B35',
    height: height*1/10,
    width: width,
    paddingTop: (Platform.OS === 'ios') ? 10 : StatusBar.currentHeight,
    flexDirection: 'row'
  },
  headerText: {
    color: '#F7FDF5',
    fontWeight: 'bold',
    fontSize: RFPercentage(3.5),
  },
  headerTextContainer: {
    width: "69%",
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: (Platform.OS === 'ios') ? 0 : "2%",
  },
  headerButton: {
    width: "100%",

  },
  headerButtonContainer: {
    width: "5.5%",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal:"5%"
  },
  headerBack:{
    flex: 1,
    paddingTop: (Platform.OS === 'web') ? 20 : 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: (Platform.OS === 'ios') ? 0 : "2%",
    marginLeft: (Platform.OS === 'ios') ? "3%" : "2%",
    marginRight: (Platform.OS === 'ios') ? "-5%" : "-7%",
  },
  notiContainer: {
    flex: 10,
    paddingTop: (Platform.OS === 'web') ? 20 : 0,
    paddingRight: (Platform.OS === 'ios') ? "5%" : "2%",
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: (Platform.OS === 'ios') ? 0 : "2%",
  },
});

export default styles;