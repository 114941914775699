import React, { Component } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/projectDevelopmentListing.styles';
import Header from '../components/header';
import NameList from '../components/nameList';
import * as CompanyService from '../services/companyService';
import * as ProjectService from '../services/projectService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  customerListing: Array<any>;
}

class ProjectDevelopmentListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      customerListing: []
    }

    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() {
    let customerListingResponse: any;
  
    if (this.props.screenProps.role === 'supplier') {
      customerListingResponse = await ProjectService.getProjectDevelopmentBySupplier(this.props.screenProps.token, this.props.screenProps.companyId);
    } else if (this.props.screenProps.role === 'sales') {
      customerListingResponse = await CompanyService.getCompanyBySales(this.props.screenProps.token, this.props.screenProps.userId);
    } else {
      customerListingResponse = await CompanyService.getCompanyAll(this.props.screenProps.token);
    }
    
    if (customerListingResponse) {
      let customerListing: Array<any> = [];
      let header = '';
      let tempList: any = [];
      
      if (this.props.screenProps.role === 'supplier') {
        customerListingResponse = customerListingResponse.map(item => item.companyId).filter((value, index, self) => self.indexOf(value) === index);
        customerListingResponse.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
        customerListingResponse.map((item: any, key: any) => {   
          if (header === '') {
            header = item.substring(0, 1)
          }
          if (header !== item.substring(0, 1)) {
            customerListing.push({
              'title': header,
              'data': tempList
            });
            header = item.substring(0, 1)
            tempList = []
          }
          tempList.push({
            'title': item,
            'content': '',
            'buttonText': 'SELECT',
            'buttonOnPress': () => this.onSelect(item)
          })
          if(key === customerListingResponse.length-1){
            customerListing.push({
              'title': header,
              'data': tempList
            });
          }
        });
      } else {
        customerListingResponse = customerListingResponse.filter(function (item: any) {
          return item.type === 'customer';
        });
        // if (this.props.screenProps.role === 'management' && this.props.screenProps.department !== 'all' && this.props.screenProps.department !== 'super') {
        //   let departmentToFilter: string = this.props.screenProps.department;
        
        //   customerListingResponse = customerListingResponse.filter(function (item: any) {
        //     return item.department === 'all' || item.department === departmentToFilter;
        //   });
        // }
        customerListingResponse.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
        customerListingResponse.map((item: any, key: any) => {
          if (header === '') {
            header = item.name.substring(0, 1).toUpperCase()
          }
          if (header !== item.name.substring(0, 1).toUpperCase()) {
            customerListing.push({
              'title': header,
              'data': tempList
            });
            header = item.name.substring(0, 1).toUpperCase()
            tempList = []
          }
          tempList.push({
            'title': item.name,
            'content': '',
            'buttonText': 'SELECT',
            'buttonOnPress': () => this.onSelect(item.name)
          })
          if(key === customerListingResponse.length-1){
            customerListing.push({
              'title': header,
              'data': tempList
            });
          }
        });
      }

      this.setState({ customerListing: customerListing });
    }
  }

  onSelect(companyId: string) {
    this.props.navigation.navigate('ProjectDevelopmentListingByCustomer', {customerId: companyId});
  }

  render() {
    let header;
    if (this.props.screenProps.role === 'sales'){
      header = <Header headerText='Project Development' onBack={() => this.props.navigation.goBack()} back={true} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti}/>
    }else{
      header = <Header headerText='Project Development' onBack={() => this.props.navigation.goBack()} back={false} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti}/>
    }
    return (
      <>
      { header }
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        {this.state.customerListing.length === 0 && <Text style={styles.emptyMessage}>No customer!</Text>}
        {this.state.customerListing.length > 0 && <NameList dataList={this.state.customerListing} />}
      </>
    );
  }
}

export default ProjectDevelopmentListing;