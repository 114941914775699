import React, { Component } from 'react';
import { Text,View, ScrollView } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from '../styles/creditLimitScreen.styles';
import Header from '../components/header';
import Fontisto from "react-native-vector-icons/Fontisto";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  creditLimit: string;
  debtorAgeing: string;
}

class CreditLimitScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      creditLimit: '',
      debtorAgeing: ''
    }
  }

  async componentDidMount() { //Get credit limit/ debtor ageing from database
    let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
    
    if (getCompanyResponse[0]) {
		    this.setState({
		      creditLimit: getCompanyResponse[0].creditLimit,
		      debtorAgeing: getCompanyResponse[0].debtorAgeing
      });
    }
  }

render() {
    return (
      <View style={styles.companyContainer}>
        <Header headerText='Credit Limit' onBack={() => this.props.navigation.goBack()} back={true} notification={false} onPress={() => this.props.navigation.navigate('NotificationScreen')} />
        <View style={[styles.ctn, styles.Shadow]}>
          <View>
            <Text style={styles.headertext}>Credit Limit</Text>
            <View style={[styles.contentBox, { flexDirection: "row" }]}>
              <Fontisto name="credit-card" size={20} />
              <Text style={styles.text}>
                RM {this.state.creditLimit}
              </Text>
            </View>
          </View>
          <View style={[{ marginTop: "10%" }]}>
            <Text style={styles.headertext}>Debtor Ageing</Text>
            <ScrollView showsVerticalScrollIndicator={true}>
              <View style={[styles.contentBox, { flexDirection: "row" }]}>
                <MaterialCommunityIcons name="credit-card-clock-outline" size={30} />
                <Text style={styles.text}>
                {this.state.debtorAgeing}
                </Text>
              </View>


            </ScrollView> 
          </View>

        
      </View>
      </View>
    );
}
}

export default CreditLimitScreen;
