import { Dimensions, StyleSheet } from 'react-native';

const screenHeight = Dimensions.get('screen').height;

const styles = StyleSheet.create({
  listingContainer: {
    flex: 1,
    height: "100%",
  },
  shadow: {
    shadowOffset: {
      height: 0,
      width: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    elevation: 5,
  },
  Btn: {
    backgroundColor: "white",  
    borderRadius: 10, 
  },
  AddBtn: {
    flexDirection: "row", 
    backgroundColor: "white", 
    width: "30%", 
    height: 60,
    marginLeft: "60%",
    marginTop: "-20%",
    alignItems: "center", 
    justifyContent: "center", 
    borderRadius: 10, 
    position: "absolute", 
  }
});

export default styles;