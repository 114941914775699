import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  companyContainer: {
    flex: 1,
  },
  tableRow: {
    height: 60,
    backgroundColor: "white",
  },
  tableText: {
    textAlign: "center",
    fontWeight: "100",
  },
  ctn: {
    backgroundColor: "white",
    borderRadius: 25,
    height: "83%",
    margin: 20,
    padding: "10%"
  },
  headertext: { color: "#056B30", fontSize: 25, fontWeight: "700" },
  contentBox: {
    paddingVertical: "10%",
    paddingHorizontal: "4%",
  },
  contentText: { paddingLeft: 10, fontSize: 20, fontWeight: "700" },
  btn: {
    flexDirection: "row",
    backgroundColor: "white",
    padding: 10,
    borderRadius: 5,
    marginBottom: "10%",
  },

  Shadow: {
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
  },
  text: {
    padding:5 ,
    paddingLeft: 10,
    fontSize: 20,
    fontWeight: "600",
  },
});

export default styles;
