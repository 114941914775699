// For documents uploading
// Purchase Order uploaded by customer
// Delivery Order uploaded by admin
import React, { useEffect, useState } from "react";
import {
  View,
  Platform,
  Alert,
  Text,
  TouchableOpacity,
} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import moment from "moment";
import styles from "../styles/uploadDocumentScreen.styles";
import Header from "../components/header";
import Loader from "../components/loader";
import * as OrderService from "../services/orderService";
import PDFReader from "rn-pdf-reader-js-improved";
import FontAwesome from "react-native-vector-icons/FontAwesome";

const UploadDocumentScreen = (props) => {
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState("");
  const [status, setStatus] = useState("");
  const [hideComponent, setHideComponent] = useState(true);

  useEffect(() => {
    getOrderFromDatabase();
  }, []);

  const getOrderFromDatabase = async () => {
    setIsLoading(true);
    // let orderResponse: any = {};
    // In the database there is no collections name as orderId
    let orderResponse: any = await OrderService.getOrderById(
      props.screenProps.token,
      props.navigation.getParam("orderId", "")
    );
    console.log("Order id:", props.navigation.getParam("orderId", ""));
    if (props.screenProps.companyId === "") {
      orderResponse = await OrderService.getOrder(props.screenProps.token);
    } else {
      orderResponse = await OrderService.getOrderByCompany(
        props.screenProps.token,
        props.screenProps.companyId
      );
    }

    if (orderResponse) {
      setCustomer(orderResponse[0].orderBy);
      setStatus(orderResponse[0].status);

      setIsLoading(false);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);

      console.log("error error");
      {
        props.screenProps.role === "customer" &&
          props.navigation.navigate("MeScreen");
      }
    }
  };

  const handleSelectDocument = async () => {
      const result = await DocumentPicker.getDocumentAsync({
        multiple: true,
        copyToCacheDirectory: true,
      });
      if (result.type !== 'cancel') {
        setDocument(result);
        setHideComponent(false);
      }
    console.log("Documents: ", document);
  };
 

  const uploadDocument = async () => {
    if (document == "") {
      return;
    }

    let proceed: string = await onConfirm();

    if (proceed !== "proceed") {
      return;
    }

    setIsLoading(true);

    let documents: any = {
      uri:
        Platform.OS === "android"
          ? document.uri
          : document.uri.replace("file://", ""),
      type: "document/pdf",
      name: "fileselected.pdf",
    };
    console.log(documents);

    let body: any = new FormData();
    body.append("orderId", props.navigation.getParam("orderId"));
    body.append("purchaseOrderUploadDate", moment().format());
    body.append("documentURI", documents);

    if (props.screenProps.role === "sales") {
      // alert("Successfully updated Delivery Order ");
      // onUpdateStatus('delivery order sent'); //// to update status to "delivery order sent by sales"
      // alert("Successfully updated Delivery Order " + document.uri);
      // props.navigation.navigate('CustomerOrderListing');
    } else if (props.screenProps.role === "customer") {
      //// update status function can work
      // onUpdateStatus('purchase order received'); //// to update status to "purchase order received by sales"
      //// still unable to upload document
      // // upload document
      let success: any = await OrderService.postDocument(body);
      if (success) {
        alert("Successfully updated Purchase Order " + document.name);
        onUpdateStatus("order confirmed");
      } else {
        alert("Fail to upload please try again");
      }
      props.navigation.goBack()
    }
    setIsLoading(false);
  };

  function onConfirm() {
    return new Promise((resolve, reject) => {
      if (Platform.OS === "web") {
        let res: any = window.confirm(`Confirm To Proceed?`);

        if (res) {
          resolve("proceed");
        } else {
          resolve("cancel");
        }
      } else {
        Alert.alert(
          "Confirm To Proceed?",
          "",
          [
            {
              text: "Cancel",
              onPress: () => resolve("cancel"),
              style: "cancel",
            },
            { text: "OK", onPress: () => resolve("proceed") },
          ],
          { cancelable: false }
        );
      }
    });
  }

  // update status after upload PO

  const onUpdateStatus = async (status: string) => {
    // let proceed: string = await onConfirm();
    // if (proceed !== 'proceed') {
    //   return;
    // }

    setIsLoading(true);
    // // // to put the status from quotation accepted to purchase order received
    await OrderService.putStatus(
      props.screenProps.token,
      props.navigation.getParam("orderId", ""),
      status
    );

    // if (status === 'purchase order received') {
    //   let getCompanyResponse: any = await CompanyService.getCompany(props.screenProps.token, state.customer);
    //   let department: any = getCompanyResponse[0].department;
    // 		   let notifyTarget: any = await UserService.getManagementByDepartment(props.screenProps.token, department);
    //     let notification: any = { orderId: props.navigation.getParam('orderId', '') };
    //   await NotificationService.postNotification(props.screenProps.token, notifyTarget, 'Quotation Accepted', 'Status Update: Quotation is accepted for order ' + state.brand + ' ' + state.material + ' by ' + state.customer, 'QuotationScreen', notification);
    //  }
    // // if (status === 'quotation approved') {
    // //   // to notify customer when management approve the quotation made by salesperson
    // //   let getCompanyResponse: any = await CompanyService.getCompany(props.screenProps.token, state.customer);
    // //   //let notifyTarget: any = await UserService.getManagementByDepartment(props.screenProps.token, department);
    // //   let notification: any = { orderId: props.navigation.getParam('orderId', '') };
    // //   alert(notification);
    // //   await NotificationService.postNotification(props.screenProps.token, state.customer, 'Quotation Approved', 'Status Update: Quotation is approved for order ' + state.brand + ' ' + state.material + ' by ' + state.customer, 'QuotationScreen', notification);
    // // }
    alert("PO received");
    setIsLoading(false);
  };

  return (
    <View style={styles.feedContainer}>
      {props.screenProps.role === "customer" && (
        <Header headerText='Upload PO' onBack={() => props.navigation.goBack()} back={true} notification={false} onPress={() => props.navigation.navigate('NotificationScreen')} />
        )}
      {props.screenProps.role === "sales" && (
        <Header headerText='Upload DO' onBack={() => props.navigation.goBack()} back={true} notification={false} onPress={() => props.navigation.navigate('NotificationScreen')} />
      )}
      {/* {/* {props.screenProps.role === 'customer' && <BackBar onPress={() => props.navigation.navigate('MeScreen')} />}
      {props.screenProps.role === 'sales' && <BackBar onPress={() => props.navigation.navigate('CustomerOrderListing')} />} */}
      {/*Document Preview Function only work on APP, not work on WEB */}
      {/* <View style={styles.uploadForm}>
        <ScrollView>
          {document && (<Text style={styles.documentText1}>{document.name}</Text>)}
          {document && (Platform.OS !== 'web' && <Text style={styles.documentText2}>File Preview</Text>)}

         
          {document && (<PDFReader source={{ uri: document.uri }} webviewProps={{ startInLoadingState: true }}
            onLoad={() => console.log(`PDF rendered from ${document.uri}`)} style={{ height: 500, width: '100%' }}
          />)}
          <View style={styles.photoButtonContainer}><Button style={styles.button} title="Select Document (PDF)" onPress={handleSelectDocument} /></View>
          {document && <View style={styles.uploadButtonContainer}><Button style={styles.button} title="Upload" onPress={uploadDocument} /></View>}
        </ScrollView>
      </View> */}

      <View style={styles.feedContainer}>
        <View style={[styles.ctn, styles.ctnShadow]}>
          {/*dummy tag  */}
          <Text> </Text>
          {/* Show the Icon and the word when the pdf is not selected */}
          {hideComponent ? (
            <View style={{ marginBottom: "20%", alignItems: "center" }}>
              <TouchableOpacity
                style={{ padding: 20 }}
                onPress={handleSelectDocument}
              >
                <FontAwesome name="file-pdf-o" size={75} color="red" />
              </TouchableOpacity>

              <Text style={{ fontSize: 25, color: "#16773C" }}>
                Select a PDF file from your phone.
              </Text>
            </View>
          ) : null}
          {/* select pdf and preview pdf */}
          {document && (
            <PDFReader
              source={{ uri: document.uri }}
              webviewProps={{ startInLoadingState: true }}
              onLoad={() => console.log(`PDF rendered from ${document.uri}`)}
              style={{ minHeight: 100, width: "90%" }}
            />
          )}
          {/* show document name */}
          {document && (
            <Text style={styles.documentText1}>{document.name}</Text>
          )}
        </View>

        <View style={{ alignItems: "center" }}>
          {/* {document && (<View style={styles.uploadButtonContainer}> <Button style={styles.button} title="Upload" onPress={uploadDocument} /> </View>)}
           */}
          {document && (
            <TouchableOpacity
              style={[styles.button, styles.shadow]}
              onPress={handleSelectDocument}
            >
              <Text style={{ fontSize: 20, color: "#374957" }}>
                Select Document (PDF)
              </Text>
            </TouchableOpacity>
          )}

          {document && (
            <TouchableOpacity
              style={[styles.button, styles.shadow]}
              onPress={uploadDocument}
            >
              <Text style={{ fontSize: 23, color: "#374957" }}>
                Upload PDF
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>

      <Loader isLoading={isLoading} />
    </View>
  );
};

export default UploadDocumentScreen;
