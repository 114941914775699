import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  companyContainer: {
    flex: 1
  },
  scrollViewContainer: { 
    justifyContent: "center" 
  },
  tableRow: { 
    height: 60, 
    backgroundColor: 'white' 
  },
  tableText: { 
    textAlign: 'center', 
    fontWeight: '100' 
  },
  logo:{
    backgroundColor: "white",
  width: 150,
  height: 150,
  marginVertical: 30,
  borderRadius: 100,
  alignItems:"center",
  justifyContent:"center"
  
},
  topline:{borderTopWidth:0.5,borderTopColor:"black",},
  bottomLine:{borderTopWidth:0.5,borderTopColor:"black",marginTop:1,},
  ctn: {
    backgroundColor: "white",
    marginTop:1,
    padding: 15,
    paddingLeft: 10,
    // borderColor: "black",
    // borderWidth: 0.5,
    alignItems: "center",
    flexDirection: "row",
  },
  ctn2: {
    backgroundColor: "white",
    marginTop: 1,
    padding: 15,
    paddingLeft: 10,
    width: "50%",
    maxWidth:"50%",
    // borderColor: "black",
    // borderWidth: 0.5,
    alignItems: "center",
    flexDirection: "row",
    margin:0.2
    
  },
  title: { fontSize: 20, fontWeight: "700", color: "#374957" },
  text: { paddingLeft: 3, fontSize: 18 },
  button: { marginTop: -20, marginRight: 5 },
  shadow:{ shadowOffset: {
    width: 0.5,
    height:1,
  },
  shadowColor: "#000",
  shadowOpacity: 0.3,
  shadowRadius: 2,
  elevation: 5,},
});

export default styles;