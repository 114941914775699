import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  cardList: {
    flexDirection: "row",
    padding: 20,
    maxHeight: "17%",

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 5,
  },
  item: {
    padding: 20,
    borderBottomColor: "#E2E2E2",
    borderBottomWidth: 2,
  },
  header: {
    fontSize: 32,
    backgroundColor: "#A0CDAA",
    padding: 5,
    paddingLeft: 10,
  },
  title: {
    fontSize: 50,
  },
});

export default styles;
