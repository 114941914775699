import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  quotingContainer: {
    flex: 1
  },
  formContainer: {
    flex: 2
  },
  scrollViewContainer: { 
    flexGrow: 1, 
    justifyContent: "center",
    // marginBottom: '20%'
  },
  form: {
    width: 250,
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  inputContainer: {
    paddingBottom: 20
  },
  ctn: {
    backgroundColor: "white",
    margin: "5%",
    marginTop: "10%",
    borderRadius: 20,
    height: "79%",
    paddingTop: "7%",
  },
  title: {
    paddingTop: 10,
    paddingLeft: 50,
    color: "#056B30",
    fontSize: 20,
    fontWeight: "600",
  },
  field: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    color: "#0E6B35",
    paddingHorizontal: 10,
    width: "76.5%",
    backgroundColor: "#F7FDF5",
    marginVertical: 10,
    paddingVertical: 12,
    shadowColor: "#171717",
    marginLeft: '12%',
    
    shadowOpacity: 0.3,
    shadowRadius: 4,
    elevation:2
  },
  shadow: {
    shadowColor: "#171717",
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 2,
  },
  button: {
    marginLeft: 45,
    width: "76%",
    marginTop: "5%",
    alignItems:"center",
    paddingVertical:10,
    borderRadius:10,
    shadowOffset: {
      height: 1,
      width: 2,
    },
    shadowOpacity: 0.7,
    shadowRadius: 3,
    elevation: 5,
  },
  errorMessage: {
    color: 'red',
    alignSelf: 'center'
  },
  gradient: {
    borderRadius: 10,
    alignItems: "center",
    width: "100%",
    paddingVertical: "4%",
    marginTop: "2%",
    marginBottom: "4%",
    borderColor: "white",
  },
  
});

export default styles;