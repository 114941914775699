import React, { Component } from 'react';
import { View, KeyboardAvoidingView, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { ScrollView } from 'react-native-gesture-handler';
import styles from '../styles/companyDetailScreenNonEditable.styles';
import Header from '../components/header';
import FontAwesome from "react-native-vector-icons/FontAwesome";
import Ionicons from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import AntDesign from "react-native-vector-icons/AntDesign";
import Fontisto from "react-native-vector-icons/Fontisto";
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  companyId: string;
  type: string;
  name: string;
  customer: string;
  department: string;
  registrationNumber: string;
  address: string;
  contactNumber: string;
  email: string;
  salesVerification: number;
  businessNature: string;
  turnover: boolean;
  numberOfWorker: string;
  numberOfProductionLine: number;
  userTier: number;
  status: string;
  registerDate: string;
}

class CompanyDetailScreenNonEditable extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      companyId: '',
      type: '',
      name: '',
      customer: '',
      department: '',
      registrationNumber: '',
      address: '',
      contactNumber: '',
      email: '',
      salesVerification: 0,
      businessNature: '',
      turnover: false,
      numberOfWorker: '',
      numberOfProductionLine: 0,
      userTier: 0,
      status: '',
      registerDate: ''
    }
  }

  async componentDidMount() { //Get company details from database
    let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
    
    if (getCompanyResponse[0]) {
		    this.setState({
		      companyId: getCompanyResponse[0]._id,
		      type: getCompanyResponse[0].type,
          name: getCompanyResponse[0].name,
          department: getCompanyResponse[0].department,
		      registrationNumber: getCompanyResponse[0].registrationNumber,
		      address: getCompanyResponse[0].address,
		      contactNumber: getCompanyResponse[0].contactNumber,
		      email: getCompanyResponse[0].email,
		      salesVerification: getCompanyResponse[0].salesVerification,
		      businessNature: getCompanyResponse[0].businessNature,
		      turnover: getCompanyResponse[0].turnover,
		      numberOfWorker: getCompanyResponse[0].numberOfWorker,
		      numberOfProductionLine: getCompanyResponse[0].numberOfProductionLine,
		      userTier: getCompanyResponse[0].userTier,
		      status: getCompanyResponse[0].status,
		      registerDate: getCompanyResponse[0].registerDate
      });
    }
  }

  render() {
    return (
      <View style={styles.companyContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Company' onBack={() => this.props.navigation.goBack()} back={true} notification={true} onPress={() => this.props.navigation.navigate('NotificationScreen')} badgeCount={this.props.screenProps.unreadNoti}/>        
        <KeyboardAvoidingView style={styles.companyContainer} behavior="padding" enabled>
		      <ScrollView contentContainerStyle={styles.scrollViewContainer}>
		        {/* <Table>
		          <Row data={['Company Type', this.state.type]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
		          <Row data={['Company Name', this.state.name]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
              <Row data={['Department', this.state.department]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
              <Row data={['Company Registration Number', this.state.registrationNumber]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
              <Row data={['Address', this.state.address]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
              <Row data={['Contact Number', this.state.contactNumber]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
		          <Row data={['Email', this.state.email]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
              <Row data={['Business Nature', this.state.businessNature]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
              <Row data={['Company Turn Over', this.state.turnover]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
		          <Row data={['Number Of Worker', this.state.numberOfWorker]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
              <Row data={['Number Of Production Line', this.state.numberOfProductionLine.toString()]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />              
              <Row data={['Verify By Sales & Date', this.state.salesVerification]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
            </Table> */}
            
            <View style={{ alignItems: "center", justifyContent: "center" }}>
              <View
                style={[styles.logo, styles.shadow]}
              ><Ionicons name="person-circle-outline" size={100} /></View>
            </View>

            <View style={[styles.ctn]}>
              <FontAwesome name="building-o" size={20} style={styles.button} />
              <View>
                <Text style={styles.title}>Company Name</Text>
                <Text style={styles.text}>{this.state.name}</Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View style={styles.ctn2}>
                <Ionicons name="person-outline" size={20} style={styles.button} />
                <View>
                  <Text style={styles.title}>Company Type</Text>
                  <Text style={styles.text}>{this.state.type}</Text>
                </View>
              </View>

              <View style={styles.ctn2}>
                <MaterialCommunityIcons
                  name="office-building-marker-outline"
                  size={20}
                  style={styles.button}
                />
                <View>
                  <Text style={styles.title}>Department</Text>
                  <Text style={styles.text}>{this.state.department}</Text>
                </View>
              </View>
            </View>

            <View style={styles.ctn}>
              <MaterialCommunityIcons
                name="map-marker-radius-outline"
                size={20}
                style={styles.button}
              />
              <View>
                <Text style={styles.title}>Address</Text>
                <Text style={styles.text}>{this.state.address}</Text>
              </View>
            </View>

              <View style={styles.ctn}>
                <AntDesign name="contacts" size={20} style={styles.button} />
                <View>
                  <Text style={styles.title}>Contact Number</Text>
                  <Text style={styles.text}>{this.state.contactNumber}</Text>
                </View>
              </View>

            <View style={styles.ctn}>
                <Fontisto name="email" size={20} style={styles.button} />
                <View>
                  <Text style={styles.title}>Email</Text>
                  <Text style={styles.text}>{this.state.email}</Text>
                </View>
              </View>

            <View style={[styles.ctn]}>
              <FontAwesome name="registered" size={20} style={styles.button} />
              <View>
                <Text style={styles.title}>Company Registration Number</Text>
                <Text style={styles.text}>{this.state.registrationNumber}</Text>
              </View>
            </View>
            <View style={styles.ctn}>
              <Fontisto name="line-chart" size={15} style={styles.button} />
              <View>
                <Text style={styles.title}>Company Turn Over</Text>
                <Text style={styles.text}>{this.state.turnover}</Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View style={styles.ctn2}>
                <MaterialCommunityIcons
                  name="file-sign"
                  size={20}
                  style={styles.button}
                />
                <View>
                  <Text style={styles.title}>Business Nature</Text>
                  <Text style={styles.text}>{this.state.businessNature}</Text>
                </View>
              </View>

              <View style={[styles.ctn2,]}>
                <Ionicons name="md-people-outline" size={20} style={styles.button} />
                <View>
                  <Text style={styles.title}>Number Of Worker</Text>
                  <Text style={styles.text}>{this.state.numberOfWorker}</Text>
                </View>
              </View>
            </View>

            <View style={[styles.ctn]}>
              <MaterialCommunityIcons
                name="factory"
                size={20}
                style={styles.button}
              />
              <View>
                <Text style={styles.title}>Number Of Production Line</Text>
                <Text style={styles.text}>{this.state.numberOfProductionLine}</Text>
              </View>
            </View>
            <View style={[styles.ctn]}>
              <Fontisto name="date" size={20} style={styles.button} />
              <View>
                <Text style={styles.title}>Verify By Sales & Date</Text>
                <Text style={styles.text}>{this.state.salesVerification}</Text>
              </View>
            </View>
		      </ScrollView>
		    </KeyboardAvoidingView>
      </View>
    );
  }
}

export default CompanyDetailScreenNonEditable;
